import clsx from "clsx";
import { useState } from "react";
import { CloseIcon } from "../../assets";
import { ReactComponent as Notifications } from "../../assets/icons/Notifications.svg";
import useNotification from "../../hooks/useNotification";
import Dashboardtitle from "../DashbordTitle";
import NotifacationMessages from "../NotifiactionPopUp";
import CustomModal from "../common/CustomModal";
import style from "./index.module.scss";

export default function Notifiaction() {
  const [notification, setNotifiacation] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const openNotification = () => {
    setNotifiacation((notification) => !notification);
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };
  const { notifiacationData, notifiacationCount, unreadCount } =
    useNotification({
      page: currentPage,
      search: search.trim(),
    });
  const debounce = (value: string) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setSearch(value)
    }, 500);
    setDebounceTimeout(timeout);
  };
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchTerm(e.target.value);
    debounce(e.target.value);
  };
  const resetSearch = () => {
    setSearchTerm("")
    setSearch("")
  }
  return (
    <div>
      <div
        className={clsx(
          unreadCount > 0 ? style.unreadNotification : "",
          style.wrapper
        )}
        onClick={openNotification}
      >
        <Notifications id="notificationPopup" />
      </div>
      <CustomModal
        isOpen={notification}
        toggle={openNotification}
        classNames={{ modal: style.notificationPopUp }}
      >
        <CloseIcon
          className={style.crossIcon}
          onClick={() => {
            setNotifiacation(false);
          }}
        />
        <Dashboardtitle title="Notifications" />
        <div className={style.notificationInput}>
          <input
            className={style.searchInput}
            placeholder="Notifications"
            type="text"
            name="notifications"
            id="notifications"
            value={searchTerm}
            onChange={onChangeHandler}
          />
          <button className={style.clearAll} onClick={resetSearch}>Clear All</button>
        </div>
        <NotifacationMessages
          notification={notifiacationData ?? []}
          notifiacationCount={notifiacationCount}
          fetchMoreData={fetchMoreData}
          currentPage={currentPage}
        />
      </CustomModal>
    </div>
  );
}
