import clsx from "clsx";
import React from "react";
import style from "./index.module.scss";
type Props = {
  title: string;
  className?: string;
};
export default function PopupTitle(props: Props) {
  const { className, title } = props;
  return (
    <>
      <h1 className={clsx(style.popupTitle, className)}>{title}</h1>
    </>
  );
}
