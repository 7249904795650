import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { Controller, FieldError, useForm } from "react-hook-form";
import AlertMessage from "../../components/AlertMessage";
import CustomButton from "../../components/CustomButton";
import Input from "../../components/Input";
import { UsernameInfo } from "../../components/PersonalInfo";
import ReactDatePicker from "../../components/ReactDatePicker";
import ReactSelect from "../../components/Select";
import FlexBox from "../../components/common/FlexBox";
import { useAffiliateApplyMutation } from "../../service/api";
import { useAppSelector } from "../../store/hooks";
import { socialmedia, yesNo } from "../../utils/constant";
import countryData from "../../utils/country.json";
import { dateFormat } from "../../utils/formatter";
import { affiliateApplySchema } from "../../utils/yupSchema";
import { AlertType } from "../Login";
import SocialAccount from "./SocialAccount";
import styles from "./index.module.scss";
export interface AffilateApplyProps {
  refetch?: any;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}
export type Social = {
  social: {
    label: string;
    value: string;
  };
  username: string;
};
const AffiliateApply = ({
  refetch,
  activeStep,
  setActiveStep,
}: AffilateApplyProps) => {
  const [socialDetails, setSocialDetails] = useState<Social>({
    social: {
      label: "",
      value: "",
    },
    username: "",
  });
  const [socialItem, setSocialItem] = useState<Social[]>([]);
  const [alertMsg, setAlertMsg] = useState<AlertType>({
    type: "success",
    msg: "",
  });
  const closeAlert = () => {
    setAlertMsg({ type: "success", msg: "" });
  };
  const [updateUser, { isLoading: loading }] = useAffiliateApplyMutation();
  const { user } = useAppSelector((state) => state.auth);
  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");

  const getDataCOR = countryData.find(
    (item) => item.value === user?.countryOfResidence
  );
  const getNationality = countryData.find(
    (item) => item.value === user?.nationality
  );
  const methods = useForm({
    defaultValues: {
      userName: user?.userName ? user?.userName : "",
      firstName: user?.firstName ? user?.firstName : "",
      lastName: user?.lastName ? user?.lastName : "",
      dob: currentDate,
      nationality: {
        value: getNationality?.value,
        label: getNationality?.label,
      },
      doYouHaveExp: {
        value: "",
        label: "",
      },
      countryOfResidence: {
        value: getDataCOR?.value,
        label: getDataCOR?.label,
      },
      phoneNumber: user?.phone ? user?.phone : "",
      primarySocialAccount: {
        value: "",
        label: "",
      },
      primarySocialAccountUserName: "",
    },
    resolver: yupResolver(affiliateApplySchema),
  });
  const { errors } = methods.formState;

  const handelUpdateUser = async (event: any) => {
    setAlertMsg({ type: "success", msg: "" });
    if (loading) return;

    const getSocialItems = socialItem.map((item) => {
      return {
        social: item.social.value,
        username: item.username,
      };
    });
    const {
      nationality,
      doYouHaveExp,
      countryOfResidence,
      primarySocialAccount,
      dob,
      primarySocialAccountUserName,
      ...rest
    } = methods.getValues();

    let payload: AffiliateRequest = {
      userId: user?.id,
      nationality: nationality?.value,
      doYouHaveExp: doYouHaveExp?.value,
      countryOfResidence: countryOfResidence.value,
      socialAccounts: getSocialItems,
      dob: dateFormat(dob ?? ""),
      primarySocialAccount: primarySocialAccount?.value,
      primarySocialAccountUserName: primarySocialAccountUserName,
      ...rest,
    };
    const { data, error }: any = await updateUser({
      ...payload,
    });

    if (data) {
      if (data.message) {
        // setAlertMsg({
        //   type: "success",
        //   msg: data?.message
        // }) ;
      }
      if (refetch) {
        refetch();
      }
      setActiveStep(1);
    }
    if (error) {
      if (
        error.data.message ==
        "Username already exist, please enter a different name"
      ) {
        methods.setError("userName", {
          type: "custom",
          message: error.data.message,
        });
      } else if (error?.data?.message == "Validation error!") {
        error?.data?.data?.errors.forEach((error) => {
          methods.setError(error?.path, {
            type: error?.type,
            message: error?.msg,
          });
        });
      } else {
        setAlertMsg({
          type: "error",
          msg: error?.data?.message,
        });
      }
    }
  };

  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const openCalenderHandler = (val: boolean) => {
    setOpenCalender(val);
  };
  return (
    <div className={styles.personalInfo}>
      <div className={styles.header}>
        <div className={styles.skip}>
          <h2>General Information</h2>
        </div>
        {/* <h5>
          {isDeclined == true
            ? "Your affiliate request is rejected by admin! Please resubmit form"
            : " "}
        </h5> */}
      </div>
      <h5 className={styles.h5}>Personal details</h5>
      {alertMsg.msg && (
        <AlertMessage type={alertMsg.type} onClose={closeAlert}>
          {alertMsg.msg}
        </AlertMessage>
      )}

      <div className={styles.form}>
        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <Controller
            control={methods.control}
            name="userName"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter userName, e.g. walt_snow "
                id="userName"
                label="Username"
                error={errors.userName}
                disabled={user?.userName ? true : false}
                info={<UsernameInfo />}
                required={true}
                {...field}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="firstName"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter first name"
                id="firstName"
                label="First name"
                disabled={user?.firstName ? true : false}
                error={errors.firstName}
                required={true}
                {...field}
              />
            )}
          />
        </FlexBox>

        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <Controller
            control={methods.control}
            name="lastName"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter last name"
                id="lastName"
                label="Last name"
                disabled={user?.lastName ? true : false}
                error={errors.lastName}
                required={true}
                {...field}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="dob"
            render={({ field }) => {
              return (
                <div
                  className={styles.dateContainer}
                  onClick={() => openCalenderHandler(true)}
                >
                  <ReactDatePicker
                    isOpen={openCalender}
                    label="Date of birth"
                    id="dob"
                    openCalenderHandler={openCalenderHandler}
                    error={errors.dob as FieldError}
                    {...field}
                  />
                </div>
              );
            }}
          />
        </FlexBox>

        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <Controller
            control={methods.control}
            name="nationality"
            render={({ field }) => {
              return (
                <ReactSelect
                  options={countryData}
                  value={field.value.label ? field.value : null}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  id="nationality"
                  placeholder="Select nationality"
                  label="Nationality"
                  error={errors.nationality?.label as FieldError}
                  errorPosition={"left"}
                  required={true}
                />
              );
            }}
          />
          <Controller
            control={methods.control}
            name="doYouHaveExp"
            render={({ field }) => {
              return (
                <ReactSelect
                  options={yesNo}
                  value={field.value?.label ? field.value : null}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  id="doYouHaveExp"
                  placeholder="Do you have experience with affiliate ?"
                  label="Do you have experience with affiliate ?"
                  error={errors.doYouHaveExp as FieldError}
                />
              );
            }}
          />
        </FlexBox>

        <h4></h4>
        <h5 className={styles.h5}>Location & contacts</h5>
        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <Controller
            control={methods.control}
            name="countryOfResidence"
            render={({ field }) => {
              return (
                <ReactSelect
                  options={countryData}
                  value={field.value.label ? field.value : null}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  id="countryOfResidence"
                  placeholder="Select residence"
                  label="Country of residence"
                  error={errors.countryOfResidence?.label as FieldError}
                  errorPosition={"left"}
                  required={true}
                />
              );
            }}
          />
          <Controller
            control={methods.control}
            name="phoneNumber"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter phone number"
                id="phoneNumber"
                label="Phone number"
                error={errors.phoneNumber as FieldError}
                {...field}
                disabled={user?.phone ? true : false}
                required={true}
              />
            )}
          />
        </FlexBox>

        <h5 className={styles.h5}>Social Media</h5>
        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <Controller
            control={methods.control}
            name="primarySocialAccount"
            render={({ field }) => {
              return (
                <ReactSelect
                  options={socialmedia}
                  // value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  id="primarySocialAccount"
                  placeholder="Choose a social media platform"
                  label="Where is your primary Account?"
                  error={errors.primarySocialAccount as FieldError}
                  required={false}
                />
              );
            }}
          />

          <Controller
            control={methods.control}
            name="primarySocialAccountUserName"
            render={({ field }) => (
              <Input
                type="text"
                id="primarySocialAccountUserName"
                placeholder="Enter your username"
                label="User name"
                error={errors.primarySocialAccountUserName as FieldError}
                required={false}
                {...field}
              />
            )}
          />
        </FlexBox>
        <SocialAccount
          socialDetails={socialDetails}
          setSocialDetails={setSocialDetails}
          socialItem={socialItem}
          setSocialItem={setSocialItem}
        />

        <div className={styles.btnContainer}>
          <CustomButton
            className={styles.continueBtn}
            loading={loading}
            type="button"
            id="continue"
            onClick={methods.handleSubmit(handelUpdateUser)}
          >
            Continue
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default AffiliateApply;
