import { TabContext, TabPanel } from "@mui/lab";
import transakSDK from "@transak/transak-sdk";
import countryToCurrency, { Countries, Currencies } from "country-to-currency";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomTabList from "../../components/CustomTabList";
import SellToken from "../../components/SellToken";
import SwapToken from "../../components/SwapToken";
import Title from "../../components/Title";
import { BuyTokenOptions } from "../../components/WalletCard";
import { useAlertMessage } from "../../context/AlertContext";
import { environment } from "../../environment";
import { useGetRandomTokenQuery } from "../../service/api";
import { useAppSelector } from "../../store/hooks";
import styles from "./index.module.scss";

const buySellTab = [
  { label: "Buy Token", value: "buy" },
  { label: "Sell Token", value: "sell" },
];

const TokenBuySell = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [value, setValue] = useState("buy");
  const [showBuy, setShowBuy] = useState(false);
  const [buyValue, setBuyValue] = useState(null);
  const { setAlertMsg } = useAlertMessage();
  const handleBuyValue = (value) => {
    setBuyValue(value);
  };

  useEffect(() => {
    setValue(tab ?? "buy");
  }, [tab]);

  const { user, selectedToken } = useAppSelector((state) => state.auth);
  const { data, refetch } = useGetRandomTokenQuery({
    refetchOnMountOrArgChange: true,
  });
  const settings = {
    apiKey: environment.TRANSAK_API_KEY,
    environment: environment.TRANSAK_ENVIRONMENT, // STAGING/PRODUCTION
    network: "ethereum",
    walletAddress: data?.data?.address,
    cryptoCurrencyList: "DAI,USDT,USDC",
    cryptoCurrencyCode: data?.data?.token,
    themeColor: "#4E307A", // App theme color
    hostURL: window.location.origin,
    widgetHeight: "600px",
    widgetWidth: "450px",
    isAutoFillUserData: true,
    fiatAmount: buyValue,
    fiatCurrency: countryToCurrency[
      (user?.countryOfResidence || "US") as Countries
    ] as Currencies,
    userData: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      mobileNumber: user?.phone,
      dob: "", //YYYY-MM-DD
      address: {
        addressLine1: user?.address,
        addressLine2: user?.address,
        city: "",
        state: "",
        postCode: "",
        countryCode: user?.countryOfResidence,
      },
    },
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleBuyTokenChange = (type: "FIAT" | "CRYPTO") => {
    const action = type === "CRYPTO" ? buyNealthyToken : cryptoBuy;
    // setOpen(false);
    action();
  };

  const buyNealthyToken = async () => {
    if (!user?.depositAddress) {
      setAlertMsg?.({
        type: "error",
        msg: "Deposit address is not available for this user",
      });
      return;
    }
    setShowBuy(true);
  };

  const cryptoBuy = () => {
    let transak = new transakSDK(settings);
    transak.init();
    // To get all the events
    transak.on(transak.ALL_EVENTS, (data: any) => {
      console.log(data);
    });
    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData: any) => {
      transak.close();
    });
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      transak.close();
    });
  };

  return (
    <div>
      <Title title={"Buy and Sell Tokens"} className={styles.title} />
      <CustomTabList
        data={buySellTab}
        value={value}
        handleChange={handleChange}
      />
      <TabContext value={value}>
        <TabPanel sx={{ padding: 0, marginTop: 2 }} value="buy">
          {showBuy ? (
            <SwapToken onCancel={() => setShowBuy(false)} />
          ) : (
            <div className={styles.card}>
              <BuyTokenOptions
                selectedToken={selectedToken}
                onChange={handleBuyTokenChange}
                handleBuyValue={handleBuyValue}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel sx={{ padding: 0, marginTop: 2 }} value="sell">
          <SellToken />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default TokenBuySell;
