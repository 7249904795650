import { Link, useNavigate } from "react-router-dom";
import { useGetPlatformTokensQuery } from "../../../service/api";
import BuySellTokenCard from "../../BuySellTokenCard/BuySellTokenCard";
import Dashboardtitle from "../../DashbordTitle";
import style from "./index.module.scss";

export default function Overview() {

  const navigate = useNavigate();
  const { data, isLoading } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  return (
    <div className={style.overview}>
      <h1>Overview</h1>
      <Dashboardtitle description="As a novel approach for straightforward, easy investing, each one of On-chain Index Tokens act as a basket containing carefully selected assets from a specific market. Buying one Index Token means buying a portion of all the assets it represents, which in turn means efficient portfolio diversification and higher potential returns." />
      <Dashboardtitle
        className={style.interested}
        description="If you’re interested and would like to learn more, make sure to check out all our available ressources regarding Index Tokens."
      />
      <Dashboardtitle
        className={style.overviewTitle}
        description=""
        title="Buy & sell On-chain Index Tokens"
      />

      <div className={style.BuySellTokenContainer}>
        {
          !isLoading && <>

            {data?.data?.map((item) => (
              <div onClick={() => navigate(`/product/${item.symbol}/${item.id}`)}>
                <BuySellTokenCard tokenName={item.name} tokenDescription={"It focuses on the 10 top trading assets on the NFT market."} />
              </div>
            ))}
          </>
        }
      </div>
      <div className={style.quicklinks}>
        <Dashboardtitle title="Quick links" className={style.quickTitle} />
        <Link className={style.quicklink} to="/">
          Saving plans details
        </Link>
        <Link className={style.quicklink} to="/">
          How to buy an Index Token?
        </Link>
      </div>
    </div>
  );
}

