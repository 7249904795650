import { ReactElement, useState } from "react";
import {
  SortOrder,
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { DownArrow, Up } from "../../../assets";
import { useAppDispatch } from "../../../store/hooks";
import { setSort } from "../../../store/reducers/authSlice";
import { sortCaseHandler } from "../../../utils/helper";
import DataTables, { Data } from "../../DataTables";
import ProgressBar from "../../ProgressBar";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";
interface IRow {
  id: string;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
}
type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable?: boolean;
  style?: {};
  cell?: (row: any) => string | ReactElement;
  sortField?: string;
  width?: string;
};

function renderCell(rowItem: string) {
  let style;
  let displayValue;
  let Icon;
  if (rowItem) {
    if (rowItem.startsWith("-")) {
      style = contentNegativeNumber;
      Icon = DownArrow;
    } else {
      style = contentPositiveNumber;
      Icon = Up;
    }
    displayValue = rowItem.slice(1);
  } else {
    style = dashStyle;
    displayValue = "-";
  }

  return (
    <div style={{ ...style, display: "flex", alignItems: "center" }}>
      {Icon && <Icon />}
      {displayValue}
    </div>
  );
}

function renderNFTs(rowItem: string) {
  let style;
  let displayValue;
  if (rowItem) {
    if (rowItem) {
      displayValue = rowItem;
      style = contentStyle; // or any other style you want to apply
    } else {
      displayValue = "-";
      style = dashStyle; // or any other style you want to apply
    }
  }
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
      }}
    >
      <ProgressBar
        parentStyle={{ width: "100%" }}
        value={parseFloat(displayValue ?? "0")}
        color="primary"
        isAllocation={true}
      />
    </div>
  );
}

function renderCollection(rowItem: string) {
  // render collection such that the string from "By" goes to new line and string before it is bold and capitalised
  const split = rowItem.split(" By ");
  return (
    <div
      style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}
    >
      <div style={{ fontWeight: 700, fontSize: 14, color: "#190044" }}>
        {split[0]}
      </div>
      <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>
        By {split[1]}
      </div>
    </div>
  );
}

const contentStyle = {
  fontWeight: 600,
  fontSize: 14,
  color: "#190044",
};
const dashStyle = {
  fontWeight: 700,
  fontSize: 15,
  color: "#9599B5",
};

const firstColumnStyle = {
  fontWeight: 600,
  fontSize: 15,
  color: "#525885",
};

const contentPositiveNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#3E3AFF",
};
const contentNegativeNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#F97172",
};

const columns: Columns[] = [
  {
    name: "Collection",
    selector: (row) => row.id,
    style: firstColumnStyle,
    sortable: true,
    cell: (row: IRow) => renderCollection(row.id),
    sortField: "collection",
  },
  {
    name: "Allocation",
    selector: (row) => row.item1,
    style: contentStyle,
    sortable: true,
    sortField: "allocation",
    cell: (row: IRow) => renderNFTs(row.item1),
  },
  {
    name: "Price (USD)",
    selector: (row) => row.item2,
    style: contentStyle,
    sortable: true,
    sortField: "price",
  },
  {
    name: "Value",
    selector: (row) => row.item3,
    style: contentStyle,
    sortable: true,
    sortField: "value",
  },
  {
    name: "Change VS USD (24 H)",
    selector: (row) => row.item4,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item4),
    sortable: true,
    sortField: "change",
  }
];

type PortfolioProps = {
  data: {
    id: string;
    item1: string;
    item2: string;
    item3: string;
    item4: string;
  }[];
};

const customStyles: TableStyles = {
  rows: {
    style: {
      "&:nth-child(odd)": {
        backgroundColor: "#F9F9F9",
      },
      border: "0px !important",
      BorderBottom: "none",
      boxShadow: "none",
      borderRadius: "6px",
      height: "auto",
    },
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "44.3px",
      PaddingTop: "12px",
      PaddingBottom: "13px",
      background: "white",
      height: "48px",
      borderBottom: 0,
      fontWeight: 700,
      fontSize: 15,
      color: "#9599B5",
    },
  },
  cells: {
    style: {
      borderBottom: 0,
      fontWeight: 400,
      fontSize: 15,
      color: "#525885",
      height: "auto",
    },
  },
};

const Portfolio = ({ data }: PortfolioProps) => {
  const dispatch = useAppDispatch();
  const [sortValue, setSortValue] = useState("all");
  const handleSort = async (
    selectedColumn: TableColumn<Data>,
    sortDirection: SortOrder,
    sortedRows: Data[]
  ) => {
    const sort = sortingFunction(selectedColumn?.sortField, sortDirection);
    dispatch(setSort({ sort: sort ?? 0 }));
  };
  const sortingFunction = (
    sortField: string | undefined,
    sortDirection: string
  ) => {
    switch (sortField) {
      case "collection":
        return sortCaseHandler(sortDirection, 1, 2);
      case "price":
        return sortCaseHandler(sortDirection, 5, 6);
      case "value":
        return sortCaseHandler(sortDirection, 7, 8);
      case "change":
        return sortCaseHandler(sortDirection, 9, 10);
      case "allocation":
        return sortCaseHandler(sortDirection, 11, 12);
    }
  };

  return (
    <>
      <h2 className={styles.title}>Portfolio</h2>
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Allocations</h2>
            </div>
            <DataTables
              handleSort={handleSort}
              customStyles={customStyles}
              selectableRows={false}
              columns={columns}
              data={data}
            />
          </div>
        </FlexBox>
      </div>
    </>
  );
};

export default Portfolio;
