import { Box, FormHelperText } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectToken } from "../../assets/icons/select-token-icon.svg";
import { useAlertMessage } from "../../context/AlertContext";
import {
  useGetAccountConfigsQuery,
  useGetBalanceQuery,
  useSwapTokensInfoMutation,
  useSwapTokensMutation,
  useGetPlatformTokensQuery,
  useGetPlatformCryptoTokenQuery,
} from "../../service/api";
import { convertExponentialToString } from "../../utils/helper";
import AlertMessage from "../AlertMessage";
import BackButton from "../BackBtn";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";
import { useAppSelector } from "../../store/hooks";

type Props = {
  onCancel?: () => void;
};

export default function SwapToken(props: Props) {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const [toTokens, setToTokens] = useState<{ name: string; value: string }[]>(
    []
  );
  const [fromTokens, setFromTokens] = useState<
    { name: string; value: string }[]
  >([]);
  const { data: platformTokens } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: crptoTokens } = useGetPlatformCryptoTokenQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (crptoTokens) {
      const tokens = crptoTokens?.data.map((token) => ({
        name: token.symbol.toUpperCase(),
        value: token.symbol,
      }));
      setFromTokens(tokens);
    }
  }, [crptoTokens]);

  useEffect(() => {
    if (platformTokens) {
      const tokens = platformTokens?.data.map((token) => ({
        name: token.name,
        value: token.symbol,
      }));
      setToTokens(tokens);
    }
  }, [platformTokens]);

  const navigate = useNavigate();
  const location = useLocation();
  const isTokenBuySellRoute =
    location.pathname.split("/")[1] === "token-buy-sell";
  const isIndexTokenRoute = location.pathname.split("/")[1] === "product";
  const [to, setTo] = useState(selectedToken?.symbol!);
  const [from, setFrom] = useState("usdc");
  const [loading, setLoading] = useState(false);
  const [networkFees, setNetworkFees] = useState<number>();
  const [fromValue, setFromValue] = useState(0);
  const [toValue, setToValue] = useState("0");
  const [getSwapInfo] = useSwapTokensInfoMutation();
  const [swapToken] = useSwapTokensMutation();
  const [validationError, setValidationError] = useState("");
  const [touched, setTouched] = useState(false);
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const { data, refetch } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: accountConfigsResults, isLoading: accountConfigLoading } =
    useGetAccountConfigsQuery(
      { symbol: from },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const accountConfigs = useMemo(() => {
    return (
      accountConfigsResults?.data || {
        maxBuyLimit: "0",
        minBuyLimit: "0",
        minimumWithdrawalLimit: "0",
        withdrawalLimit: "0",
      }
    );
  }, [accountConfigsResults]);

  const tokenBalances = data?.data?.balances || {};

  const tokenBalance = tokenBalances[from] || 0;

  const calculateNetworkFees = async () => {
    try {
      setLoading(true);
      if (fromValue === 0) {
        setValidationError("The value must be more than 0");
        return;
      }
      const { data } = await getSwapInfo({
        from,
        to,
        value: fromValue,
      }).unwrap();
      setNetworkFees(data.networkFees);
      setToValue(convertExponentialToString(data.tokenValue));
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      await swapToken({
        from,
        to,
        value: convertExponentialToString(fromValue),
        tokenSymbol: selectedToken?.symbol!,
      }).unwrap();
      setAlertMsg?.({
        type: "success",
        msg: "Token transferred to your wallet successfully.",
      });

      resetStates();
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
      refetch();
    }
  };

  const resetStates = () => {
    setNetworkFees(undefined);
    setToValue("");
    setFromValue(0);
  };

  function handleChange(cb: (value: string) => void) {
    return (e: any) => {
      resetStates();
      setTouched(true);
      cb(e.target.value);
    };
  }

  const handleMax = () => {
    const max = parseFloat(accountConfigs.maxBuyLimit);
    setFromValue(() => {
      if (tokenBalance < max) {
        return Number(
          (
            Math.floor(tokenBalance * Math.pow(10, 5)) / Math.pow(10, 5)
          ).toFixed(5)
        );
      }
      return Number(max.toFixed(5));
    });
  };

  // useEffect(() => {
  //   if (!isLoading && !data) {
  //     navigate("/");
  //   }
  //   if (data && !balance) {
  //     navigate("/");
  //   }
  // }, [data, isLoading, balance]);

  useEffect(() => {
    setFromValue(parseFloat(accountConfigs.minBuyLimit));
  }, [accountConfigs]);

  useEffect(() => {
    if (!touched) return;
    setValidationError("");
    const max = parseFloat(accountConfigs.maxBuyLimit);
    const min = parseFloat(accountConfigs.minBuyLimit);
    yup
      .number()
      .typeError("")
      .max(max, `This must be less than or equal to ${max}.`)
      .min(min, `This must be more than or equal to ${min}.`)
      .validate(fromValue)
      .then((value = 0) => {
        setValidationError(value > tokenBalance ? "Insufficient balance." : "");
      })
      .catch((error) => setValidationError(error?.message || ""));
  }, [accountConfigs, fromValue]);

  const hasNetworkFees = typeof networkFees !== "undefined";

  return (
    <>
      {(!isTokenBuySellRoute && !isIndexTokenRoute) && (
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      )}

      <div className={style.BuyNealthyToken}>
        <Dashboardtitle
          className={style.BuyNealthyTitle}
          description={"Swap"}
          title={`Buy ${selectedToken?.name} token`}
        />
        {alertMsg?.msg && (
          <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
            {alertMsg?.msg}
          </AlertMessage>
        )}

        <div className={style.BuyNealthyCard}>
          <h6>From</h6>
          <span>Balance: {parseFloat(tokenBalance + "").toFixed(5)}</span>
          <div className={style.BuyNealthycrytoValue}>
            <div className={style.inputContainer}>
              <input
                type="number"
                value={fromValue}
                className={style.input}
                placeholder="0"
                onChange={handleChange((value) => {
                  setFromValue(parseFloat(value));
                })}
              />
              {validationError && (
                <FormHelperText error>{validationError}</FormHelperText>
              )}
              <span>~ 0.00 (0.00%)</span>
            </div>
            <div className={style.BuyNealthyoptons}>
              <button onClick={handleMax}>Max</button>
              <select value={from} onChange={handleChange(setFrom)}>
                {fromTokens.map((token) => (
                  <option key={token.value} value={token.value}>
                    {token.name}
                  </option>
                ))}
              </select>
              <SelectIcon />
            </div>
          </div>
        </div>
        <div className={style.BuyNealthyCard}>
          <SelectToken className={style.douwnArrow} />
          <h6>To</h6>
          <div className={style.BuyNealthycrytoValue}>
            <div className={style.inputContainer}>
              <input
                type="number"
                value={toValue}
                className={style.input}
                readOnly
              />
              <span>~ 0.00 (0.00%)</span>
            </div>
            <div className={style.BuyNealthyoptons}>
              <select
                value={to}
                onChange={handleChange(setTo)}
                className={style.select}
              >
                {toTokens.map((token) => (
                  <option
                    disabled={selectedToken?.name === token.name ? false : true}
                    key={token.value}
                    value={token.value}
                  >
                    {token.name}
                  </option>
                ))}
              </select>
              <SelectIcon />
            </div>
          </div>
        </div>

        {hasNetworkFees && (
          <div className={style.orderSummary}>
            <h4>1 ${selectedToken?.name} = 0.00001 ETH</h4>
            <div className={style.orderList}>
              <p>Network Fee</p>
              <strong>{networkFees} ETH</strong>
            </div>
          </div>
        )}

        <Box gap="20px" flexDirection="row" display="flex">
          {props.onCancel && (
            <CustomButton
              className={style.BuyNealthyBtn}
              variant={"secondary"}
              disabled={loading}
              onClick={props.onCancel}
            >
              Cancel
            </CustomButton>
          )}
          {hasNetworkFees && (
            <CustomButton
              loading={loading}
              className={style.BuyNealthyBtn}
              variant={"primary"}
              disabled={!!validationError}
              onClick={handleSwap}
            >
              Confirm Buy
            </CustomButton>
          )}
          {!hasNetworkFees && (
            <CustomButton
              loading={loading}
              disabled={!!validationError}
              className={style.BuyNealthyBtn}
              variant={"primary"}
              onClick={calculateNetworkFees}
            >
              Buy
            </CustomButton>
          )}
        </Box>
      </div>
    </>
  );
}
