import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { BackOrange, Down, Exchange, LineGraph, Setting } from "../../assets";
import Allocation from "../../components/Allocation";
import AllocationMobile from "../../components/AllocationMobile";
import Card from "../../components/Card";
import CustomButton from "../../components/CustomButton";
import { useAppSelector } from "../../store/hooks";
import styles from "./index.module.scss";

export type AllocationType = {
  data: {
    collection: string;
    by: string;
    nfts: string;
    price: string;
    change: string;
  };
  index: number;
};

type Amount = {
  type: string;
  isMax: boolean;
  coin: string;
};
const allocationData = [
  {
    collection: "CRYPTOPUNK",
    by: "By Larva Labs",
    nfts: "2.334",
    price: "15.80",
    change: "2.55",
  },
  {
    collection: "BAYC",
    by: "By Larva Labs",
    nfts: "1.234",
    price: "15.80",
    change: "2.55",
  },
  {
    collection: "MAYC",
    by: "By Larva Labs",
    nfts: "1.234",
    price: "15.80",
    change: "2.55",
  },
  {
    collection: "AZUKI",
    by: "By Larva Labs",
    nfts: "1.234",
    price: "15.80",
    change: "2.55",
  },
];
const MyToken = () => {
  const { isKYCed, selectedToken } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <div className={clsx(styles.myTokenContainer)}>
      <div className={styles.back} onClick={() => navigate(-1)}>
        <BackOrange />
        <p>Back</p>
      </div>
      <h2 className={styles.heading}>${selectedToken?.name} Token</h2>
      <div className={styles.wrapper}>
        <div className={styles.cardLeft}>
          <div className={styles.innerCard}>
            <Card>
              <div className={styles.cardInner}>
                <div className={styles.cardInnerContent}>
                  <div className={styles.cardInnerContentLeft}>
                    <p>Price</p>
                    <div className={styles.priceContainer}>
                      <h3>$ 1,474.91</h3>
                      <span>4.48% APY</span>
                    </div>
                    <h6 className={styles.rate}>+$35.06(+2.14%)</h6>
                    <div className={styles.priceMob}>
                      <div className={styles.priceContainerMob}>
                        <span>4.48% APY</span>
                        <h6>+$35.06(+2.14%)</h6>
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardInnerContentRight}>
                    <p>1h</p>
                    <p>24h</p>
                    <p className={styles.active}>1w</p>
                    <p>1m</p>
                    <p>1y</p>
                    <p>All</p>
                  </div>
                </div>
                <LineGraph className={styles.svg} />
              </div>
            </Card>
          </div>
          <div className={styles.innerCard}>
            <Card>
              <div className={styles.statContainer}>
                <h2>Stats</h2>
                <div className={styles.statDEtail}>
                  <div className={styles.statItem}>
                    <p>Market Cap</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>Volume(24h)</p>
                    <h3>$65.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>Current Supply</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>Streaming Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>Mint Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>Redeem Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={styles.statItem}>
                    <p>NAV</p>
                    <h3>$685.14K</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.innerCard}>
            <Card>
              <>
                <div className={styles.innerCardAllocation}>
                  <h2>Allocations</h2>
                  <div className={styles.allocationHeader}>
                    <div
                      className={clsx(styles.allocationHeaderHead, styles.pos)}
                    >
                      <p>Pos</p>
                    </div>
                    <div className={styles.allocationHeaderHead}>
                      <p>Collection</p>
                    </div>
                    <div className={styles.allocationHeaderHead}>
                      <p>NFTs</p>
                    </div>
                    <div className={styles.allocationHeaderHead}>
                      <p>Price(USD)</p>
                    </div>
                    <div className={styles.allocationHeaderHead}>
                      <p>Change VS USD (24H)</p>
                    </div>
                  </div>
                  <div>
                    {allocationData.map((item, index) => (
                      <Allocation
                        index={index}
                        data={item}
                        key={item.collection}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.allocationMob}>
                  <h2 className={styles.allocationTitle}>Allocations</h2>
                  {allocationData.map((item, index) => (
                    <AllocationMobile
                      index={index}
                      data={item}
                      key={item.collection}
                    />
                  ))}
                </div>
              </>
            </Card>
          </div>
        </div>

        <div className={styles.cardRight}>
          <Card>
            <div>
              <div className={styles.swapContainer}>
                <h2>Swap</h2>
                <Setting />
              </div>
              <div className={styles.coinDetail}>
                <Amount type="From" isMax={true} coin="ETH" />
                <Exchange className={styles.exchangeIcon} />
                <Amount
                  type="To"
                  isMax={false}
                  coin={`$${selectedToken?.name}`}
                />
              </div>
              <CustomButton
                className={styles.btn}
                onClick={() => navigate("/staking")}
              >
                Enter Amount
              </CustomButton>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
const Amount = ({ type, isMax, coin }: Amount) => {
  return (
    <div className={styles.amountContainer}>
      <div className={styles.amountTop}>
        <h6>{type}</h6>
        <p>Balance: 0.0023,7</p>
      </div>
      <div className={styles.amountBottom}>
        <h3>0.00</h3>
        <div className={styles.amountBottomRight}>
          {isMax && (
            <div className={styles.max}>
              <span>Max</span>
            </div>
          )}
          <div className={styles.coin}>
            <p>{coin}</p>
            <Down />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyToken;
