import clsx from "clsx";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import CustomModal from "../../common/CustomModal";
import FlexBox from "../../common/FlexBox";
import CustomButton from "../../CustomButton";
import Title from "../../Title";
import style from "../Rewards/index.module.scss";
type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable: boolean;
};
export default function Rewards() {
  const [claim, setClaim] = useState(false);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);

  const claimPopup = () => {
    setClaim((claim) => !claim);
  };
  const opentransactions = () => {
    setTransactionConfirmed((transactionConfirmed) => !transactionConfirmed);
  };

  const dummyData = [
    {
      id: 1,
      mission: "Registered Friends Referral",
      points: "150",
      amount: (
        <p className={style.darkText}>
          -20.00$Token1<span> $20.00</span>
        </p>
      ),
      clamable: "18.04.2022",
      remaining: "23d  : 6h : 23m  ",
      action: (
        <CustomButton
          onClick={() => {
            claimPopup();
          }}
          className={style.claimBtn}
          variant="outlined"
        >
          Claim
        </CustomButton>
      ),
    },
    {
      id: 2,
      mission: "Airdrop",
      points: "100",
      amount: (
        <p className={style.darkText}>
          -20.00$Token1<span> $20.00</span>
        </p>
      ),
      clamable: "18.04.2022",
      remaining: "23d  : 6h : 23m  ",
      action: (
        <CustomButton
          onClick={() => {
            claimPopup();
          }}
          className={style.claimBtn}
          variant="outlined"
        >
          Claim
        </CustomButton>
      ),
    },
  ];
  const columns: Columns[] = [
    {
      name: "Mission",
      selector: (row) => row.mission,
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => row.points,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Claimable until",
      selector: (row) => row.clamable,
      sortable: true,
    },
    {
      name: "Remaining time",
      selector: (row) => row.remaining,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
    },
  ];
  const tableCustomCss = {
    rows: {
      style: {
        backgroundColor: "#F9F7FC",
        border: "0 !important",
        borderRadius: "12px",
        marginBottom: "12px",
        minHeight: "64px",
      },
    },
    headCells: {
      style: {
        color: "#9599B5",
        fontSize: "12px",
        fontWeight: "500",
        border: "0 !important",
      },
    },
    cells: {
      style: {
        color: "#190044",
        fontSize: "14px",
        fontWeight: "500",
        paddingRight: "14px",
        paddingLeft: "14px",
      },
    },
  };
  const popupData = [
    {
      content: "Mission accomplished",
      price: "Registered Referral",
    },
    {
      content: "Points earned",
      price: "150",
    },
    {
      content: "Claimable reward",
      price: "120 $Token1",
    },
  ];
  return (
    <div className={style.rewards}>
      <FlexBox className={style.mywalletTable}>
        <FlexBox className={style.tableButtons}>
          <CustomButton className={clsx(style.eachButton, style.active)}>
            Claimable rewards
          </CustomButton>
          <CustomButton variant="outlined" className={style.eachButton}>
            Historical rewards
          </CustomButton>
        </FlexBox>
      </FlexBox>
      <DataTable
        columns={columns}
        data={dummyData}
        responsive={true}
        customStyles={tableCustomCss}
      />
      <CustomModal isOpen={claim} toggle={claimPopup}>
        <Title title="Claim your rewards" className={style.rewardTitel} />
        <p className={style.eligibletext}>
          Here are the rewards you are eligible to claim
        </p>
        <div className={style.popupcont}>
          {popupData.map((popupItems, index) => {
            return (
              <FlexBox className={style.popupItems}>
                <li>{popupItems.content}</li>
                <li>
                  <strong>{popupItems.price}</strong>
                </li>
              </FlexBox>
            );
          })}
        </div>
        <FlexBox className={style.confirmButton}>
          <CustomButton
            onClick={() => {
              setClaim(false);
            }}
            className={style.buttons}
            variant="outlined"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setClaim(false);
              opentransactions();
            }}
            className={style.buttons}
          >
            Claim
          </CustomButton>
        </FlexBox>
      </CustomModal>
      <CustomModal isOpen={transactionConfirmed} toggle={opentransactions}>
        <Title title="Claim your rewards" className={style.transactionsTitel} />
        <p className={clsx(style.eligibletext, style.textCenter)}>
          You have successfully claimed all your rewards. Keep going to earn
          more!
        </p>
        <CustomButton
          onClick={() => {
            setTransactionConfirmed(false);
          }}
          className={style.gotItButton}
        >
          Got it!
        </CustomButton>
      </CustomModal>
    </div>
  );
}
