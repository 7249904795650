const config: AppConfig = {
  BASE_API_URL: "https://apidevinfra.nealthy.com",
  BASE_URL: "https://appdevinfra.nealthy.com",
  GOOGLE_API_KEY:
    "804165985119-5o0iq7m296o094uetv9siqamhum80l5t.apps.googleusercontent.com",
  GOOGLE_API_SECRET_KEY: "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",
  FB_APP_ID: "663095355795986",
  GOOGLE_OAUTH_INFO: "https://www.googleapis.com/oauth2/v3/userinfo",
  TRANSAK_API_KEY: "85e3a344-e0bc-4c31-bea9-bafb15baa4e0",
  CONTACT_MANAGER_EMAIL: "noreply.75way@gmail.com",
  APPLE_BUNDLE_ID: "com.nealthy.auth.client",
  TRANSAK_ENVIRONMENT: "STAGING",
  REMOVED_ACCESS_MODULE_PATH: [],
  REMOVED_ACCESS_MODULE: [],
  REMOVED_SOCIAL_BUTTON: [],
  CONTRACT_ADDRESS: {
    token: "0x0DD24989f9b75eddea179A333c0a3e4e64201600",
    usdc: "0xBe72EC4f68dF0Eac57bF594F0ae9a8574a180114",
    usdt: "0x72999f6C258244e53b0155354F6E384C55E78A94",
    dai: "0x2636E8088d0b526b0e6Bf5EfcC2b5DC3dA5E19Ed",
  },
};

export default config;
