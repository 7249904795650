import { KeyboardEventHandler, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useAlertMessage } from "../../context/AlertContext";
import {
  emailResponse,
  useEmailLoginMutation,
  useGetEmailOtpQuery,
  useVerifyFbEmailOtpMutation,
  useVerifyOtpMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logoutUser, saveEmailOnLogin } from "../../store/reducers/authSlice";
import AlertMessage from "../AlertMessage";
import CustomButton from "../CustomButton";
import { OnBoardingProps } from "../PersonalInfo";
import CustomModal from "../common/CustomModal";
import styles from "./index.module.scss";

const EmailVerification = ({
  activeStep,
  setActiveStep,
  email,
  affliateEmailVerification,
}: OnBoardingProps) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();
  const { user }: any = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { data: emailOtpResponse, isLoading: otpLoading } = useGetEmailOtpQuery(
    user?.id
  );
  const [verifyFbEmailOtp, { isLoading: verifyOtpLoading }] =
    useVerifyFbEmailOtpMutation();
  const [emailLogin, { isLoading: loading }] = useEmailLoginMutation();

  useEffect(() => {
    if (!otpLoading && emailOtpResponse) {
    }
  }, [otpLoading]);

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();

  const handleClick = async () => {
    const payload = {
      userId: user?.id,
      otp,
      type: "affiliate",
      email: user?.email,
    };

    if (affliateEmailVerification == true) {
      const { data, error }: any = (await verifyOtp(payload)) as apiResponse;
      if (data) {
        dispatch(saveEmailOnLogin(user?.email));
        navigate("/login");
        dispatch(logoutUser());
      }
      if (error) {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
        // navigate("/login");
      }
    } else {
      const { data, error } = (await verifyFbEmailOtp(payload)) as apiResponse;
      if (data) {
        setActiveStep((prev) => prev + 1);
      }
      if (error) {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
      }
    }
  };

  const toggle = () => setIsOpen(!isOpen);
  const openHandle = () => {
    setIsOpen(true);
  };

  // const [verifyOtp, { isLoading }] = useVerifyOtpMutation();

  // const emailVerificationHandler = async (e: any) => {
  //   const { email } = e;
  //   const { data, error }: any = await verifyOtp({ email });
  //   if (data) {
  //     navigate("/onboarding", {
  //       state: {
  //         email,
  //       },
  //     });
  //   }
  //   if (error) {
  //     console.log(error, "error");
  //   }
  // };
  const resendOtpCode = async () => {
    const email = user?.email;
    if (loading) return;
    if (!email) return;
    const { data, error } = (await emailLogin({ email })) as emailResponse;
    if (data) {
      setAlertMsg?.({
        type: "success",
        msg: data?.message,
      });
      setOtp("");
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.data?.message,
      });
      console.log(error, "error");
    }
  };
  const enterKeyHandler: KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === "Enter" && !verifyOtpLoading) {
      if (otp.length < 6) return;
      handleClick();
    }
  };
  return (
    <>
      <div className={styles.container}>
        <h2>Verify your e-mail address</h2>
        <p>Please enter 6-digit code we sent you to{email}</p>
        {alertMsg?.msg && (
          <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
            {alertMsg?.msg}
          </AlertMessage>
        )}
        <div className={styles.otpp}>
          <form onKeyDown={enterKeyHandler} className={styles.verify_code}>
            <OTPInput
              onChange={setOtp}
              value={otp}
              inputStyle="inputStyle"
              containerStyle={styles.reactOtp}
              numInputs={6}
              shouldAutoFocus={true}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props) => {
                return (
                  <input
                    {...props}
                    id="otp"
                    type="number"
                    value={props.value}
                    className={styles.inputOtp}
                    placeholder="0"
                  />
                );
              }}
            />
          </form>
        </div>
        <div className={styles.resendBtn}>
          <h5 onClick={resendOtpCode}>Resend Verification Code</h5>
          <h5 onClick={openHandle}>Change Email Address</h5>
        </div>
        <div className={styles.btnContainer}>
          <CustomButton
            className={styles.btn}
            variant="outlined"
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={user?.onboardingStep !== 0}
          >
            Back
          </CustomButton>
          <CustomButton
            className={styles.btn}
            disabled={otp.length !== 6}
            onClick={handleClick}
            loading={verifyOtpLoading}
          >
            Continue
          </CustomButton>
        </div>
        <div className={styles.resendBtnMobile}>
          <h5>Resend Verification Code</h5>
          <h5 onClick={openHandle}>Change Email Address</h5>
        </div>
      </div>
      <CustomModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.modal}>
          <h2>Change email address?</h2>
          <p>Are you sure you want to restart with new email address?</p>
          <div className={styles.modalBtn}>
            <CustomButton
              variant="outlined"
              className={styles.btn}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className={styles.btn}
              onClick={() => setIsOpen(false)}
            >
              Restart
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default EmailVerification;
