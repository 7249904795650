import { Button, CircularProgress, FormHelperText } from "@mui/material";
import transakSDK from "@transak/transak-sdk";
import clsx from "clsx";
import countryToCurrency, { Countries, Currencies } from "country-to-currency";
import { ReactElement, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import { environment } from "../../environment";
import SelectTokenPage from "../../pages/SelectToken";
import {
  useGetAccountConfigsQuery,
  useGetBalanceQuery,
} from "../../service/api";
import { useAppSelector } from "../../store/hooks";
import CustomButton from "../CustomButton";
import Input from "../Input";
import Title from "../Title";
import CustomModal from "../common/CustomModal";
import FlexBox from "../common/FlexBox";
import style from "./index.module.scss";

export type TypeCard = "BUY_CRYPTO" | "BUY_N_TOKEN" | "SELL_N_TOKEN";

type Props = {
  title?: string;
  subtile?: string;
  desCription?: string;
  butBtn: String;
  to: string;
  icon?: ReactElement;
  isInfoIcon?: boolean;
  className?: string;
  type: TypeCard;
  selectedToken?: PlatformToken;
};

export default function WalletCard(Props: Props) {
  const {
    title,
    icon,
    isInfoIcon,
    subtile,
    desCription,
    butBtn,
    to,
    type,
    className,
    selectedToken,
  } = Props;

  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [buyValue, setBuyValue] = useState<number>(0);
  const handleBuyValue = (value) => {
    setBuyValue(value);
  };
  const handleBuyAmountModal = (value: boolean) => {
    setIsBuyAmountModalOpen(value);
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openBuyToken, setOpenBuyToken] = useState(false);

  const [isBuyAmountModalOpen, setIsBuyAmountModalOpen] =
    useState<boolean>(false);
  const { setAlertMsg } = useAlertMessage();

  const settings = {
    apiKey: environment.TRANSAK_API_KEY,
    environment: environment.TRANSAK_ENVIRONMENT, // STAGING/PRODUCTION
    network: "ethereum",
    walletAddress: user?.depositAddress?.address,
    cryptoCurrencyList: "DAI,USDT,USDC",
    themeColor: "#4E307A", // App theme color
    hostURL: window.location.origin,
    widgetHeight: "600px",
    widgetWidth: "450px",
    isAutoFillUserData: true,
    fiatAmount: buyValue,
    fiatCurrency: countryToCurrency[
      (user?.countryOfResidence || "US") as Countries
    ] as Currencies,
    userData: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      mobileNumber: user?.phone,
      dob: "", //YYYY-MM-DD
      address: {
        addressLine1: user?.address,
        addressLine2: user?.address,
        city: "",
        state: "",
        postCode: "",
        countryCode: user?.countryOfResidence,
      },
    },
  };

  const cryptoBuy = () => {
    let transak = new transakSDK(settings);
    transak.init();
    // To get all the events
    transak.on(transak.ALL_EVENTS, (data: any) => {
      console.log(data);
    });
    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData: any) => {
      transak.close();
    });
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      console.log(orderData);
      transak.close();
    });
  };

  const handleBuyTokenChange = (type: "FIAT" | "CRYPTO") => {
    const action = type === "CRYPTO" ? buyNealthyToken : cryptoBuy;
    setOpenBuyToken(false);
    action();
  };

  const buyNealthyToken = async () => {
    if (!user?.depositAddress) {
      setAlertMsg?.({
        type: "error",
        msg: "Deposit address is not available for this user",
      });
      return;
    }
    navigate("/swap-token");
  };

  const sellNealthyToken = () => {
    setOpen(false);
    navigate("/sell-token");
  };

  const action =
    type === "BUY_CRYPTO"
      ? () => {
          setOpen(false);
          setIsBuyAmountModalOpen(true);
          console.log(isBuyAmountModalOpen);
        }
      : type === "BUY_N_TOKEN"
      ? () => {
          setOpenBuyToken(true);
          setOpen(false);
        }
      : sellNealthyToken;

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div className={clsx(style.walletCard, className)}>
      <div className={style.icon}>{icon}</div>
      <div className={style.icon2}>
        <InfoIcon />
      </div>
      <h4>{title}</h4>
      <p>{desCription}</p>
      <CustomButton loading={isLoading} onClick={handleClick}>
        {butBtn}
      </CustomButton>
      <CustomModal isOpen={open} toggle={() => setOpen(false)}>
        <SelectTokenPage onSelect={action} />
      </CustomModal>
      <CustomModal isOpen={openBuyToken} toggle={() => setOpenBuyToken(false)}>
        <BuyTokenOptions
          selectedToken={selectedToken}
          handleBuyValue={handleBuyValue}
          onChange={handleBuyTokenChange}
          onCancel={() => setOpenBuyToken(false)}
        />
      </CustomModal>
      {isBuyAmountModalOpen && (
        <BuyNstableModal
          selectedToken={selectedToken}
          handleBuyAmountModal={handleBuyAmountModal}
          handleBuyValue={setBuyValue}
          onChange={handleBuyTokenChange}
          onCancel={() => setIsBuyAmountModalOpen(false)}
        />
      )}
    </div>
  );
}

type BuyTokenOptionsProps = {
  selectedToken?: PlatformToken;
  onChange: (type: "FIAT" | "CRYPTO") => void;
  onCancel?: () => void;
  handleBuyValue: (value: number) => void;
};

type BuyTokenOptionsType = "FIAT" | "CRYPTO";

export function BuyTokenOptions(props: BuyTokenOptionsProps) {
  const { onChange, onCancel, handleBuyValue, selectedToken } = props;
  const { data, isLoading, error } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { setAlertMsg } = useAlertMessage();

  const [type, setType] = useState<BuyTokenOptionsType>("FIAT");
  const balances = data && data.data.balances;
  const totalBalance = Object.values(balances || {}).reduce(
    (acc, value: number) => acc + value,
    0
  );
  const enable = totalBalance > 0;

  const handleChange = (type: BuyTokenOptionsType) => {
    return () => {
      setType(type);
    };
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [buyAmount, setBuyAmount] = useState<number>(0);
  const [nstblValidationError, setNstblValidationError] = useState("");

  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
    setNstblValidationError("");
  };

  return (
    <>
      <FlexBox className={style.buttonBox}>
        <div className={style.header}>
          <h3>Buy {selectedToken?.name} token</h3>
          <p>Please select your desired method.</p>
        </div>
        <FlexBox className={style.buttonBox}>
          <Button
            className={clsx(style.button, type === "FIAT" && style.active)}
            onClick={handleChange("FIAT")}
          >
            <h6>Fiat</h6>
            <p>Buy {selectedToken?.name} with fiat</p>
          </Button>
          <div className={style.buttonWrapper}>
            <Button
              disabled={!enable}
              className={clsx(style.button, type === "CRYPTO" && style.active)}
              onClick={handleChange("CRYPTO")}
            >
              {isLoading && <CircularProgress size={20} color="primary" />}
              {!isLoading && (
                <>
                  <h6>Crypto</h6>
                  <p>Buy {selectedToken?.name} with cryptocurrencies</p>
                </>
              )}
            </Button>
            {!enable && !isLoading && (
              <FormHelperText sx={{ width: "100%", ml: 1 }} error>
                {error
                  ? // @ts-ignore
                    error?.data?.message ||
                    "Something went wrong while fetching balance"
                  : "Insufficient balance"}
              </FormHelperText>
            )}
          </div>
        </FlexBox>
        <FlexBox className={style.action}>
          {onCancel && (
            <CustomButton variant="outlined" onClick={() => onCancel()}>
              Cancel
            </CustomButton>
          )}
          <CustomButton
            onClick={() => (type === "FIAT" ? toggle() : onChange(type))}
          >
            Continue
          </CustomButton>
        </FlexBox>
      </FlexBox>

      {/* <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <div className={style.nstblModal}>
          <Title title={"Enter NSTBL to Buy"} />
          <Input
            type="number"
            name="nstblAmount"
            id="nstblAmount"
            placeholder="Enter NSTBL amount to buy"
            onChange={(e) => {
              setBuyAmount(parseInt(e.target.value));
              handleBuyValue(parseInt(e.target.value))
              setNstblValidationError("");
            }}
          />

          <FormHelperText sx={{ width: "100%", ml: 1 }} error>
            {nstblValidationError ?? ""}
          </FormHelperText>

          <FlexBox className={style.gotItBtn}>
            <CustomButton variant="outlined" onClick={toggle}>
              Cancel
            </CustomButton>
            <CustomButton onClick={() => validateNstblAmount()}>
              Continue
            </CustomButton>
          </FlexBox>
        </div>
      </CustomModal> */}
      {/* show BuyNstableModalProps if fiat is selected */}
      {type === "FIAT" && isModalOpen && (
        <BuyNstableModal
          selectedToken={selectedToken}
          handleBuyAmountModal={toggle}
          handleBuyValue={handleBuyValue}
          onChange={onChange}
          onCancel={toggle}
        />
      )}
    </>
  );
}

type BuyNstableModalProps = {
  selectedToken?: PlatformToken;
  onChange: (type: "FIAT" | "CRYPTO") => void;
  onCancel?: () => void;
  handleBuyValue: (value: number) => void;
  handleBuyAmountModal: (value: boolean) => void;
};

export function BuyNstableModal(props: BuyNstableModalProps) {
  const {
    onChange,
    onCancel,
    handleBuyValue,
    handleBuyAmountModal,
    selectedToken,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [buyAmount, setBuyAmount] = useState<number>(0);
  const [nstblValidationError, setNstblValidationError] = useState("");
  const { setAlertMsg } = useAlertMessage();
  const [type, setType] = useState<BuyTokenOptionsType>("FIAT");

  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
    handleBuyAmountModal(false);
    setNstblValidationError("");
  };
  const { data: accountConfigsResults, isLoading: accountConfigLoading } =
    useGetAccountConfigsQuery(
      { symbol: selectedToken?.symbol! },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const accountConfigs = useMemo(() => {
    return (
      accountConfigsResults?.data || {
        minBuyLimit: "0",
        maxBuyLimit: "0",
        minimumWithdrawalLimit: "0",
        withdrawalLimit: "0",
      }
    );
  }, [accountConfigsResults]);
  const validateNstblAmount = () => {
    if (
      buyAmount >= parseInt(accountConfigs?.minBuyLimit) &&
      buyAmount <= parseInt(accountConfigs?.maxBuyLimit)
    ) {
      onChange(type);
      toggle();
    } else {
      setNstblValidationError(
        `Entered ${selectedToken?.name} amount must be between min ${accountConfigs?.minBuyLimit} to max ${accountConfigs?.maxBuyLimit}`
      );
      setAlertMsg?.({
        type: "error",
        msg: `Entered ${selectedToken?.name} amount must be between min ${accountConfigs?.minBuyLimit} to max ${accountConfigs?.maxBuyLimit}`,
      });
    }
  };

  return (
    <CustomModal isOpen={isModalOpen} toggle={toggle}>
      <div className={style.nstblModal}>
        <Title title={`Enter ${selectedToken?.name} to Buy`} />
        <Input
          type="number"
          name="nstblAmount"
          id="nstblAmount"
          placeholder={`Enter ${selectedToken?.name} to Buy`}
          onChange={(e) => {
            setBuyAmount(parseInt(e.target.value));
            handleBuyValue(parseInt(e.target.value));
            setNstblValidationError("");
          }}
        />

        <FormHelperText sx={{ width: "100%", ml: 1 }} error>
          {nstblValidationError ?? ""}
        </FormHelperText>

        <FlexBox className={style.gotItBtn}>
          <CustomButton variant="outlined" onClick={toggle}>
            Cancel
          </CustomButton>
          <CustomButton onClick={() => validateNstblAmount()}>
            Continue
          </CustomButton>
        </FlexBox>
      </div>
    </CustomModal>
  );
}
