import { useEffect, useState } from "react";
import { FoxIcon, InputIcon, CircularTick } from "../../assets";
import styles from './index.module.scss'
import copy from "copy-to-clipboard";

export default function CopyToClipboard({ value, isPolicies, isDepositor, isFees }: { value: string, isPolicies?: boolean, isDepositor?: boolean, isFees?: boolean }) {
  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  const copyVal = (val: string) => {
    if (val) { copy(val); setCopySuccess(true) }
  };
  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false)
    }, 2000);
  }, [copySuccess])
  return (
    <>
      <div className={styles.managerAddressPolicy}>
        <div className={styles.imageContainer}>
          <div className={styles.managerImage}>
            <FoxIcon />
          </div>
        </div>
        <div className={styles.address}>{(isPolicies || isDepositor || isFees) ? value.slice(0, 3) + "....." + value.slice(10, 12) : value}</div>
        <div className={styles.copyBtn}>{copySuccess ? <CircularTick stroke="#4e307a" /> : <InputIcon onClick={() => copyVal(value)} />}</div>
      </div>
    </>
  )
}
