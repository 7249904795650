import { CheckCircleOutline, MoreHoriz, Pending } from "@mui/icons-material";
import { Box } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import useSellOrder from "../../../hooks/useSellOrder";
import { formatAmountToFixed } from "../../../utils/helper";
import CustomButton from "../../CustomButton";
import Spinner from "../../Spinner";
import FlexBox from "../../common/FlexBox";
import style from "../Transactions/index.module.scss";

type Props = {
  status?: SellOrder["status"];
  title?: string;
};

const getIconByStatus = (status: SellOrder["status"]) => {
  switch (status) {
    case "FILLED":
      return <CheckCircleOutline color="success" />;
    case "NOT_FILLED":
      return <Pending color="info" />;
    default:
      return <MoreHoriz color="warning" />;
  }
};
const tokens = [
  "ada",
  "bnb",
  "btc",
  "doge",
  "eth",
  "jton",
  "sol",
  "usdc",
  "usdt",
  "xrp",
];

const cryptoTokens: TableColumn<SellOrder>[] = tokens.map((token) => ({
  name: token.toUpperCase(),
  selector: (row) => row[token] as number,
  sortable: true,
  cell: (row) => {
    return <p className={style.amount}>{formatAmountToFixed(row[token])}</p>;
  },
  style: { textAlign: "left" },
}));

const columns: TableColumn<SellOrder>[] = [
  {
    name: "Token",
    selector: (row) => row.symbol,
    sortable: true,
    cell: (row) => {
      return <p className={style.amount}>{row.symbol}</p>;
    },
    style: { textAlign: "left" },
  },
  ...cryptoTokens,
  {
    name: "Created at",
    selector: (row) => dayjs(row.createdAt).format("DD.MM.YYYY"),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    cell: (row) => getIconByStatus(row.status),
  },
];

const tableCustomCss = {
  rows: {
    style: {
      backgroundColor: "#F9F7FC",
      border: "0 !important",
      borderRadius: "12px",
      marginBottom: "12px",
      minHeight: "64px",
      overflow: "hidden",
    },
  },
  headCells: {
    style: {
      color: "#9599B5",
      fontSize: "12px",
      fontWeight: "500",
      border: "0 !important",
    },
  },
  cells: {
    style: {
      color: "#190044",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
};

const ChildrenOrders: React.FC<ExpanderComponentProps<SellOrder>> = (props) => {
  const styles = {
    ...tableCustomCss,
    rows: {
      style: {
        ...tableCustomCss.rows.style,
        borderRadius: "0px",
        margin: "0px",
        borderBottom: "1px solid #dddeed !important",
        "&:first-child": {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
        "&:last-child": {
          borderBottom: "unset !important",
          marginBottom: tableCustomCss.rows.style.marginBottom,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
    },
    table: {
      style: {
        "& > div": {
          margin: 0,
        },
      },
    },
  };
  return (
    <Box marginLeft="48px">
      <DataTable
        columns={columns}
        data={props.data.partialOrders}
        noHeader
        noTableHead
        customStyles={styles}
      />
    </Box>
  );
};

export default function SellOrders(props: Props) {
  const { title, status } = props;

  const { sellOrders, applyFilter, loadMoreCount, loadMore, filter, loading } =
    useSellOrder({ status });

  console.log(sellOrders);

  return (
    <div className={style.transactions}>
      {title && <h3>{title}</h3>}
      {!status && (
        <FlexBox className={style.mywalletTable}>
          <FlexBox className={style.tableButtons}>
            <CustomButton
              variant="outlined"
              className={clsx(style.eachButton, filter === "" && style.active)}
              onClick={applyFilter("")}
            >
              All<span>&nbsp;time</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "7d" && style.active
              )}
              onClick={applyFilter("7d")}
            >
              Last 7 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "30d" && style.active
              )}
              onClick={applyFilter("30d")}
            >
              Last 30 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "6m" && style.active
              )}
              onClick={applyFilter("6m")}
            >
              Last 6 m<span>onths</span>
            </CustomButton>
          </FlexBox>
        </FlexBox>
      )}
      <DataTable
        columns={columns}
        data={sellOrders}
        expandableRows
        expandableRowDisabled={(row) => row?.partialOrders?.length === 0}
        expandableRowsComponent={ChildrenOrders}
        customStyles={tableCustomCss}
      />
      {loading && <Spinner />}
      {loadMoreCount !== 0 && (
        <CustomButton
          variant="outlined"
          className={style.loadMore}
          onClick={loadMore}
        >
          Load {loadMoreCount} More
        </CustomButton>
      )}
    </div>
  );
}
