import clsx from "clsx";
import style from "./index.module.scss";
type Props = {
  Icon?: React.ReactElement;
  title: string;
  description: React.ReactElement;
  className?: string;
};
export default function OverviewWallet(Props: Props) {
  const { Icon, title, description, className } = Props;
  return (
    <div className={clsx(style.overViewCard, className)}>
      {Icon}
      <h3>{title}</h3>
      <div>{description}</div>
    </div>
  );
}
