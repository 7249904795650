import clsx from "clsx";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "../../assets";
import SelectTokenPage from "../../pages/SelectToken";
import Card from "../Card";
import CustomButton from "../CustomButton";
import CustomModal from "../common/CustomModal";
import style from "./index.module.scss";

type Props = {
  title?: string;
  subtile?: string;
  description?: string;
  buttonText: String;
  to: string;
  icon?: ReactElement;
  isInfoIcon?: boolean;
  className?: string;
  type: string;
  toolTipText?: string;
};

const HomeCard = (props: Props) => {
  const navigate = useNavigate();
  const {
    title,
    icon,
    description,
    toolTipText,
    buttonText,
    type,
    to,
    className,
  } = props;
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const isComingSoon = type === "COMING_SOON";

  return (
    <Card
      className={clsx(
        { [style.card]: true, [style.comingSoon]: isComingSoon },
        className
      )}
    >
      <div className={style.cardBody}>
        <div className={style.icon}>{icon}</div>
        {toolTipText && (
          <div className={style.toolTip}>
            <InfoIcon />
          </div>
        )}
        <h4 className={style.title}>{title}</h4>
        <p className={style.text}>{description}</p>
        <CustomButton
          onClick={onClick}
          className={clsx({
            [style.button]: true,
            [style.inactive]: isComingSoon,
          })}
        >
          {buttonText}
        </CustomButton>
        <CustomModal isOpen={open} toggle={() => setOpen(false)}>
          <SelectTokenPage onSelect={() => navigate(to)} />
        </CustomModal>
      </div>
    </Card>
  );
};

export default HomeCard;
