import copy from "copy-to-clipboard";
import React from "react";
import { ArrowRight, InputIcon } from "../../assets";
import CustomButton from "../CustomButton";
import FlexBox from "../common/FlexBox";
import styles from "./index.module.scss";

type AmountData = {
  mainText: string;
  subText: string;
  tokenImg: React.ReactNode;
};

type sharedRedeemedData = {
  mainText: string;
  tokenImg: React.ReactNode;
};

type DepositorData = {
  mainText: string;
};

type RightSectionProps = {
  amountData?: AmountData;
  sharedRedeemedData?: sharedRedeemedData;
  depositorData?: DepositorData;
  to?: DepositorData
  from?: DepositorData
  asset?: AmountData
  shares?: string,
  hash?: DepositorData
};


type ActivityCardProps = {
  isComingSoon?: boolean;
  activityType: ActivityEnum;
  activityTime: string;
  tokenName: string;
  tokenAddress: string;
  tokenImg: React.ReactNode;
  rightSectionProps?: RightSectionProps;
};
export enum ActivityEnum {
  REDEMPTION = "Redemption",
  DEPOSIT = "Deposit",
  VAULT_MIGRATION = "Vault Migration Executed",
  ADD_ASSET = "Add Tracked Assets",
  SHARE_TRANSFER = "Share Transfer",
}
const ActivityCard = (props: ActivityCardProps) => {
  const copyToClipboard = (val?: string) => {
    if (val) copy(val);
  };
  return (
    <>
      <FlexBox className={styles.flex}>
        <div className={styles.leftSection}>
          <div className={styles.activityHeader}>
            <div className={styles.activityTimeContainer}>
              <span>{props.activityTime}</span>
            </div>
            <div className={styles.activityTypeContainer}>
              <h4>{props.activityType}</h4>
            </div>
          </div>

          <div className={styles.tokenDetails}>
            <div className={styles.tokenImg}>
              {props.tokenImg}
            </div>
            <div className={styles.tokenInfo}>
              <div className={styles.tokenName}>
                <h4>{props.tokenName}</h4>
              </div>
              <div className={styles.tokenAddress}>
                <h4>{props.tokenAddress.slice(0, 10)}.....</h4>
                <div className={styles.copyImg}>
                  <InputIcon onClick={() => copyToClipboard(props?.rightSectionProps?.depositorData?.mainText)} />
                </div>
              </div>

            </div>
          </div>
        </div>

        {props.activityType !== ActivityEnum.VAULT_MIGRATION && props.rightSectionProps ? (
          <div className={styles.rightSection}>
            {props.rightSectionProps.amountData && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>Amount</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightInfoText}>
                      <span>{props.rightSectionProps.amountData.mainText}</span>
                    </div>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.amountData.subText}</span>
                    </div>
                  </div>
                  <div className={styles.rightSectionImg}>
                    {props.rightSectionProps.amountData.tokenImg}
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.sharedRedeemedData && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>Shares Redeemed</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightInfoText}>
                      <span>{props.rightSectionProps.sharedRedeemedData.mainText}</span>
                    </div>
                  </div>
                  <div className={styles.rightSectionImg}>
                    {props.rightSectionProps.sharedRedeemedData.tokenImg}
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.depositorData && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>Depositor</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.depositorData.mainText}</span>
                    </div>
                  </div>
                  <div className={styles.copyImg}>
                    <InputIcon onClick={() => copyToClipboard(props?.rightSectionProps?.depositorData?.mainText)} />
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.hash && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>Transaction Hash</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightInfoText}>
                      <span>{props.rightSectionProps.hash.mainText}</span>
                    </div>
                  </div>

                </div>
              </div>
            )}
            {props.rightSectionProps.asset && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>Asset</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightInfoText}>
                      <span>{props.rightSectionProps.asset.mainText}</span>
                    </div>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.asset.subText}</span>
                    </div>
                  </div>
                  <div className={styles.rightSectionImg}>
                    {props.rightSectionProps.asset.tokenImg}
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.shares && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>To</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.shares}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.from && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>From</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.from.mainText}</span>
                    </div>
                  </div>
                  <div className={styles.copyImg}>
                    <InputIcon onClick={() => copyToClipboard(props?.rightSectionProps?.depositorData?.mainText)} />
                  </div>
                </div>
              </div>
            )}
            {props.rightSectionProps.to && (
              <div className={styles.withSubTextAndImg}>
                <div className={styles.rightSectionTitle}>
                  <span>To</span>
                </div>
                <div className={styles.rightSectionSubInfo}>
                  <div className={styles.rightSectionText}>
                    <div className={styles.rightSubText}>
                      <span>{props.rightSectionProps.to.mainText}</span>
                    </div>
                  </div>
                  <div className={styles.copyImg}>
                    <InputIcon onClick={() => copyToClipboard(props?.rightSectionProps?.depositorData?.mainText)} />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (<div className={styles.vaultRightSection}>
          <CustomButton className={styles.vaultButton} variant="outlined" endIcon={<ArrowRight />}>View Vault</CustomButton>
        </div>)}
      </FlexBox>
    </>
  )
}

export default ActivityCard;