import clsx from "clsx";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import { environment } from "../../environment";
import useDashboardGraph from "../../hooks/useDashboardGraph";
import useTokens from "../../hooks/useTokens";
import useTransactions from "../../hooks/useTransactions";
import { loanData, rewardData, staking } from "../../utils/constant";
import {
  convertExponentialToString,
  getTokenLabel,
  formatAmountToFixed,
} from "../../utils/helper";
import { getIconByType, getLabelByType } from "../../utils/transaction";
import Card from "../Card";
import LoanMobile from "../Dashboard/Loan";
import LoanComponent from "../Dashboard/Loan/LoanComponent";
import RewardMobile from "../Dashboard/RewardMobile";
import RewardDesktop from "../Dashboard/RewardMobile/Reward";
import StakMobile from "../Dashboard/StakMobile";
import StakDesktop from "../Dashboard/StakMobile/Stak";
import Dashboardtitle from "../DashbordTitle";
import SortByYear from "../SortByYear";
import Spinner from "../Spinner";
import TokensCard from "../TokensCard";
import FlexBox from "../common/FlexBox";
import styles from "./index.module.scss";
import FinancialChart from "../FinanceChart";
import useWindowResize from "../../hooks/useWindowResize";
import useParentWidth from "../../hooks/useParentWidth";
import { useAppSelector } from "../../store/hooks";

const pathNotAllow = environment.REMOVED_ACCESS_MODULE;
type ChartData = {
  name: string;
  value: number;
};

const MainDashboard = () => {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const { tokens, walletBalance, applyFilter, isLoading } = useTokens();
  const { transactions, loadMore, loadMoreCount, loading } = useTransactions(
    {}
  );
  const { parentRef, parentWidth } = useParentWidth();

  const { dashboardChart, applyFilter: applyGraphFilter } = useDashboardGraph();

  const [sortValue, setSortValue] = useState("all");

  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    setChartValues();
  }, []);

  useEffect(() => {
    setChartValues();
  }, [tokens]);

  const handleSortValue = (val: string) => {
    if (isLoading) return;

    applyFilter(val);
    applyGraphFilter(val);
    setSortValue(val);
  };

  const getXAxisList = () => {
    let type: any;
    switch (sortValue) {
      case "1y":
        type = "month";
        break;
      case "1m":
        type = "day";
        break;
      case "1w":
        type = "date";
        break;
      case "24h":
        type = "hour";
        break;
      default:
        type = "month";
        break;
    }

    const start = moment().startOf(type);
    // const formatData = 'h a, dd, DD-M-YYYY'
    const formatData = "DD-M-YYYY";

    const xList: any[] = [];
    if (sortValue == "1y" || sortValue == "all") {
      const end = moment(moment().subtract(1, "year"));
      while (end.isSameOrBefore(start, type)) {
        xList.push(start.format());
        start.subtract(1, "month");
      }
    }
    if (sortValue == "1m") {
      const end = moment(moment().subtract(1, "month"));
      while (end.isSameOrBefore(start, type)) {
        xList.push(start.format());
        start.subtract(1, "day");
      }
    }
    if (sortValue == "1w") {
      const end = moment(moment().subtract(1, "week"));
      while (end.isSameOrBefore(start, type)) {
        xList.push(start.format());
        start.subtract(1, "day");
      }
    }
    if (sortValue == "24h") {
      const end = moment(moment().subtract(24, "hour"));
      while (end.isSameOrBefore(start, type)) {
        xList.push(start.format());
        start.subtract(1, "hour");
      }
    }

    return xList.reverse();
  };
  const [chartDataValues, setChartDataValues] = useState<number[]>();
  useEffect(() => {
    const newChartValue: number[] = [];
    let temp = 0;
    dashboardChart?.forEach((element) => {
      if (element.value !== 0) {
        temp = element.value;
      }
      element.value === 0
        ? newChartValue.push(temp)
        : newChartValue.push(element.value);
    });
    setChartDataValues(newChartValue);
  }, [dashboardChart]);

  const data = {
    labels:
      sortValue == "24h"
        ? dashboardChart?.map((e) => moment(e.timestamp).local().format("hh a"))
        : dashboardChart?.map((e) => e.name),
    datasets: [
      {
        label: "Portfolio value",
        data: chartDataValues,
        fill: false,
        borderColor: "rgb(78, 48, 122)",
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            pointLabel: true,
          },
          stacked: true,
          gridLines: {
            // drawOnChartArea: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            // drawOnChartArea: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.xLabel}: ${tooltipItem.yLabel}`;
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      height: 200,
    },
    showLine: false,
    legend: {
      display: "",
    },
    bezierCurve: false,
    elements: {
      line: {
        tension: 0,
      },
    },
  };

  const formatMoment = (val: any, format: string) => {
    return moment(val).format(format);
  };

  const setChartValues = async () => {
    const xAxisList = await getXAxisList();

    const arr = [...tokens];
    const key = "updatedTimeStamp";
    const arr2: ChartData[] = [];

    const sortedArr = arr.sort((left, right) => {
      return (
        parseInt(moment(right.updatedTimeStamp).format()) -
        parseInt(moment(left.updatedTimeStamp).format())
      );
    });

    let customFormat: any;

    switch (sortValue) {
      case "1y":
        customFormat = "MMM YY";
        break;
      case "1m":
        customFormat = "D MMM";
        break;
      case "1w":
        customFormat = "ddd";
        break;
      case "24h":
        customFormat = "hh a";
        break;
      default:
        customFormat = "MMM YY";
        break;
    }

    for (let n = 0; n < xAxisList.length; n++) {
      const element = xAxisList[n];

      if (
        sortedArr.some((val: AssetToken) => {
          return (
            formatMoment(val[key], customFormat) ==
            formatMoment(element, customFormat)
          );
        })
      ) {
        const dbData = sortedArr.find(
          (val: AssetToken) =>
            formatMoment(val[key], customFormat) ==
            formatMoment(element, customFormat)
        );

        if (
          dbData &&
          arr2.some((val: ChartData) => {
            return val["name"] == moment(dbData[key]).format();
          })
        ) {
          for (let j = 0; j < arr2.length; j++) {
            const k = arr2[j];
            if (moment(dbData[key]).format() == k["name"]) {
              k["value"] = k["value"] + parseFloat(dbData["balance"]);
            }
          }
        } else {
          if (dbData) {
            const a = {
              value: parseFloat(dbData.balance),
              name: moment(dbData[key]).format(),
            };
            arr2.push(a);
          }
        }
      } else {
        const a = { value: parseFloat("0"), name: element };
        arr2.push(a);
      }
    }
    const finalArray = arr2.map((data) => ({
      ...data,
      name: formatMoment(data.name, customFormat),
    }));
    setChartData(finalArray);
  };

  const Loans = () => (
    <div className={styles.reward}>
      <Card className={styles.dashboardCard}>
        <div className={styles.stakCard}>
          <div className={styles.stakCardHeader}>
            <h2>Loans</h2>
            <h3>View More</h3>
          </div>
          <div className={styles.stakHeader}>
            <h4>Asset</h4>
            <h4>Amount to repay</h4>
            <h4>Repay before</h4>
            <h4>Actions</h4>
          </div>
          <div className={styles.stakWrapper}>
            {loanData?.map((loan) => (
              <LoanComponent key={loan.id} loan={loan} />
            ))}
          </div>
          <div className={styles.rowMobileContainer}>
            <LoanMobile data={loanData} />
          </div>
        </div>
      </Card>
    </div>
  );

  const Rewards = () => (
    <div className={styles.reward}>
      <Card className={styles.dashboardCard}>
        <div className={styles.stakCard}>
          <div className={styles.stakCardHeader}>
            <h2>Rewards</h2>
            <h3>View More</h3>
          </div>
          <div className={clsx(styles.stakHeader, styles.RewardsHeader)}>
            <h4>Mission</h4>
            <h4>Points</h4>
            <h4>Amount</h4>
            <h4>Actions</h4>
          </div>
          <div className={styles.stakWrapper}>
            {rewardData?.map((reward) => (
              <RewardDesktop key={reward.id} reward={reward} />
            ))}
          </div>
          <div className={styles.rowMobileContainer}>
            <RewardMobile data={rewardData} />
          </div>
        </div>
      </Card>
    </div>
  );

  const StakingOverwiew = () => (
    <Card className={styles.dashboardCard}>
      <div className={styles.stakCard}>
        <div className={styles.stakCardHeader}>
          <h2>Staking overview</h2>
          <h3>View More</h3>
        </div>
        <div className={styles.stakHeader}>
          <h4>Staked amount</h4>
          <h4>Available rewards</h4>
          <h4>Actions</h4>
        </div>
        <div className={styles.stakWrapper}>
          {staking?.map((stak, i) => (
            <StakDesktop key={i} stak={stak} />
          ))}
        </div>
        <div className={styles.rowMobileContainer}>
          <StakMobile data={staking} />
        </div>
      </div>
    </Card>
  );
  const RecentTransactions = () => (
    <div className={clsx(styles.tokenContainer, styles.mt)}>
      <Card className={styles.dashboardCard}>
        <div className={styles.recentTrans}>
          <div className={styles.header}>
            <h2>Recent transactions</h2>
            {loadMoreCount !== 0 && <p onClick={loadMore}>View More</p>}
          </div>
          <div className={styles.transWrapper}>
            {transactions?.map((item, i) => (
              <div key={i}>
                <div className={styles.tokenWrapper}>
                  <div className={styles.iconContainer}>
                    {getIconByType(item.type, { Buy: styles.buyIcon })}{" "}
                  </div>
                  <div className={styles.tranContent}>
                    <div className={styles.tranHead}>
                      <h6>{getLabelByType(item.type)}</h6>
                      <p>{moment(item.createdTimeStamp).format("lll")}</p>
                    </div>
                    <h5>
                      {formatAmountToFixed(
                        convertExponentialToString(item.amount)
                      )}{" "}
                      {getTokenLabel(item.currency, selectedToken?.name)}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
            {loading && <Spinner />}
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <>
      <Dashboardtitle title="Dashboard" />
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.graphContainer}>
            <Card className={styles.dashboardCard} key={"a"}>
              <div className={styles.cardInner}>
                <div className={styles.cardInnerContent}>
                  <div className={styles.cardInnerContentLeft}>
                    <p>My portfolio value</p>
                    <div className={styles.priceContainer}>
                      {isLoading ? (
                        <h3>
                          <Spinner />
                        </h3>
                      ) : (
                        <>
                          <h3>
                            $ {formatAmountToFixed(walletBalance.toString())}
                          </h3>
                          <span>4.48% APY</span>
                        </>
                      )}
                    </div>
                    <h6 className={styles.rate}>+$35.06(+2.14%)</h6>
                    <div className={styles.priceMob}>
                      <div className={styles.priceContainerMob}>
                        <span>4.48% APY</span>
                        <h6>+$35.06(+2.14%)</h6>
                      </div>
                    </div>
                  </div>
                  <SortByYear
                    sortValue={sortValue}
                    handleSortValue={handleSortValue}
                  />
                </div>
                {data?.datasets?.length > 0 ? (
                  <div className={styles.graph} ref={parentRef}>
                    <FinancialChart
                      key={parentWidth}
                      data={data}
                      height={250}
                      width={parentWidth}
                      sortValue={sortValue}
                      ratio={1}
                    />
                  </div>
                ) : (
                  <div className={styles.noDataFound}>
                    <p>No data found!</p>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.myTokenLeftContainer}>
            <TokensCard />
            <RecentTransactions />
          </div>
          <div className={styles.rightContainer}>
            <StakingOverwiew />
            {!pathNotAllow.includes("Loans") && <Loans />}
            {!pathNotAllow.includes("Rewards") && <Rewards />}
          </div>
        </FlexBox>
      </div>
    </>
  );
};

export default MainDashboard;
