import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { tok1 } from "../../../assets";
import { getImageBySymbol } from "../../../utils/constant";
import { getTokenLabel, formatAmountToFixed } from "../../../utils/helper";
import CustomButton from "../../CustomButton";
import Title from "../../Title";
import FlexBox from "../../common/FlexBox";
import style from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

type Props = {
  tokens: AssetToken[];
};
const WalletToken = (props: Props) => {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const { tokens } = props;
  const navigate = useNavigate();
  return (
    <div>
      <Title title="My tokens" />
      {tokens.map((myTokens, index) => {
        return (
          <FlexBox
            className={clsx(style.mywalletCard, style.walletSpace)}
            key={index}
          >
            <FlexBox>
              <div className={style.walletBalanceIcon}>
                <img
                  src={getImageBySymbol[myTokens.symbol] || tok1}
                  alt="Token"
                />
              </div>
              <div>
                <strong className={style.wallerTitle}>
                  {" "}
                  {myTokens.name
                    ? getTokenLabel(myTokens.name, selectedToken?.name)
                    : getTokenLabel(myTokens.symbol, selectedToken?.name)}
                  {`($${formatAmountToFixed(myTokens.currentPrice)})`}
                </strong>
              </div>
            </FlexBox>
            <FlexBox className={style.cardCentertext}>
              <div className={style.centerText}>
                <p className={style.walletBalance}>Token value</p>
                <strong className={style.tokenPrice}>
                  ${formatAmountToFixed(myTokens.price)}
                </strong>
              </div>
              <div className={style.centerText}>
                <p className={style.walletBalance}>Holdings</p>
                <strong className={style.tokenPrice}>
                  {formatAmountToFixed(myTokens.balance)}
                </strong>
              </div>
            </FlexBox>
            <FlexBox className={style.buttonGroups}>
              <CustomButton
                variant="outlined"
                className={style.buy}
                onClick={() => navigate("/stak_repay")}
              >
                Buy
              </CustomButton>
              <CustomButton
                onClick={() => navigate("/stak_repay")}
                className={style.sell}
                variant="outlined"
              >
                Sell
              </CustomButton>
            </FlexBox>
          </FlexBox>
        );
      })}
    </div>
  );
};

export default WalletToken;
