import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import clsx from "clsx";
import React from "react";
import Dashboardtitle from "../../components/DashbordTitle";
import StakeInfo from "../../components/StakeInfo";
import Staking from "../../components/Staking";
import { useAppSelector } from "../../store/hooks";
export default function StakingHeader() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { isKYCed } = useAppSelector((state) => state.auth);
  return (
    // <div className={clsx("material-ui-tab", !isKYCed && "fade")}>
    <div className={clsx("material-ui-tab")}>
      <Dashboardtitle title={"Staking "} description={""} />
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              marginBottom: "32px",
              // position: "fixed",
              // top: "0",
              // zIndex: "1",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#F97172" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#525885",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "capitalize",
                  fontFamily: "Nunito Sans",
                },
                "& button.Mui-selected ": {
                  color: "#190044",
                  fontWeight: "600",
                  fontFamily: "Nunito Sans",
                },
              }}
            >
              <Tab label="Staking" value="1" />
              <Tab label="Staking infos" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <Staking />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <StakeInfo />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
