import { BillCheckIcon, CardIcon, MagniferIcon } from "../../assets";
import Card from "../../components/Card";
import MainDashboard from "../../components/DashboardMain";
import Title from "../../components/Title";
import WalletCard, { TypeCard } from "../../components/WalletCard";
import FlexBox from "../../components/common/FlexBox";
import { environment } from "../../environment";
import { useAppSelector } from "../../store/hooks";
import Blog from "../Blog";
import BoardingDashboard from "../BoardingDashboard";
import PendingOnBoardingDashboard from "../PendingOnBoardingDashboard";
import styles from "./index.module.scss";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const pathNotAllow = environment.REMOVED_ACCESS_MODULE;

const Dashboard = () => {
  const { skipToDashboard, isOnboardingCompleted, selectedToken } =
    useAppSelector((state) => state.auth);
  const datas = [
    {
      id: 1,
      title: "BUY CRYPTOCURRENCIES",
      desc: "Don't own any yet? You can buy your first cryptos here and now!",
      btnText: "Buy cryptocurrencies",
      icon: <BillCheckIcon />,
      type: "BUY_CRYPTO",
    },
    {
      id: 2,
      title: "DIVE INTO TOKEN'S INDICATORS",
      desc: "Our Indicator Tokens are the best way to enjoy a lot of exciting assets, effortlessly!",
      btnText: `Buy Tokens`,
      icon: <MagniferIcon />,
      type: "BUY_N_TOKEN",
    },
    {
      id: 3,
      title: "SELL TOKEN'S TOKEN",
      desc: "Our Indicator Tokens are the best way to enjoy a lot of exciting assets, effortlessly!",
      btnText: `Sell token`,
      icon: <CardIcon />,
      type: "SELL_N_TOKEN",
    },
  ];
  const allowedArray = datas.filter(
    (data) => !pathNotAllow.includes(data.type)
  );

  if (skipToDashboard) {
    return (
      <div>
        <MainDashboard />
        <Blog />
        <div>
          <Title title={"Getting Started"} />
          <FlexBox className={styles.flex}>
            {allowedArray.map((item) => (
              <Card className={styles.card} padding="lg" key={item.id}>
                <WalletCard
                  selectedToken={selectedToken}
                  className={styles.cryptocurrencies}
                  icon={item.icon}
                  isInfoIcon={true}
                  to="#"
                  title={item.title}
                  subtile={""}
                  type={item.type as TypeCard}
                  desCription={item.desc}
                  butBtn={item.btnText}
                />
              </Card>
            ))}
          </FlexBox>
        </div>
      </div>
    );
  }

  if (isOnboardingCompleted) {
    return <BoardingDashboard />;
  }
  return <PendingOnBoardingDashboard />;
};

export default Dashboard;
