import { FormHelperText } from "material-ui-core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WalletIconOutlineWhite, tok1 } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import {
  useGetBalanceQuery,
  useGetPlatformCryptoTokenQuery,
  useSellTokensMutation,
} from "../../service/api";
import { useAppSelector } from "../../store/hooks";
import { formatAmountToFixed } from "../../utils/helper";
import BackButton from "../BackBtn";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";

export default function SellToken() {
  const selectedToken = useAppSelector((state) => state.auth.selectedToken);
  const navigate = useNavigate();
  const location = useLocation();
  const isTokenBuySellRoute =
    location.pathname.split("/")[1] === "token-buy-sell";

  const [loading, setLoading] = useState(false);
  const [fromTokens, setFromTokens] = useState<
    { name: string; value: string }[]
  >([]);
  
  const [cryptoTokenValue, setCryptoTokenValue] = useState<SellTokenObject>({});
  const { data: crptoTokens } = useGetPlatformCryptoTokenQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (crptoTokens) {
      const tokens = crptoTokens?.data.map((token) => ({
        name: token.symbol.toUpperCase(),
        value: token.symbol,
      }));
      const cryptoTokenValue = crptoTokens?.data.reduce(
        (acc: SellTokenObject, token: CryptoToken) => {
          acc[token.symbol] = 0;
          return acc;
        },
        {}
      );
      setCryptoTokenValue(cryptoTokenValue);
      setFromTokens(tokens);
    }
  }, [crptoTokens]);

  const { setAlertMsg } = useAlertMessage();

  const [sellToken] = useSellTokensMutation();

  const { data, refetch } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const handleSell = async () => {
    try {
      setLoading(true);
      const payload: SellTokenPayload = {
        SellTokenObject: cryptoTokenValue,
        tokenSymbol: selectedToken?.symbol!,
      };
      await sellToken(payload).unwrap();
      setAlertMsg?.({
        type: "success",
        msg: "Token transferred to your wallet successfully.",
      });
      resetStates();
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
      refetch();
    }
  };

  const resetStates = () => {
    setCryptoTokenValue((prevState) => {
      let newState = { ...prevState };
      for (let key in newState) {
        newState[key] = 0;
      }
      return newState;
    });
  };

  function handleChange(cb: (value?: number) => void) {
    return (e: any) => {
      cb(e.target.value ? parseFloat(e.target.value) : undefined);
    };
  }

  const token = data?.data.tokenAmount[selectedToken?.symbol!] || 0;
  const tokenOnHold = data?.data.balances.token || 0;
  const total = Object.values(cryptoTokenValue).reduce((a, b) => a + b, 0);

  const disabled =
    total === 0 ||
    Object.values(cryptoTokenValue).some((value) => value < 0) ||
    token < total;

  return (
    <div className={style.SellNealthyToken}>
      {!isTokenBuySellRoute && (
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <div className={style.titleContainer}>
        <Dashboardtitle
          className={style.SellNealthyTitle}
          title={`Sell ${selectedToken?.name} token`}
        />
        <div className={style.nStableContainer}>
          <WalletIconOutlineWhite />
          <span>
            {selectedToken?.name}: {formatAmountToFixed(token)}{" "}
          </span>
        </div>
      </div>
      {tokenOnHold !== 0 && (
        <span className={style.nStableBalance}>
          <img src={tok1} alt="token" />
          {selectedToken?.name}(Sell in progress):{" "}
          {formatAmountToFixed(tokenOnHold)}
        </span>
      )}
      {fromTokens?.map((token) => (
        <div className={style.SellNealthyCard} key={token.name}>
          <h6>{token.name}</h6>
          <div className={style.SellNealthycrytoValue}>
            <div className={style.inputContainer}>
              <input
                type="number"
                value={cryptoTokenValue[token.value]}
                className={style.input}
                onChange={handleChange((value) => {
                  if (value) {
                    setCryptoTokenValue({
                      ...cryptoTokenValue,
                      [token.value]: value,
                    });
                  }
                })}
              />
              {cryptoTokenValue && cryptoTokenValue[token.value] < 0 && (
                <FormHelperText error className={style.error}>
                  Value should be a positive number
                </FormHelperText>
              )}
            </div>
          </div>
        </div>
      ))}
      {total > token - tokenOnHold && (
        <FormHelperText error className={style.error}>
          Insufficient balance
        </FormHelperText>
      )}
      <CustomButton
        loading={loading}
        className={style.SellNealthyBtn}
        variant={"primary"}
        disabled={disabled}
        onClick={handleSell}
      >
        Sell
      </CustomButton>
    </div>
  );
}
