import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import LearnMore from "../../components/LearnMore";
import MonthButton from "../../components/MonthButton";
import Tab from "../../components/Tab";
import CustomModal from "../../components/common/CustomModal";
import FlexBox from "../../components/common/FlexBox";
import BackButton from "../BackBtn";
import { durationBtn } from "../SavingPlans/EditSavingPlan";
import styles from "./index.module.scss";
import {
  useGetStakeQuery,
  useStakeMutation,
  useGetBalanceQuery,
  useGetStakeInfoQuery,
  useGetUnstakeQuery,
  useGetClaimQuery,
  useUnstakeMutation,
  StakeData,
} from "../../service/api";
import moment from "moment";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactSelect, { Option } from "../Select";
import { useAppSelector } from "../../store/hooks";
type StakeFormData = {
  amount: number;
  tranche: string;
};
type UnstakeFormData = {
  stakeId: string;
  type: string;
};

interface Props {
  item: {
    name: string;
    value: string | number;
  };
}

type NFTSTokenStakeData = {
  name: string;
  value: string | number;
};

const tabs = ["Stake", "Unstake", "Claim"];
const data = [
  { name: "Stake-end-date", value: "01.02.2023  10:32" },
  { name: "Lock - up period", value: "" },
  { name: "You will receive (including fees)", value: "1238 $Token1" },
  { name: "APR", value: "" },
  { name: "Exchange rate", value: "1 ETH = 1zETH" },
  { name: "Transaction cost", value: "$2.20" },
  { name: "Reward fee", value: "10%" },
];
const stakeSchema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .integer("Amount must be an integer")
    .min(1, "Amount must be greater than zero")
    .typeError("Amount must be a number"),
  tranche: yup.string().required("Duration is required"),
});

const unstakeSchema = yup.object().shape({
  stakeId: yup.string().nonNullable().required("Stake ID is required"),
  type: yup.string().required("Type is required"),
});

const claimSchema = yup.object().shape({
  stakeId: yup.string().required("Stake ID is required"),
  type: yup.string().required("Type is required"),
});

const StakingNFTpage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const stakeId = queryParams.get("stakeId");
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(tab ? Number(tab) : 0);
  const { selectedToken } = useAppSelector((state) => state.auth);
  const months = ["1 month", "6 month", "12 month", "18 month"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTab = (index: number) => setTabIndex(index);
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const [selectedDuration, setSelectedDuration] = useState<string>("1m");
  const [stakeOptions, setStakeOptions] = useState<Option[]>([]);
  const [totalStakeAmount, setTotalStakeAmount] = useState<number>(0);
  const [maxStakeAmount, setMaxStakeAmount] = useState<number>(0);
  const [selectedIdForUnstake, setSelectedIdForUnstake] = useState(
    stakeId ? stakeId : null
  );
  const [selectedIdForClaim, setSelectedIdForClaim] = useState(
    stakeId ? stakeId : null
  );
  const [NFTSTokenStakeData, setNFTSTokenStakeData] = useState<
    NFTSTokenStakeData[]
  >([{ name: "", value: "" }]);
  const [NFTSTokenUnstakeData, setNFTSTokenUnstakeData] = useState<
    NFTSTokenStakeData[]
  >([{ name: "", value: "" }]);
  const [NFTSTokenClaimeData, setNFTSTokenClaimData] = useState<
    NFTSTokenStakeData[]
  >([{ name: "", value: "" }]);
  const [balance, setBalance] = useState<number>(0);

  const CustomOption = (props) => {
    const { data, isDisabled, innerProps, getStyles, isSelected } = props;
    if (isDisabled || !data || !data.label) return null;
    const parts = data.label.split("|");
    const before = parts[0];
    const after = parts[1];

    const optionStyles = getStyles("option", props);
    console.log(optionStyles, "optionStyles");
    console.log(props, "props");

    return (
      <div
        {...innerProps}
        style={{
          ...optionStyles,
          "&:active": {
            backgroundColor: "black",
          },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{before}| </span>
        <span style={{ color: "#5e648f" }}>{after}</span>
      </div>
    );
  };

  const [stakeEndDate, setStakeEndDate] = useState<string>(
    calculateStakeEndDate("1m")
  );
  const { data: balanceData, refetch: balanceRefetch } = useGetBalanceQuery();
  const {
    data: apiData,
    error,
    isLoading: stakeQueryLoading,
    refetch: stakeRefetch,
  } = useGetStakeQuery({ tranche: selectedDuration, amount: selectedAmount });

  const {
    data: stakeInfoData,
    isSuccess: stakeInfoSuccess,
    isLoading: stakeInfoLoading,
    refetch: stakeInfoRefetch,
  } = useGetStakeInfoQuery({ tranche: "", symbol: "" });
  const {
    data: claimData,
    isSuccess: claimSuccess,
    isLoading: claimLoading,
    refetch: claimRefetch,
  } = useGetClaimQuery({
    stakeId: selectedIdForClaim ? selectedIdForClaim : "",
  });

  const {
    data: unstakeData,
    isSuccess: unstakeSuccess,
    isLoading: unstakeLoading,
    refetch: unstakeRefetch,
  } = useGetUnstakeQuery({
    stakeId: selectedIdForUnstake ? selectedIdForUnstake : "",
  });

  const [createStake] = useStakeMutation();
  const [unstake] = useUnstakeMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(stakeSchema),
    defaultValues: {
      tranche: "1m",
    },
  });

  const {
    control: unstakeControl,
    handleSubmit: unstakehandleSubmit,
    formState: { errors: unstakeErrors },
    getValues: unstakeGetValues,
    setValue: unstakeSetValue,
    setError: unstakeSetError,
    clearErrors: unstakeClearErrors,
  } = useForm({
    resolver: yupResolver(unstakeSchema),
    defaultValues: {
      type: "unstake",
    },
  });
  const {
    control: claimControl,
    handleSubmit: claimhandleSubmit,
    formState: { errors: claimErrors },
    getValues: claimGetValues,
    setValue: claimSetValue,
    setError: claimSetError,
    clearErrors: claimClearErrors,
  } = useForm({
    resolver: yupResolver(claimSchema),
    defaultValues: {
      type: "claim",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (stakeInfoSuccess) {
        if (stakeInfoData) {
          const stakesInfo = stakeInfoData.data.child.map((stake) => {
            return {
              value: stake.id,
              label: `NSTBL: ${stake.stakedAmount.value} | ${new Date(
                stake.createdAt
              ).toLocaleString()}`,
            };
          });
          const stakeData = stakeInfoData.data.amount.value;
          setTotalStakeAmount(Number(stakeData.split(" ")[0]));

          setStakeOptions(stakesInfo);
          if (stakeId) {
            setSelectedIdForUnstake(stakeId);
            setSelectedIdForClaim(stakeId);
            unstakeSetValue("stakeId", stakeId);
            claimSetValue("stakeId", stakeId);
          }
        }
      }
    };
    fetchData();
  }, [stakeInfoSuccess, stakeInfoData]);

  const processData = (
    apiResponse: StakeData,
    setData: React.Dispatch<React.SetStateAction<NFTSTokenStakeData[]>>
  ) => {
    const keyNameMapping = {
      lockUpPeriod: "Lock - up period",
      amount: "You will receive (including fees)",
      apr: "APR",
      exchangeRate: "Exchange rate",
      transactionCost: "Transaction cost",
      rewardFee: "Reward fee",
    };

    const data = Object.entries(apiResponse).map(([key, value]) => {
      return { name: keyNameMapping[key], value: value };
    });
    data.unshift({ name: "Stake-end-date", value: stakeEndDate });
    setData(data);
  };

  useEffect(() => {
    const apiResponse = apiData?.data || [];
    processData(apiResponse as StakeData, setNFTSTokenClaimData);
  }, [claimData]);

  useEffect(() => {
    const apiResponse = apiData?.data || [];
    processData(apiResponse as StakeData, setNFTSTokenUnstakeData);
  }, [unstakeData]);

  useEffect(() => {
    const apiResponse = apiData?.data || [];
    processData(apiResponse as StakeData, setNFTSTokenStakeData);
    setIsLoading(false);
  }, [apiData]);

  useEffect(() => {
    if (balanceData) {
      setBalance(Math.floor(balanceData.data.balances[selectedToken?.symbol!]));
    }
    setIsLoading(false);
  }, [balanceData]);

  useEffect(() => {
    setMaxStakeAmount(balance - totalStakeAmount);
    setValue("amount", balance - totalStakeAmount);
  }, [balance, totalStakeAmount]);

  useEffect(() => {
    balanceRefetch();
    stakeInfoRefetch();
    stakeRefetch();
    claimRefetch();
    unstakeRefetch();
  }, []);

  const toggle = () => {
    if (isModalOpen == true) {
      setIsModalOpen(false);
      navigate("/staking-menu/");
    } else {
      setIsModalOpen(true);
    }
  };

  const navigate = useNavigate();

  function calculateStakeEndDate(selectedDuration: string) {
    const durationValue = Number(selectedDuration.slice(0, -1));
    const durationUnit =
      selectedDuration.slice(-1) === "m" ? "months" : "years";
    const stakeEndDate = moment().add(durationValue, durationUnit);
    const formattedStakeEndDate = stakeEndDate.format("DD.MM.YYYY  HH:mm");
    return formattedStakeEndDate;
  }

  const handleSelectDuration = async (val: string) => {
    setSelectedDuration(val);
    setValue("tranche", val);
    const stakeEndDate = calculateStakeEndDate(val);
    setStakeEndDate(stakeEndDate);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAmount(Number(event.target.value));
    clearErrors("amount");
    setValue("amount", Number(event.target.value));
  };

  const onSubmitStake = async (data: StakeFormData) => {
    const { amount, tranche } = data;
    if (amount > maxStakeAmount) {
      setError("amount", {
        type: "manual",
        message: "Amount must be less than or equal to your balance",
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await createStake({ amount, tranche }).unwrap();
      setIsLoading(false);
      setIsModalOpen(true);
    } catch (err: any) {
      setIsLoading(false);
      setError("amount", { type: "manual", message: err?.data?.message });
    }
  };
  const onSubmit = async (data: StakeFormData | UnstakeFormData) => {
    if (tabIndex === 0) {
      onSubmitStake(data as StakeFormData);
    } else if (tabIndex === 1) {
      onSubmitUnstake(data as UnstakeFormData);
    } else {
      onSubmitClaim(data as UnstakeFormData);
    }
  };

  const onSubmitUnstake = async (data: { stakeId: string; type: string }) => {
    const { stakeId, type } = data;
    try {
      setIsLoading(true);
      const repsonse = await unstake({ stakeId, type }).unwrap();
      setIsLoading(false);
      setIsModalOpen(true);
    } catch (err: any) {
      setIsLoading(false);
      unstakeSetError("stakeId", {
        type: "manual",
        message: err?.data?.message,
      });
    }
  };

  const onSubmitClaim = async (data: { stakeId: string; type: string }) => {
    const { stakeId, type } = data;
    try {
      setIsLoading(true);
      const repsonse = await unstake({ stakeId, type }).unwrap();
      setIsLoading(false);
      setIsModalOpen(true);
    } catch (err: any) {
      setIsLoading(false);
      claimSetError("stakeId", { type: "manual", message: err?.data?.message });
    }
  };
  return (
    <>
      <BackButton
        onClick={() => {
          navigate("/staking-menu/");
        }}
      />
      <div className={styles.stakContainer}>
        <div className={styles.stakMainDiv}>
          {/*
           */}

          <div className={styles.cardWrapper}>
            <h2>${selectedToken?.name}</h2>
            <div className={styles.buttons}>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  tab={tab}
                  index={i}
                  tabIndex={tabIndex}
                  handleTab={handleTab}
                />
              ))}
            </div>
            {tabIndex === 0 && (
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h5 className={styles.claim}>Stake</h5>
                  <div className={styles.titleInputMobContainer}>
                    <div className={styles.titleInputMob}>
                      <div className={styles.titleInputMobTop}>
                        <p>Amount</p>
                        <span>
                          Available: {balance - totalStakeAmount} $
                          {selectedToken?.name}
                        </span>
                      </div>
                      <div className={styles.inputContainerMob}>
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="number"
                              placeholder="0.00"
                              onChange={handleAmountChange}
                              value={getValues("amount")}
                            />
                          )}
                        />

                        <div className={styles.max}>
                          <h4
                            onClick={() => setValue("amount", maxStakeAmount)}
                          >
                            Max
                          </h4>
                        </div>
                      </div>
                    </div>
                    {errors.amount && (
                      <p className={styles.fieldError}>
                        {errors.amount.message}
                      </p>
                    )}
                  </div>
                  <p className={styles.duration}>Lock up duration</p>
                  <FlexBox className={styles.monthButtonParrent}>
                    {durationBtn.map((month, index) => {
                      return (
                        <Controller
                          name="tranche"
                          control={control}
                          render={({ field }) => (
                            <MonthButton
                              key={index}
                              months={month.mainVal}
                              value={month.value}
                              clasName={styles.monthButton}
                              selectedDuration={selectedDuration}
                              onClick={() => {
                                handleSelectDuration(month.value);
                              }}
                            />
                          )}
                        />
                      );
                    })}
                  </FlexBox>
                  <div className={styles.btnContainer}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate("/staking-menu")}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      loading={isLoading}
                      onClick={() => handleSubmit(onSubmit)}
                    >
                      Submit
                    </CustomButton>
                  </div>

                  <div className={styles.tokenDetail}>
                    {NFTSTokenStakeData.map((item, i) => (
                      <TokenDetail item={item} key={i} />
                    ))}
                  </div>
                </form>
              </div>
            )}
            {tabIndex === 1 && (
              <div>
                <h5 className={styles.claim}>Unstake</h5>
                <form onSubmit={unstakehandleSubmit(onSubmit)}>
                  <div className={styles.titleInputMobContainer}>
                    <Controller
                      control={unstakeControl}
                      name="stakeId"
                      render={({ field }) => {
                        return (
                          <ReactSelect
                            id="stakeId"
                            label=""
                            onChange={(e: any) => {
                              unstakeClearErrors("stakeId");
                              setSelectedIdForUnstake(e?.value);
                              unstakeSetValue("stakeId", e?.value);
                            }}
                            options={stakeOptions}
                            placeholder="Select"
                            value={
                              selectedIdForUnstake
                                ? stakeOptions.find(
                                  (option) =>
                                    option.value === selectedIdForUnstake
                                )
                                : null
                            }
                            error={unstakeErrors.stakeId as FieldError}
                            errorPosition={"left"}
                            components={{ Option: CustomOption }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={styles.btnContainer}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate("/staking-menu")}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      loading={isLoading}
                      onClick={() => unstakehandleSubmit(onSubmit)}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </form>
                <div className={styles.tokenDetail}>
                  {NFTSTokenUnstakeData.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
              </div>
            )}
            {tabIndex === 2 && (
              <div>
                <h5 className={styles.claim}>Claim</h5>
                <form onSubmit={claimhandleSubmit(onSubmit)}>
                  <div className={styles.titleInputMobContainer}>
                    <Controller
                      control={claimControl}
                      name="stakeId"
                      render={({ field }) => {
                        return (
                          <ReactSelect
                            id="stakeId"
                            label=""
                            onChange={(e: any) => {
                              claimClearErrors("stakeId");
                              setSelectedIdForClaim(e.value);
                              claimSetValue("stakeId", e.value);
                            }}
                            options={stakeOptions}
                            placeholder="Select"
                            value={
                              selectedIdForClaim
                                ? stakeOptions.find(
                                  (option) =>
                                    option.value === selectedIdForClaim
                                )
                                : null
                            }
                            error={claimErrors.stakeId as FieldError}
                            errorPosition={"left"}
                            components={{ Option: CustomOption }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={styles.btnContainer}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate("/staking-menu")}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      loading={isLoading}
                      onClick={() => claimhandleSubmit(onSubmit)}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </form>
                <div className={styles.tokenDetail}>
                  {NFTSTokenClaimeData.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <LearnMore
          className={styles.transation}
          title={"Transaction confirmed!"}
          buttonContent={"Got it!"}
          contnet={`Congratulations! Your ${tabIndex === 0 ? "Stake" : tabIndex === 1 ? "Unstake" : "Claim"
            } of Nealthy Token was successful.`}
          onClick={toggle}
        />
      </CustomModal>
    </>
  );
};
const TokenDetail = ({ item }: Props) => {
  return (
    <div className={styles.item}>
      <span>{item.name}</span>
      <p className={clsx(item.name === "APR" && styles.colorChange)}>
        {item.value}
      </p>
    </div>
  );
};
export default StakingNFTpage;
