import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import clsx from "clsx";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import metamaskBlack from "../../../assets/icons/metamask-black-img.jpg";
import metamask from "../../../assets/icons/metamask.jpg";
import BackButton from "../../BackBtn";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import Title from "../../Title";
import style from "./index.module.scss";

const names = [
  "It doesn’t have the information I need",
  "The solution did not worked as described",
  "The information was hard to follow or confusing",
  "It doesn’t have the information I need.",
  "Other",
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function GetingStart() {
  const [isArticleHelpfull, setIsArticleHelpfull] = useState<string>("");
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [textAreaTitle, setTextAreaTitle] = React.useState<string>("");

  const onChangeFeedback = (type: string) => {
    setIsArticleHelpfull(type);
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const navigate = useNavigate();
  return (
    <>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className={style.GetingStart}>
        <Dashboardtitle title={"Getting started"} />
        <div className={style.gettingSection}>
          <div className={style.connectWallet}>
            <Title
              className={style.startedtitle}
              title={"How to connect wallet"}
              content={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
              }
            />
            <div className={style.metamask}>
              <img src={metamask} alt="metamask" className={style.destopImg} />
              <img
                src={metamaskBlack}
                alt="metamask"
                className={style.mobileImg}
              />
            </div>
            <div className={style.installationBrowser}>
              <Title
                className={style.metamaskTitle}
                title={"1. Purchase digital currency (ETH)"}
                content={
                  "You can buy ETH, the digital currency that fuels transactions on the Ethereum blockchain, from a digital currency exchange like Coinbase. You'll need ETH to mint an NFT, purchase an NFT, and for gas fees to complete transactions."
                }
              />
              <p className={style.nowthat}>
                Now that you have ETH, let's get a crypto wallet.
              </p>
              <Title
                className={style.metamaskTitle}
                title={"2. Install a crypto wallet"}
                content={
                  "A crypto wallet, such as Metamask, stores your ETH and processes transactions on the Ethereum blockchain. A unique wallet address will be generated for you, and you'll use this address to complete transactions"
                }
              />
              <Title
                className={style.metamaskTitle}
                title={"3. Connect to Nealthy"}
                content={
                  "A crypto wallet, such as Metamask, stores your ETH and processes transactions on the Ethereum blockchain. A unique wallet address will be generated for you, and you'll use this address to complete transactions"
                }
              />
            </div>
            <div className={style.helpfullArticle}>
              <Title
                title={"Was this article helpful?"}
                className={style.articleTitle}
              />
              {isArticleHelpfull === "" && (
                <div>
                  <div className={style.buttonGroup}>
                    <div className={style.yesButton}>
                      <CustomButton
                        variant={"outlined"}
                        onClick={() => onChangeFeedback("yes")}
                      >
                        Yes
                      </CustomButton>
                    </div>
                    <div className={style.noButton}>
                      <CustomButton
                        variant={"outlined"}
                        onClick={() => onChangeFeedback("no")}
                      >
                        No
                      </CustomButton>
                    </div>
                  </div>
                </div>
              )}
              {isArticleHelpfull === "yes" && (
                <p className={style.feedbackReply}>
                  We're glad this article was helpful. Thank you for your
                  feedback.
                </p>
              )}
              {isArticleHelpfull === "no" && (
                <>
                  <p className={style.feedbackReply}>
                    Thank you for your feedback. What can we do to improve this
                    article?
                  </p>
                  <div>
                    <FormControl sx={{ mt: 4, width: 490, textAlign: "left" }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Tag
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        sx={{
                          // border: "1px solid #4E307A",
                          borderColor: "#4E307A",
                          borderRadius: "12px",
                          boxShadow:
                            " 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                          backgroundColor: "#FAFAFA",
                        }}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className={style.menuItem}
                          >
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText
                              primary={name}
                              sx={{
                                color: "#525885",
                                fontSize: "0.9rem !important",
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                      <br />
                      {personName?.length !== 0 && (
                        <Input
                          type="textarea"
                          id="title"
                          name="title"
                          placeholder=""
                          label="Title"
                          value={textAreaTitle}
                          onChange={(e) => setTextAreaTitle(e.target.value)}
                        />
                      )}
                      <div className={style.btnContainer}>
                        <CustomButton
                          disabled={
                            personName?.length === 0 ||
                            textAreaTitle.length === 0
                          }
                          className={style.submitBtn}
                          onClick={() => onChangeFeedback("submitted")}
                        >
                          Submit
                        </CustomButton>
                      </div>
                    </FormControl>
                  </div>
                </>
              )}
              {isArticleHelpfull === "submitted" && (
                <p className={style.feedbackReply}>
                  Thank you for helping us improve Nealthy. Your feedback is
                  appreciated.
                </p>
              )}
            </div>
          </div>
          <div className={style.articleSection}>
            <Title
              title={"Articles in this section"}
              className={style.articleTitle}
            />
            <div className={style.connectLinks}>
              <Link className={clsx(style.links, style.active)} to="">
                How to connect wallet
              </Link>
              <Link className={style.links} to="">
                How to buy crypto
              </Link>
              <Link className={style.links} to="">
                How to buy token
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
