import clsx from "clsx";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import * as yup from "yup";
import { InputIcon } from "../../../assets";
import { useAlertMessage } from "../../../context/AlertContext";
import {
  useGetAccountConfigsQuery,
  useGetBalanceQuery,
  useWithdrawalTokenMutation,
} from "../../../service/api";
import CustomButton from "../../CustomButton";
import Input from "../../Input";
import FlexBox from "../../common/FlexBox";
import SelectItem from "../SelectItem";
import styles from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

type Props = {
  title: string;
  type: string;
};

export type SelectOptions = {
  label: string;
  value: string;
};

const optionsChain: SelectOptions[] = [{ label: "ETH", value: "eth" }];

const DepositDetail = (props: Props) => {
  const { title, type } = props;
  const { selectedToken } = useAppSelector((state) => state.auth);
  const selectedTokenUppercase = selectedToken?.name.toUpperCase();
  const [touched, setTouched] = useState(false);

  const [SelectedToken, setSelectedToken] = useState<SelectOptions>({
    label: "USDC",
    value: "USDC",
  });

  const [selectedOptionsChain, setSelectedOptionsChain] =
    useState<SelectOptions>({
      label: "ETH",
      value: "eth",
    });

  const [amount, setAmount] = useState<number>(0);
  const [address, setAddress] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [optionsToken, setOptionsToken] = useState<SelectOptions[]>([]);
  const [withdrawOptions, setWithdrawToken] = useState<SelectOptions[]>([]);

  const { setAlertMsg } = useAlertMessage();

  const [withdrawalToken, { isLoading: loadingWithdraw }] =
    useWithdrawalTokenMutation();

  const { data } = useGetAccountConfigsQuery(
    { symbol: SelectedToken?.value },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: walletBalance, refetch } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const tokenBalances = walletBalance?.data.balances || {};
  const tokenBalance = tokenBalances[SelectedToken?.value.toLowerCase()] || 0;

  const minAmount = Number(data?.data?.minimumWithdrawalLimit ?? 0);
  const maxAmount = Number(data?.data?.withdrawalLimit ?? 0);

  useEffect(() => {
    const dataArray: SelectOptions[] = [];
    for (const token in data?.data?.tokens) {
      if (token != selectedToken?.name) {
        dataArray.push({
          label: token.toUpperCase(),
          value: token.toLowerCase(),
        });
      }
    }
    setOptionsToken(dataArray);
    const finalData = dataArray.filter(
      (val) => val.label != selectedTokenUppercase
    );
    setWithdrawToken(finalData);
  }, [data]);

  const copyToClipboard = (copyData: string) => {
    copy(copyData);
    setAlertMsg?.({
      type: "success",
      msg: "Deposit address copied to clipboard!",
    });
  };

  const handleOnSelect = (
    e: MultiValue<SelectOptions> | SingleValue<SelectOptions>
  ) => {
    setTouched(true);
    setSelectedToken(e as SelectOptions);
    setValidationError("");
    setAmount(0);
  };

  const handleOnSelectChain = (
    e: MultiValue<SelectOptions> | SingleValue<SelectOptions>
  ) => {
    setTouched(true);
    setSelectedOptionsChain(e as SelectOptions);
  };

  function handleChange(cb: (value: string) => void) {
    return (e: any) => {
      setTouched(true);
      setAmount(e.target.value);
    };
  }

  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (amount !== 0) {
      amountValidate();
    }
  }, [amount]);

  const amountValidate = () => {
    if (!touched) return;

    setValidationError("");
    yup
      .number()
      .typeError("")
      .required("Amount is required")
      .validate(amount)
      .then((value = 0) => {
        setValidationError(
          value > tokenBalance
            ? "Insufficient balance."
            : value > maxAmount
            ? "Exceeds maximum withdrawal limit."
            : value < minAmount
            ? "Less than minimum withdrawal limit."
            : ""
        );
      })
      .catch((error) => setValidationError(error?.message || ""));
  };

  const handleWithdrawal = async () => {
    try {
      const body = {
        amount,
        token: SelectedToken.value.toLowerCase(),
        address,
      };
      await withdrawalToken(body).unwrap();
      await refetch();
      setAmount(0);
      setAddress("");
      setAlertMsg?.({
        type: "success",
        msg: "Amount withdrawal successfully",
      });
    } catch (error: any) {
      const validationMsg = error?.message || error?.data?.data?.errors[0].msg;
      if (validationMsg === "Invalid address") {
        setErrorMsg(validationMsg);
        return;
      }
      setAlertMsg?.({
        type: "error",
        msg:
          error?.message ||
          validationMsg ||
          error?.data?.message ||
          "Something went wrong!",
      });
    }
  };

  const renderComponent = (t: string) => {
    if (t === "deposit") {
      return (
        <div>
          <SelectItem
            title="Select Token"
            options={optionsToken}
            defaultValue={SelectedToken}
            value={SelectedToken}
            onChange={handleOnSelect}
          />
          <SelectItem
            title="Enter the Chain"
            options={optionsChain}
            defaultValue={selectedOptionsChain}
            onChange={handleOnSelectChain}
          />

          <div className={clsx(styles.detailInfo, styles.mar25)}>
            <p>Deposit Address</p>
            <h4>
              {data?.data.depositAddress || "-"}
              {data?.data.depositAddress && (
                <span
                  onClick={() =>
                    copyToClipboard(data?.data.depositAddress || "")
                  }
                >
                  <InputIcon /> Copy
                </span>
              )}
            </h4>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <SelectItem
            title="Select Token"
            options={withdrawOptions}
            value={SelectedToken}
            onChange={handleOnSelect}
            tokenBalance={tokenBalance}
            balanceText="Avl Bal"
          />
          <Input
            placeholder={"amount"}
            label={"Enter amount"}
            type={"number"}
            id={"amount"}
            name={"amount"}
            max={data?.data?.withdrawalLimit}
            value={amount}
            onChange={handleChange((value) => {
              setAmount(parseFloat(value));
            })}
            inputClassName={styles.inputClass}
          />
          {validationError && <p className={styles.error}>{validationError}</p>}
          <SelectItem
            title="Enter the Chain"
            options={optionsChain}
            value={selectedOptionsChain}
            onChange={handleOnSelect}
          />
          <div className={clsx(styles.detailInfo, styles.mar25)}>
            <Input
              placeholder={"address"}
              label={"Withdrawal Address"}
              type={"text"}
              id={"address"}
              name={"address"}
              value={address}
              onChange={(e) => {
                setErrorMsg("");
                setAddress(e.target.value);
              }}
              inputClassName={styles.inputClass}
            />
          </div>
          {errorMsg && <p className={styles.error}>{errorMsg}</p>}
          <FlexBox className={styles.flex}>
            <div className={styles.detailInfo}>
              <p>Minimum Withdrawal</p>
              <h4>
                {data?.data.minimumWithdrawalLimit} {SelectedToken?.label}
              </h4>
            </div>
            <div className={styles.detailInfo}>
              <p>Maximum Withdrawal</p>
              <h4>
                {data?.data.withdrawalLimit} {SelectedToken?.label}
              </h4>
            </div>
          </FlexBox>
          <CustomButton
            onClick={handleWithdrawal}
            loading={loadingWithdraw}
            variant={"primary"}
            disabled={!!validationError || !address || !amount}
            className={styles.withdrawalBTn}
          >
            Withdraw
          </CustomButton>
        </div>
      );
    }
  };
  return (
    <div className={styles.detail}>
      <h3>{title}</h3>
      {renderComponent(type)}
    </div>
  );
};

export default DepositDetail;
