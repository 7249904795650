import { PropsWithChildren } from "react";
import { LogoMain, LogoSmall } from "../../assets";
import styles from "./index.module.scss";

export default function BasicLayout(props: PropsWithChildren) {
  return (
    <div className={styles.root}>
      <div className={styles.logoContainer}>
        <LogoSmall />
      </div>
      <div className={styles.container}>
        <div className={styles.sideImage}>
          <div className={styles.img}>
            <LogoMain className={styles.logoMain} />
            <div className={styles.diversify_text}>
              <h4>Digital Asset Management</h4>
              <p>
                Empower your institution with simple and compliant exposure to crypto and real-world assets.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
}
