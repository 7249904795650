import { ArrowUp, tok1 } from "../../assets";
import { useAppSelector } from "../../store/hooks";
import { getImageBySymbol } from "../../utils/constant";
import { getTokenLabel, formatAmountToFixed } from "../../utils/helper";
import styles from "./index.module.scss";

type Props = {
  data: AssetToken;
};
const ChartListItem = (props: Props) => {
  const selectedToken = useAppSelector((state) => state.auth.selectedToken);
  const { data } = props;

  return (
    <div className={styles.myTokenContainer}>
      <div className={styles.token}>
        <div className={styles.tokenLeft}>
          <div className={styles.logoContainer}>
            <img src={getImageBySymbol[data.symbol || data.name] || tok1} />
          </div>
          <div className={styles.contentLeft}>
            <h5>
              {getTokenLabel(data.name || data.symbol, selectedToken?.name)}{" "}
              {`($${formatAmountToFixed(data.currentPrice)})`}{" "}
            </h5>
            <p>
              <span>
                <ArrowUp className={styles.icon} />
                {0}%
              </span>
              This week
            </p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default ChartListItem;
