import clsx from "clsx";
import dayjs from "dayjs";
import DataTable, { TableColumn } from "react-data-table-component";
import useBuyOrder from "../../../hooks/useBuyOrder";
import { formatAmountToFixed } from "../../../utils/helper";
import CustomButton from "../../CustomButton";
import Spinner from "../../Spinner";
import FlexBox from "../../common/FlexBox";
import style from "../Transactions/index.module.scss";

type Props = {
  status?: BuyOrder["status"];
  title?: string;
};

const columns: TableColumn<BuyOrder>[] = [
  {
    name: "Symbol",
    selector: (row) => row.symbol,
    sortable: true,
    cell: (row) => {
      return <p className={style.amount}>{row.symbol}</p>;
    },
    style: { textAlign: "left" },
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    cell: (row) => {
      return <p className={style.amount}>{formatAmountToFixed(row.amount)}</p>;
    },
    style: { textAlign: "left" },
  },
  {
    name: "Token Issued",
    selector: (row) => row.tokenIssued,
    sortable: true,
    cell: (row) => {
      return (
        <p className={style.amount}>
          {row.tokenIssued} {row.tokenSymbol}
        </p>
      );
    },
    style: { textAlign: "left" },
  },
  {
    name: "Date",
    selector: (row) => dayjs(row.createdAt).format("DD.MM.YYYY"),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => "Successful",
    sortable: false,
    cell: (row) => {
      return <p className={style.amount}>{`${row.status}`}</p>;
    },
  },
];

const tableCustomCss = {
  rows: {
    style: {
      backgroundColor: "#F9F7FC",
      border: "0 !important",
      borderRadius: "12px",
      marginBottom: "12px",
      minHeight: "64px",
    },
  },
  headCells: {
    style: {
      color: "#9599B5",
      fontSize: "12px",
      fontWeight: "500",
      border: "0 !important",
    },
  },
  cells: {
    style: {
      color: "#190044",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
};

export default function BuyOrders(props: Props) {
  const { title, status } = props;

  const { buyOrders, applyFilter, loadMoreCount, loadMore, filter, loading } =
    useBuyOrder({ status });

  return (
    <div className={style.transactions}>
      {title && <h3>{title}</h3>}
      {!status && (
        <FlexBox className={style.mywalletTable}>
          <FlexBox className={style.tableButtons}>
            <CustomButton
              variant="outlined"
              className={clsx(style.eachButton, filter === "" && style.active)}
              onClick={applyFilter("")}
            >
              All<span>&nbsp;time</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "7d" && style.active
              )}
              onClick={applyFilter("7d")}
            >
              Last 7 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "30d" && style.active
              )}
              onClick={applyFilter("30d")}
            >
              Last 30 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "6m" && style.active
              )}
              onClick={applyFilter("6m")}
            >
              Last 6 m<span>onths</span>
            </CustomButton>
          </FlexBox>

          {/* <CustomButton variant="outlined" className={style.douwnload}>
            <Douwnload className={style.douwnloadIcon} />
            {downladableData && (
              <CSVLink
                data={downladableData}
                filename="transactions.csv"
                className="hidden"
                target="_blank"
              >
                Download CSV
              </CSVLink>
            )}
          </CustomButton> */}
        </FlexBox>
      )}
      <DataTable
        columns={columns}
        data={buyOrders}
        customStyles={tableCustomCss}
      />
      {loading && <Spinner />}
      {loadMoreCount !== 0 && (
        <CustomButton
          variant="outlined"
          className={style.loadMore}
          onClick={loadMore}
        >
          Load {loadMoreCount} More
        </CustomButton>
      )}
    </div>
  );
}
