import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-responsive-modal/styles.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { environment } from "./environment";

import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import ErrorBoundary from "./components/ErrorBoundary";
import AlertContext from "./context/AlertContext";
import SocketContext from "./context/Socket";
import { theme } from "./styles/thems";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const mode = "light";

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme({ mode })}>
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <CssBaseline />
          <GoogleOAuthProvider clientId={environment.GOOGLE_API_KEY}>
            <AlertContext>
              <SocketContext>
                <App />
              </SocketContext>
            </AlertContext>
          </GoogleOAuthProvider>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  </ThemeProvider>
  // </React.StrictMode>
);
reportWebVitals();
