import { ClockIcon } from "../../assets/";
import chart from "../../assets/Graphs.jpg";
import CoinDiamondImage from "../../assets/coin-DIAMOND-posed.jpg";
import Card from "../Card";
import CustomButton from "../CustomButton";
import styles from "./index.module.scss";
type BuySellTokenCardProps = {
  isComingSoon?: boolean;
  showBuyButton?: boolean;
  tokenName: string;
  tokenDescription: string;
};

const BuySellTokenCard = ({
  isComingSoon,
  showBuyButton,
  tokenName,
  tokenDescription,
}: BuySellTokenCardProps) => {
  return (
    <Card>
      <div className={styles.card}>
        <div>
          <img
            className={styles.tokenImage}
            src={CoinDiamondImage}
            alt="coin image"
          />
        </div>
        <div className={styles.cardBody}>
          <div className={styles.cardBodyHeader}>
            <h3>{tokenName}</h3>
            <p>{tokenDescription}</p>
          </div>
          <div className={styles.cardBodyData}>
            {isComingSoon ? (
              <div className={styles.comingSoon}>
                <ClockIcon />
                <span>Coming Soon</span>
              </div>
            ) : (
              <div className={styles.pricingData}>
                <div className={styles.currPrice}>
                  <div className={styles.priceTitle}>Current price</div>
                  <div className={styles.prices}>
                    <div>
                      <span className={styles.amount}>$1,474.91</span>
                      <span className={styles.apy}>4.48% APY</span>
                    </div>
                    <span>+$35.06 (+2.14%)</span>
                  </div>
                </div>
                <div className={styles.marketCap}>
                  <div className={styles.capSection}>
                    <small>Market capitalization</small>
                    <span>$237.467,02</span>
                  </div>
                  {showBuyButton && (
                    <CustomButton className={styles.buyBtn} onClick={() => {}}>
                      Buy Token
                    </CustomButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div />
        {!isComingSoon && (
          <div>
            <img className={styles.chartImage} src={chart} alt="chart" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default BuySellTokenCard;
