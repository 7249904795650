import { useRef, useState, useEffect } from "react";

function useParentWidth() {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setParentWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(parentRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return { parentRef, parentWidth };
}
export default useParentWidth;
