import clsx from "clsx";
import { coinSample } from "../../assets";

import { Typography } from "material-ui-core";
import SelectTokenBox from "../../components/SelectTokenBox";
import Spinner from "../../components/Spinner";
import Title from "../../components/Title";
import FlexBox from "../../components/common/FlexBox";
import { useGetPlatformTokensQuery } from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelectedToken } from "../../store/reducers/authSlice";
import styles from "./index.module.scss";

type Props = {
  onSelect?: (token: PlatformToken) => void;
};
const SelectTokenPage = (props: Props) => {
  const { onSelect } = props;
  const { selectedToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const handleTokenSelect = (token: PlatformToken) => () => {
    dispatch(setSelectedToken(token));
    onSelect?.(token);
  };

  const platformTokens = data?.data || [];

  return (
    <div>
      <Title title={"Select Token"} className={styles.title} />
      {isLoading && <Spinner />}
      <FlexBox className={clsx(styles.planFlex)}>
        {platformTokens.length === 0 && (
          <Typography align="center">No tokens found!</Typography>
        )}
        {platformTokens.map((token, index) => (
          <SelectTokenBox
            token={token}
            key={index}
            icon={coinSample}
            isSelected={selectedToken?.name === token.name}
            onClick={handleTokenSelect(token)}
          />
        ))}
      </FlexBox>
    </div>
  );
};

export default SelectTokenPage;
