import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Setting } from "../../../assets/icons/gear.svg";
import starImg from "../../../assets/saving-plan-img/star.svg";
import { ReactComponent as Visa } from "../../../assets/saving-plan-img/visa-logo.svg";
import useWindowResize from "../../../hooks/useWindowResize";
import CustomModal from "../../common/CustomModal";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import LearnMore from "../../LearnMore";
import EditSavingPlan from "../EditSavingPlan";
import style from "./index.module.scss";
export default function Confirmationplan() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };
  const editedPopup = () => {
    setIsEdit((isEdit) => !isEdit);
  };
  const { width } = useWindowResize();
  const breackpoint = 576;
  return (
    <div className={style.Confirmationplan}>
      <div className={style.ConfirmationplanTitle}>
        <Dashboardtitle
          title={"Saving plan summary"}
          description={
            "You will be billed on 16th of each month until June 16 2023"
          }
        />
        {width > breackpoint ? (
          <button className={style.editPlan} onClick={toggle}>
            Edit Plan
          </button>
        ) : (
          ""
        )}
        <CustomModal isOpen={isModalOpen} toggle={toggle}>
          <EditSavingPlan
            cancel={"Cancel"}
            saveChange={"Confirm"}
            title="Edit Auto-Invest setup"
            saveonClick={() => {
              editedPopup();
              setIsModalOpen(false);
            }}
            cancelonClick={() => {
              setIsModalOpen(false);
            }}
            inputAmounttext={
              <Input
                label={"Title"}
                type={"text"}
                id={"title"}
                placeholder={"100$"}
                name={"title"}
              />
            }
            investmentext="Investment timeline"
            inputDuration={
              <Input
                label={"Title"}
                type={"text"}
                id={"month"}
                placeholder={"4 month"}
                name={"month"}
              />
            }
          />
        </CustomModal>
        <CustomModal isOpen={isEdit} toggle={editedPopup}>
          <LearnMore
            className={style.editPopup}
            title={"Your Setup was canceled!"}
            icon={<Setting />}
            buttonContent={"Ok, Thanks!"}
            onClick={() => {
              setIsEdit(false);
            }}
            contnet={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
            }
          />
        </CustomModal>
      </div>
      <div className={style.editPlanBox}>
        <div className={style.editPlanBoxInner}>
          <div className={style.SavingPlan}>
            <h6 className={style.blueText}>Saving plan</h6>
            <div className={style.boxIcon}>
              <img src={starImg} />
            </div>
            <div className={style.textArea}>
              <h5 className={style.cryptoTitle}>$CRY10 TOKEN</h5>
              <Link to="/" className={style.purcheses}>
                1 Nealthy Token{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className={style.editPlanBoxInner}>
          <div className={style.SavingPlan}>
            <h6 className={style.blueText}>Payment provider </h6>
            <div className={style.boxIcon}>
              <Visa />
            </div>
            <div className={style.textArea}>
              {/* <h3>$CRY10 TOKEN</h3> */}
              {/* <Link to="/">1 Nealthy Token </Link> */}
              <h6 className={style.visa}>Visa</h6>

              <p className={style.purcheses}>**** **** **** 1234</p>
              <p className={style.until}>Your credit card saved </p>
            </div>
          </div>
        </div>
        <div className={style.editPlanBoxInner}>
          <div className={style.SavingPlan}>
            <h6 className={style.blueText}>Amount & frequency</h6>
            <div className={style.amount}>100$</div>
            <div className={style.textArea}>
              {/* <h3>$CRY10 TOKEN</h3> */}
              <p className={style.purcheses}>Following purchases </p>
              <p className={style.month}>Monthly, 16th </p>
              <p className={style.until}>until 16.06.2023 </p>
            </div>
          </div>
        </div>
      </div>
      {width < breackpoint ? (
        <button className={style.editPlan} onClick={toggle}>
          Edit Plan
        </button>
      ) : (
        ""
      )}
      <div className={style.buttonGroup}>
        <div className={style.viewMyPlan}>
          <CustomButton variant={"outlined"}>View my setup</CustomButton>
        </div>
        <div className={style.SubscribePlan}>
          <CustomButton variant={"outlined"}>Create another setup</CustomButton>
        </div>
      </div>
    </div>
  );
}
