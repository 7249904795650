import clsx from "clsx";
import style from "./index.module.scss";

type Props = {
  icon?: any;
  token?: PlatformToken;
  isSelected?: boolean;
  onClick: () => void;
};

export default function SelectTokenBox(props: Props) {
  const { icon, token, isSelected, onClick } = props;

  return (
    <div
      className={clsx(style.token, isSelected && style.selected)}
      onClick={() => onClick()}
    >
      <h3 className={style.title}>
        {token?.name}({token?.symbol})
      </h3>
      <div className={style.image}>
        <img className={style.icon} src={icon} alt={token?.symbol} />
      </div>
    </div>
  );
}
