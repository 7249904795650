import { Sell } from "@mui/icons-material";
import clsx from "clsx";
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  AffiliateActiveIcon,
  AfilliateIcon,
  DashboardActive,
  HelpActive,
  HelpIcon,
  HomeIcon,
  HomeIconActive,
  LogoSmall,
  LoyalActive,
  PlanActive,
  ProductActive,
  StakingActive,
  User,
  UserActive,
} from "../../assets";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Index } from "../../assets/icons/Index.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";
import { ReactComponent as Loyalty } from "../../assets/icons/Loyalty .svg";
import { ReactComponent as MenuArrow } from "../../assets/icons/menu-arrow.svg";
import { ReactComponent as Saving } from "../../assets/icons/saving plan.svg";
import { ReactComponent as Staking } from "../../assets/icons/Staking.svg";
import { environment } from "../../environment";
import { useGetPlatformTokensQuery } from "../../service/api";
import Logout from "../Logout";
import style from "./index.module.scss";
type IMenu = {
  to: string;
  label: string;
  icon: React.ReactElement;
  activeIcon?: React.ReactElement;
  children?: IMenu[];
  onClick?: () => void;
  toggle?: (() => void) | undefined;
  routeName?: string;
  isTab?: boolean;
  setRouteName?: Dispatch<SetStateAction<string | undefined>>;
  isTokens?: boolean;
  helpChild?: IMenu
};

export type SideBarRef = {
  toggleSideBar?: () => void;
};
type Props = {
  onChange?: (width: number) => void;
  toggleActiveClass: () => void;
};
const Sidebar = forwardRef<SideBarRef, Props>((props, ref) => {
  const [routeName, setRouteName] = useState<string | undefined>("/boarding");
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { toggleActiveClass } = props;

  const toggleSideBar = () => {
    sidebarRef.current?.classList.toggle(style.active);
    props.onChange?.(sidebarRef.current?.clientWidth || 0);
    setTimeout(() => {
      props.onChange?.(sidebarRef.current?.clientWidth || 0);
    }, 700);
  };

  useImperativeHandle(
    ref,
    () => ({
      toggleSideBar,
    }),
    [sidebarRef.current]
  );

  useEffect(() => {
    props.onChange?.(sidebarRef.current?.clientWidth || 0);
  }, []);

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const toggleLogoutModal = () =>
    setIsLogoutModalOpen((isLogoutModalOpen) => !isLogoutModalOpen);
  const navigateLogout = () => {
    toggleLogoutModal();
    navigate(-1);
  };
  const [productChilds, setProductChilds] = useState<IMenu[]>([]);
  const { data, isLoading } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const products: IMenu[] = [];
    for (const token of data?.data || []) {
      products.push({
        to: `/product/${token.symbol}/${token.id}`,
        icon: <Index />,
        isTab: true,
        activeIcon: <ProductActive />,
        label: token.name,
        isTokens: true,
      });
    }

    setProductChilds(products);
  }, [data, isLoading]);
  const navigations: IMenu[] = [
    {
      to: "/",
      icon: <HomeIcon />,
      activeIcon: <HomeIconActive />,
      label: "Home",
    },
    {
      to: "/dashboard",
      icon: <Dashboard />,
      activeIcon: <DashboardActive />,
      label: "Dashboard",
    },
    {
      to: "/product",
      icon: <Index />,
      activeIcon: <ProductActive />,
      label: "Index Tokens",
      children: [
        ...productChilds,
      ],
      isTokens: true,
      helpChild: { to: "/product/help", icon: <Index />, label: "Help center" },
    },
    {
      to: "/staking-menu",
      icon: <Staking />,
      activeIcon: <StakingActive />,
      label: "Staking",
    },
    {
      to: "/auto-invest",
      icon: <Saving />,
      activeIcon: <PlanActive />,
      label: "Auto-Invest",
    },
    {
      to: "/loyalty-program",
      icon: <Loyalty />,
      activeIcon: <LoyalActive />,
      label: "Loyalty Program",
    },
    {
      to: "/profile",
      icon: <User />,
      activeIcon: <UserActive />,
      label: "Profile",
    },
    {
      to: "/affiliate",
      icon: <AfilliateIcon />,
      activeIcon: <AffiliateActiveIcon />,
      label: "Affiliate System",
    },
    {
      to: "/orders",
      icon: <Sell />,
      activeIcon: <Sell sx={{ fill: "#28255F" }} />,
      label: "My Orders",
    },
  ];
  const pathNotAllow = environment.REMOVED_ACCESS_MODULE_PATH;

  const allowedNavigations = navigations.filter(
    (item) => !pathNotAllow.includes(item?.to)
  );
  return (
    <div ref={sidebarRef} className={clsx(style.sidebar)}>
      <div
        className={style.loggle}
        onClick={() => {
          toggleSideBar();
          toggleActiveClass();
        }}
      >
        <CrossIcon className={style.close} />
        <MenuArrow className={style.open} />
      </div>
      <div className={style.scroll}>
        <div className={style.logo}>
          <LogoSmall />
        </div>
        <div className={style.menu}>
          <ul className={style.sidebarUl}>
            {allowedNavigations.map((navigation, index) => (
              <Menu
                {...navigation}
                key={index}
                toggle={() => toggleSideBar()}
                routeName={routeName}
                setRouteName={setRouteName}
              />
            ))}
          </ul>
          <ul className={clsx(style.LogOut, style.sidebarUl)}>
            <Menu
              to="/helpCenter"
              icon={<HelpIcon />}
              activeIcon={<HelpActive />}
              label="Help Center"
              routeName={routeName}
              setRouteName={setRouteName}
            />
            <div onClick={toggleLogoutModal}>
              <Menu
                to="#logout"
                icon={<LogOut />}
                activeIcon={<LogOut />}
                label="Logout"
                routeName={routeName}
                setRouteName={setRouteName}
              />
            </div>
          </ul>
          <Logout isOpen={isLogoutModalOpen} toggle={navigateLogout} />
        </div>
      </div>
    </div>
  );
});
export default Sidebar;

function Menu(props: IMenu) {
  const navigate = useNavigate();
  const [newActive, setNewActive] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [searchParam] = useSearchParams();

  const {
    to,
    label,
    icon,
    activeIcon,
    children,
    toggle,
    routeName,
    setRouteName,
    isTab,
    isTokens,
    helpChild
  } = props;

  const changeHandler = () => {
    setRouteName?.(to);
    if (toggle !== undefined && window.innerWidth < 768) {
      toggle();
    }
  };
  const handleOnClick = (e: any, to: string) => {
    e.preventDefault();
    setIsActive((current) => !current);
    navigate(to);
  };

  const checkRoute = routeName == to && to !== "#logout";
  const pathNotAllow = environment.REMOVED_ACCESS_MODULE_PATH;

  const filteredChildren = children?.filter(
    (child) => !pathNotAllow.includes(child?.to)
  );
  if (filteredChildren && filteredChildren.length) {
    return (
      <div
        className={clsx(style.dropMenu, isActive ? style.activeDropMenu : "")}
      >
        <li className={clsx(checkRoute ? style.active : "")}>
          <NavLink
            to={to}
            onClick={(e: any) => handleOnClick(e, to)}
            className={({ isActive }) => clsx(isActive ? style.active : "")}
          >
            {({ isActive }) => {
              return (
                <>
                  <span>{isActive ? activeIcon : icon}</span>
                  <p
                    className={clsx(
                      style.label,
                      isActive ? style.textActive : ""
                    )}
                  >
                    {label}
                  </p>
                  <span className={style.arrow}>
                    <Arrow />
                  </span>
                </>
              );
            }}
          </NavLink>
        </li>

        <ul className={isTokens ? style.tokensdropmenu : style.opendropmenu} onClick={changeHandler}>
          {filteredChildren.map((child, index) => (
            <Menu
              {...child}
              key={index}
              routeName={routeName}
              setRouteName={setRouteName}
            />
          ))}
        </ul>
        {
          helpChild &&
          <>
            <ul className={style.opendropmenu} onClick={changeHandler}>
              <Menu
                {...helpChild}
                routeName={routeName}
                setRouteName={setRouteName}
              />
            </ul>
          </>
        }

      </div>
    );
  }
  return (
    <li
      className={newActive ? style.active : ""}
      onClick={() => changeHandler()}
    >
      <NavLink to={to}>
        {({ isActive, isPending }) => {
          setNewActive(isActive && to !== "#logout");
          return (
            <>
              <span>{isActive && to !== "#logout" ? activeIcon : icon}</span>
              <p
                className={clsx(
                  style.label,
                  isActive && to !== "#logout" ? style.textActive : ""
                )}
              >
                {label}
              </p>
            </>
          );
        }}
      </NavLink>
    </li>
  );
}
