import clsx from "clsx";
import { useAppSelector } from "../../store/hooks";
import { rewardData } from "../../utils/constant";
import Card from "../Card";
import CustomButton from "../CustomButton";
import RewardMobile from "../Dashboard/RewardMobile";
import RewardDesktop from "../Dashboard/RewardMobile/Reward";
import styles from "../DashboardMain/index.module.scss";
const DashboardReward = () => {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const { isOnboardingSkipped } = useAppSelector((state) => state.auth);
  return (
    <div className={clsx(styles.flexContainer)}>
      <div className={styles.reward1}>
        <Card>
          <div className={styles.stakCard}>
            <div className={styles.stakCardHeader}>
              <h2>Rewards</h2>
              <h3>View More</h3>
            </div>
            <div className={clsx(styles.stakHeader, styles.RewardsHeader)}>
              <h4>Mission</h4>
              <h4>Points</h4>
              <h4>Amount</h4>
              <h4>Actions</h4>
            </div>
            <div className={styles.stakWrapper}>
              {rewardData?.map((reward) => (
                <RewardDesktop key={reward.id} reward={reward} />
              ))}
            </div>
            <div className={styles.rowMobileContainer}>
              <RewardMobile data={rewardData} />
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.balance}>
        {isOnboardingSkipped == false ? (
          <Card>
            <div className={styles.balanceWrapper}>
              <p>Total balance</p>
              <div className={styles.balanceContent}>
                <div className={styles.balanceContentLeft}>
                  <h2>0.00$</h2>
                  <span>0.00 ${selectedToken?.name}</span>
                </div>
                <div className={styles.balanceContenRight}>
                  <CustomButton>Add</CustomButton>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          ""
        )}

        <div className={styles.token}>
          <h3>My Tokens</h3>
          <p>
            No tokens here yet. <span>&nbsp;Buy&nbsp;</span> your first token &
            start earning
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardReward;
