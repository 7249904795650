import copy from "copy-to-clipboard";
import { ReactComponent as InputIcon } from "../../../assets/icons/inputIcon.svg";
import { AirdropsIllustration, monkeyBanner } from "../../../assets/index";
import { ReactComponent as One } from "../../../assets/staking-page-icons/one.svg";
import { ReactComponent as RightArrow } from "../../../assets/staking-page-icons/right-right.svg";
import { ReactComponent as Three } from "../../../assets/staking-page-icons/three.svg";
import { ReactComponent as Two } from "../../../assets/staking-page-icons/two.svg";
import { useAlertMessage } from "../../../context/AlertContext";
import { environment } from "../../../environment";
import Banner from "../../Banner";
import Dashboardtitle from "../../DashbordTitle";
import HowItwork from "../../HowItwork";
import Input from "../../Input";
import style from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

export default function Airdrop() {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const { setAlertMsg } = useAlertMessage();

  const copyToClipboard = (copyData: string) => {
    copy(copyData);
    setAlertMsg?.({
      type: "success",
      msg: "Link copied to clipboard!",
    });
  };
  const link = `${environment.BASE_URL}/?invite=user6726161re13`;
  return (
    <div className={style.airdrop}>
      {/* <Dashboardtitle title={"Airdrop"} /> */}
      <Banner
        title={"What is Airdrop"}
        content={
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting."
        }
        src={monkeyBanner}
        alt="bannerImg"
        icon={<AirdropsIllustration />}
      />
      <Dashboardtitle
        title={"Refer friends"}
        description={`Get 20$ in ${selectedToken?.name} if one of your friends invests in our index tokens:`}
      />
      <div className={style.Referral}>
        <div className={style.ReferralInput}>
          <Input
            placeholder={link}
            textColor={style.referralInputColor}
            value={link}
            label={"Referral link"}
            type={"text"}
            id={"referral"}
            name={"referral"}
            disabled={true}
          />
          <InputIcon onClick={() => copyToClipboard(link)} />
        </div>
        <p className={style.ReferralP}>
          *Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old.
        </p>
      </div>
      <Dashboardtitle title={"How it works"} />
      <div className={style.gridsystem}>
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<One />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Two />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Three />}
        />
      </div>
    </div>
  );
}
