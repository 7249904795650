import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import clsx from "clsx";
import React from "react";
import Dashboardtitle from "../../components/DashbordTitle";
import InvestmentInfoPlan from "../../components/SavingPlans/InvestmentInfoPlan";
import MonthlyPlan from "../../components/SavingPlans/MonthlyPlan";
import MyInvestPlan from "../../components/SavingPlans/MyInvestPlan";
import { useAppSelector } from "../../store/hooks";

export default function SavingPlanHeader() {
  const [value, setValue] = React.useState("1");
  const { isKYCed } = useAppSelector((state) => state.auth);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    // <div className={clsx("material-ui-tab", !isKYCed && "fade")}>
    <div className={clsx("material-ui-tab")}>
      <Dashboardtitle title="Auto-Invest" />
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              marginBottom: "32px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#F97172" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#525885",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "capitalize",
                  fontFamily: "Nunito Sans",
                },
                "& button.Mui-selected ": {
                  color: "#190044",
                  fontWeight: "600",
                  fontFamily: "Nunito Sans",
                },
              }}
            >
              <Tab label="General" value="1" />
              <Tab label="My Auto-Invest" value="2" />
              <Tab label="Information" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <MonthlyPlan />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <MyInvestPlan />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="3">
            <InvestmentInfoPlan />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
