import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Clock,
  DocIcon,
  EmailLetterIcon,
  FileCheckIcon,
  FrameIcon,
} from "../../assets";
import AlertMessage from "../../components/AlertMessage";
import CustomButton from "../../components/CustomButton";
import { useAlertMessage } from "../../context/AlertContext";
import useWindowResize from "../../hooks/useWindowResize";
import BasicLayout from "../../layout/BasicLayout";
import { useSkipOnBoardingMutation } from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeSkipStatus } from "../../store/reducers/authSlice";
import styles from "./index.module.scss";

export type StepsData = {
  title: string;
  icon: React.ReactElement;
  time: string;
};
export const stepsData: StepsData[] = [
  { title: "General information", icon: <FrameIcon />, time: "2" },
  { title: "Identity verification", icon: <FileCheckIcon />, time: "5" },
  { title: "Questionnaire", icon: <DocIcon />, time: "2" },
];
export const stepsDataWithEmail: StepsData[] = [
  { title: "General info", icon: <FrameIcon />, time: "5 " },
  { title: "Email verification", icon: <EmailLetterIcon />, time: "2" },
  { title: "Identity verification", icon: <FileCheckIcon />, time: "5" },
  { title: "Questionnaire", icon: <DocIcon />, time: "2" },
];
export default function Verify() {
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isOnboardingSkipped, isOnboardingCompleted, isSkipToBoarding } =
    useAppSelector((state) => state.auth);
  const [skipOnBoarding, { isLoading }] = useSkipOnBoardingMutation();

  const methods = useForm({
    defaultValues: {
      perosnalInfo: "",
      emailVerification: "",
      identity: "",
      questionnaire: "",
    },
    // resolver: yupResolver(personalInfoSchema),
  });
  useEffect(() => {
    isSkipToBoarding && verifyUser();
  }, [isSkipToBoarding]);

  const verifyUser = () => {
    navigate("/onboarding");
  };

  const handleSkip = async () => {
    const payload = {
      id: user?.id,
      isOnboardingSkipped: true,
    };
    const { data, error } = (await skipOnBoarding(payload)) as SkipStep;
    if (data) {
      dispatch(changeSkipStatus(true));
      navigate("/", { replace: true });
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message,
      });
    }
  };

  const findStep: StepsData[] =
    user?.provider === "facebook" ? stepsDataWithEmail : stepsData;

  const { width } = useWindowResize();
  const breakpoint = 575;

  if (isOnboardingCompleted) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.bg}>
      <div className={styles.signup_page}>
        <BasicLayout>
          <div className={styles.sign_form}>
            <div>
              {alertMsg?.msg && (
                <AlertMessage type={alertMsg.type} onClose={onCloseAlert}>
                  {alertMsg.msg}
                </AlertMessage>
              )}
              <h2 className={styles.create}>
                {width > breakpoint
                  ? "Your account was successfully created!"
                  : "Account created"}
              </h2>
              <p className={styles.createContent}>
                In order to have unrestricted access to all the services nealthy
                offers, it is required that you go through a simple verification
                process. Please have your Documents ready.
              </p>

              <form onSubmit={methods.handleSubmit(verifyUser)}>
                <div className={styles.create_ac}>
                  {findStep.map(({ title, icon, time }, i) => {
                    return (
                      <div className={styles.input_field} key={i}>
                        <div className={styles.input_field_left}>
                          <div className={styles.input_field_left_text}>
                            <p>{i + 1}</p>
                          </div>
                          <p className={styles.icon}>{icon}</p>
                          <p>{title}</p>
                        </div>
                        <div className={styles.right}>
                          <Clock />
                          <p>{time} min</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.verify}>
                  <CustomButton
                    className={styles.verifyBtn}
                    disabled={isLoading}
                  >
                    Let's get verified!
                  </CustomButton>
                  {isLoading ? (
                    "Loading..."
                  ) : (
                    <div onClick={handleSkip}>
                      <Link to="">Skip for now</Link>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </BasicLayout>
      </div>
    </div>
  );
}
