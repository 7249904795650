/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../service/api";

export interface AuthState {
  authenticated: boolean;
  isLoading: boolean;
  isOnboardingCompleted: boolean;
  isOnboardingSkipped: boolean;
  isSkipToBoarding: boolean;
  isKYCed: boolean;
  skip: boolean;
  user?: User | null;
  token: string | null;
  applicationId?: string | null;
  skipToDashboard: boolean;
  onboardingStep: number;
  email: string;
  isCompany: boolean;
  refreshToken: string | null;
  joinAffiliateCode?: string | null;
  isRegistered?: boolean;
  registeredOtp?: boolean;
  selectedToken?: PlatformToken;
  sortValue: number | undefined;
  isEditable?: boolean;
}

const token = localStorage.getItem("token");
const initialState: AuthState = {
  authenticated: Boolean(token),
  isLoading: Boolean(token),
  skip: false,
  isOnboardingCompleted: false,
  isOnboardingSkipped: false,
  isSkipToBoarding: false,
  isKYCed: false,
  user: null,
  token: token,
  applicationId: null,
  skipToDashboard: false,
  onboardingStep: 0,
  email: "",
  isCompany: false,
  refreshToken: localStorage.getItem("refreshToken"),
  joinAffiliateCode: "",
  isRegistered: false,
  registeredOtp: false,
  selectedToken: localStorage.getItem("selectedToken")
    ? JSON.parse(localStorage.getItem("selectedToken") as string)
    : null,
  sortValue: 0,
  isEditable: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (
      state,
      action: PayloadAction<{ token: string; user: User; refreshToken: string }>
    ) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      state.authenticated = true;
      state.isLoading = false;
      state.isOnboardingCompleted = action.payload.user.isOnboardingCompleted;
      state.isOnboardingSkipped = action.payload.user.isOnboardingSkipped;
      state.isKYCed = action.payload.user.isKYCed;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.onboardingStep = action.payload.user.onboardingStep;
      state.refreshToken = action.payload.refreshToken;
    },
    rememberMeEmail: (
      state,
      action: PayloadAction<{ email: string; isAdd: boolean }>
    ) => {
      state.email = action.payload.isAdd ? action.payload.email : "";
    },
    logoutUser: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      state.authenticated = false;
      state.token = null;
      state.user = null;
      state.isLoading = false;
      state.skip = false;
      state.isOnboardingCompleted = false;
      state.isOnboardingSkipped = false;
      state.isSkipToBoarding = false;
      state.applicationId = null;
      state.skipToDashboard = false;
      state.isRegistered = false;
      state.registeredOtp = false;
    },
    skipBoarding: (state) => {
      state.isOnboardingCompleted = true;
    },
    changeSkipStatus: (state, action: PayloadAction<boolean>) => {
      if (state.isOnboardingSkipped === true) {
        state.isSkipToBoarding = true;
      }
      state.isOnboardingSkipped = action.payload;
    },
    changeKycStatus: (state, action: PayloadAction<boolean>) => {
      state.isKYCed = action.payload;
    },
    changeApplicationId: (state, action: PayloadAction<string>) => {
      state.applicationId = action.payload;
    },
    updateUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
    },
    changeToDashboardStatus: (state, action: PayloadAction<boolean>) => {
      state.skipToDashboard = action.payload;
    },
    updateOnBoardingStep: (state, action: PayloadAction<number>) => {
      state.onboardingStep = action.payload;
    },
    saveEmailOnLogin: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserType: (state, action: PayloadAction<boolean>) => {
      state.isCompany = action.payload;
    },
    setRegAffiliateCode: (state, action: PayloadAction<string>) => {
      state.joinAffiliateCode = action.payload;
    },
    setTokens: (
      state,
      action: PayloadAction<{ refreshToken: string; accessToken: string }>
    ) => {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.accessToken;
      localStorage.setItem("token", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      return state;
    },
    checkUserRegister: (
      state,
      action: PayloadAction<{ isRegistered: boolean; registeredOtp: boolean }>
    ) => {
      state.isRegistered = action.payload.isRegistered;
      state.registeredOtp = action.payload.registeredOtp;
    },
    setSelectedToken: (state, action: PayloadAction<PlatformToken>) => {
      state.selectedToken = action.payload;
      localStorage.setItem("selectedToken", JSON.stringify(action.payload));
    },
    setSort: (state, action: PayloadAction<{ sort: number | undefined }>) => {
      state.sortValue = action.payload.sort;
    },
    setUserEdit: (state, action: PayloadAction<{}>) => {
      state.isEditable = true;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getProfile.matchFulfilled,
      (state, action: PayloadAction<{ data: { user: User } }>) => {
        state.user = action.payload.data.user;
        state.isLoading = false;
        state.authenticated = true;
        state.skipToDashboard = action.payload.data.user.skipToDashboard;
        state.isOnboardingCompleted =
          action.payload.data.user.isOnboardingCompleted;
        state.isOnboardingSkipped =
          action.payload.data.user.isOnboardingSkipped;
        state.isKYCed = action.payload.data.user.isKYCed;
        state.skipToDashboard = action.payload.data.user.skipToDashboard;
        state.onboardingStep = action.payload.data.user.onboardingStep;
        state.isCompany = action.payload.data.user.isCompany;
        return state;
      }
    );
    builder.addMatcher(api.endpoints.getProfile.matchRejected, (state) => {
      if (state.isLoading) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        state.authenticated = false;
        state.token = null;
        state.user = null;
        state.isLoading = false;
        state.skip = false;
        state.isOnboardingCompleted = false;
        state.isOnboardingSkipped = false;
        state.isSkipToBoarding = false;
        state.applicationId = null;
        state.skipToDashboard = false;
      }
      return state;
    });
    builder.addMatcher(
      api.endpoints.getAuthToken.matchFulfilled,
      (state, action) => {
        state.refreshToken = action.payload.refreshToken;
        state.token = action.payload.token;
        state.isLoading = false;
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
        return state;
      }
    );
    builder.addMatcher(
      api.endpoints.getAuthToken.matchRejected,
      (state, action) => {
        state.refreshToken = "";
        state.token = "";
        state.isLoading = false;
        return state;
      }
    );
  },
});

export const {
  loginUser,
  logoutUser,
  skipBoarding,
  changeSkipStatus,
  changeKycStatus,
  changeApplicationId,
  updateUser,
  changeToDashboardStatus,
  updateOnBoardingStep,
  saveEmailOnLogin,
  setUserType,
  rememberMeEmail,
  setTokens,
  setRegAffiliateCode,
  checkUserRegister,
  setSelectedToken,
  setSort,
} = authSlice.actions;

export default authSlice.reducer;
