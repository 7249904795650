import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import AlertMessage from "../../components/AlertMessage";
import Dashboardtitle from "../../components/DashbordTitle";
import Mywallet from "../../components/MywallelPages/Mywallet";
import Rewards from "../../components/MywallelPages/Rewards";
import Transactions from "../../components/MywallelPages/Transactions";
import { useAlertMessage } from "../../context/AlertContext";

export default function MyWallet() {
  const [value, setValue] = React.useState("1");

  const { alertMsg, onCloseAlert } = useAlertMessage();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className="Indicator-Tokens">
      {/* className="material-ui-tab " */}
      <Dashboardtitle title={"My Wallet"} />
      {alertMsg?.msg && (
        <AlertMessage type={alertMsg.type} onClose={onCloseAlert}>
          {alertMsg.msg}
        </AlertMessage>
      )}

      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              // position: "fixed",
              // top: "0",
              // zIndex: "1",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#F97172" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#525885",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "capitalize",
                  fontFamily: "Red Hat Display",
                  minWidth: "auto",
                  width: "auto",
                },
                "& button.Mui-selected ": {
                  color: "#190044",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                  minWidth: "auto",
                  width: "auto",
                },
              }}
            >
              <Tab label="My Wallet" value="1" />
              <Tab label="Transactions History" value="2" />
              <Tab label="Rewards" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <Mywallet />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <Transactions />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="3">
            <Rewards />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
