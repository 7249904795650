import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoundedToken } from "../../assets";
import BackButton from "../../components/BackBtn";
import CustomButton from "../../components/CustomButton";
import Dashboardtitle from "../../components/DashbordTitle";
import Activity from "../../components/TokenPages/Activity";
import Fees from "../../components/TokenPages/Fees";
import Financial from "../../components/TokenPages/Financial";
import Overview from "../../components/TokenPages/Overview";
import Policies from "../../components/TokenPages/Policies";
import Portfolio from "../../components/TokenPages/Portfolio";
import FlexBox from "../../components/common/FlexBox";
import { useGetTokenInformationByIdQuery } from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelectedToken } from "../../store/reducers/authSlice";
import styles from "./index.module.scss";

const IndicatorToken = () => {
  const [value, setValue] = useState("1");
  const { selectedToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const params = useParams<{ token: string; id: string }>();
  const [vaultInfo, setVaultInfo] = useState<any>({});
  const [denominationAsset, setDenominationAsset] = useState<string[]>([]);
  const { data, error, isLoading, refetch } = useGetTokenInformationByIdQuery({
    id: params.id || "",
  });

  useEffect(() => {
    if (data) {
      setVaultInfo(data.data);
      const denominationAssetArray = data?.data?.denominationAsset
        .match(/"([^"]+)"/g)
        ?.map((symbol) => symbol.replace(/"/g, ""));
      setDenominationAsset(denominationAssetArray);
    }
  }, [data, isLoading]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const portfolioPayload = denominationAsset.map((asset) => ({
    id: `${asset} By Larva Labs`,
    item1: `${100 / denominationAsset.length}%`,
    item2: "$0.15",
    item3: "$3,438,726.40",
    item4: "+0.15%",
  }));

  const navigate = useNavigate();

  return (
    <div>
      <div>
        <BackButton
          className={styles.backButton}
          onClick={() => navigate("/")}
        />
        <FlexBox className={styles.flex}>
          <div className={styles.pageTitle}>
            <RoundedToken />
            <Dashboardtitle title={`${vaultInfo?.name}`} />
          </div>
          <div className={styles.titleButtos}>
            <CustomButton
              className={styles.depositBtn}
              onClick={() => {
                dispatch(
                  setSelectedToken({
                    name: vaultInfo?.name,
                    id: vaultInfo?.id,
                    symbol: vaultInfo?.symbol,
                  })
                );
                navigate("/token-buy-sell");
              }}
              disabled={!vaultInfo.name}
            >
              Buy Token
            </CustomButton>
            <CustomButton
              className={styles.redeemBtn}
              variant="outlined"
              onClick={() => {
                dispatch(
                  setSelectedToken({
                    name: vaultInfo?.name,
                    id: vaultInfo?.id,
                    symbol: vaultInfo?.symbol,
                  })
                );
                navigate("/token-buy-sell?tab=sell");
              }}
              disabled={!vaultInfo.name}
            >
              Redeem
            </CustomButton>
          </div>
        </FlexBox>
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                typography: "body1",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ sx: { backgroundColor: "#F97172" } }}
                sx={{
                  "& button": {
                    padding: "0",
                    color: "#BEBEBE",
                    fontSize: "16px",
                    marginRight: "28px",
                    textTransform: "capitalize",
                    fontFamily: "Red Hat Display",
                    minWidth: "auto",
                    width: "auto",
                  },
                  "& button.Mui-selected ": {
                    color: "#190044",
                    fontWeight: "600",
                    fontFamily: "Red Hat Display",
                    minWidth: "auto",
                    width: "auto",
                  },
                }}
              >
                <Tab label="Overview" value="1" />
                <Tab label="Portfolio" value="2" />
                <Tab label="Financial" value="3" />
                <Tab label="Fees" value="4" />
                <Tab label="Policies" value="5" />
                <Tab label="MyActivity" value="6" />
              </TabList>
            </Box>
            <TabPanel sx={{ padding: 0 }} value="1">
              <Overview denominationAsset={denominationAsset} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="2">
              <Portfolio data={portfolioPayload} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="3">
              <Financial />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="4">
              <Fees data={vaultInfo.fees} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="5">
              <Policies
                redemptionData={vaultInfo.redemption}
                depositData={vaultInfo.deposit}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="6">
              <Activity
                tokenName={vaultInfo?.name || "Token"}
                tokenAddress={vaultInfo?.priceFeedAddress || "0x1ce8...f6db"}
                tokenSymbol={vaultInfo?.symbol || "ST"}
              />
            </TabPanel>
          </TabContext>
        </Box>
        {/* <FlexBox className={styles.flex}>
          <IndicatorTokenCard
            key={1}
            icon={<SafeSquare />}
            heading="Vaults"
            val={1391}
          />
          <IndicatorTokenCard
            key={1}
            icon={<BankNote />}
            heading="Assets Under Management"
            val={166121954.62}
            dollar
          />
          <IndicatorTokenCard
            key={1}
            icon={<CardRecieve/>}
            heading="Deposits"
            val={6860}
          />
        </FlexBox> */}

        {/* <div className={styles.indicatorTokenContent}>
          <p>
            As a novel approach for straightforward, easy investing, each one of
            our Indicator Tokens act as a basket containing carefully selected
            assets from a specific market. Buying one Indicator Token means
            buying a portion of all the assets it represents, which in turn
            means efficient portfolio diversification and higher potential
            returns!
          </p>
          <p>
            If you’re interested and would like to learn more, make sure to
            check out all our available ressources regarding our Indicator
            Tokens!
          </p>
        </div> */}
      </div>

      {/* <div>
        <div>
          <Dashboardtitle
            title={"Buy & sell our Indicator Tokens"}
            description="See all the details about a Token’s performance, proof-of-reserve or simply buy or sell... This is where the magic happens!"
          />
          <div className={styles.BuySellTokenContainer}>
            <BuySellTokenCard />
            <BuySellTokenCard />
            <BuySellTokenCard />
            <BuySellTokenCard isComingSoon={true}/>
            <FlexBox
        // className={style.indicatorCard}
        className={clsx(styles.comingSoonCard, styles.indicatorCard)}
      >
        <div className={styles.imgSection}>
          <img className={styles.img} src={"CardIcon"} alt="tok1" />
          <img className={styles.tokenImage} src={CoinDiamondImage} alt="coin image" />
        </div>
        <div className={styles.comingSoonText}>
          <Dashboardtitle
            className={styles.gameTitle}
            title={"NFTS TOKEN"}
            description={`$NFTS TOKEN focuses on the 10 top trading assets on the NFT market.`}
          />
          <p className={styles.comingtext}>
            <span>Coming Soon</span>
          </p>
        </div>
      </FlexBox>
          </div>
        </div>
        <div className={styles.quicklinks}>
        <Dashboardtitle title="Quick links" className={styles.quickTitle} />
        <Link className={styles.quicklink} to="/">
          Saving plans details
        </Link>
        <Link className={styles.quicklink} to="/">
          How to buy an Index Token?
        </Link>
      </div>
      </div> */}
    </div>
  );
};

export default IndicatorToken;
