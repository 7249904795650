import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import Dashboardtitle from "../../components/DashbordTitle";
import BuyOrders from "../../components/MywallelPages/BuyOrders";
import SellOrders from "../../components/MywallelPages/SellOrders";

export default function MyOrder() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className="Indicator-Tokens">
      <Dashboardtitle title={"My Orders"} />
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#F97172" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#525885",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "capitalize",
                  fontFamily: "Red Hat Display",
                  minWidth: "auto",
                  width: "auto",
                },
                "& button.Mui-selected ": {
                  color: "#190044",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                  minWidth: "auto",
                  width: "auto",
                },
              }}
            >
              <Tab label="Buy Orders" value="1" />
              <Tab label="Sell Orders" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <BuyOrders />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <SellOrders />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
