import { createTheme, PaletteMode } from "@mui/material";

type Props = {
  mode: PaletteMode | undefined;
};
export const theme = ({ mode }: Props) =>
  createTheme({
    palette: {
      primary: {
        main: "#4E307A",
      },
      secondary: {
        main: "#EEE9F6",
      },
      error: {
        main: "#ff0000",
      },
      mode: mode,
    },
  });
