import { Tooltip } from "@mui/material";
import clsx from "clsx";
import copy from "copy-to-clipboard";
import { ReactElement, useState } from "react";
import { SortOrder, TableColumn, TableStyles } from "react-data-table-component";
import { CircularPlus, EditIcon, FoxIcon, InfoIcon, InputIcon, TrashIcon } from "../../../assets";
import { useAppDispatch } from "../../../store/hooks";
import { setSort } from "../../../store/reducers/authSlice";
import { sortCaseHandler } from "../../../utils/helper";
import CopyToClipboard from "../../CopyToClipboard";
import CustomButton from "../../CustomButton";
import DataTables, { Data, SelectedRow } from "../../DataTables";
import FlexBox from "../../common/FlexBox";
import FeeModals from "./FeeModals";
import styles from "./index.module.scss";
interface IRow {
  id: string;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
}
type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable?: boolean;
  style?: {};
  cell?: (row: any) => string | ReactElement;
  sortField?: string;
  width?: string;
};
const copyToClipboard = (val?: string) => {
  if (val) copy(val);
};

function renderRecipient(rowItem: string) {
  if (!rowItem) return <div style={dashStyle}>-</div>

  const isAddress = /^0x[a-fA-F0-9]{40}$/.test(rowItem);

  if (isAddress) {
    const truncatedRowItem = `${rowItem.slice(0, 6)}...${rowItem.slice(10, 12)}`;
    return (
      <div style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}>
        <div style={{
          paddingLeft: "11.5px",
          paddingRight: "11.5px",
          borderRadius: "16px",
          border: "1px solid #E0E0E0",
          width: "fit-content",
        }}>
          <span style={{ fontSize: "12px", fontWeight: 400, color: "#525885" }}>Vault Owner</span>
        </div>
        <div className={styles.managerAddress}>
          <div className={styles.imageContainer}>
            <div className={styles.managerImage}>
              <FoxIcon />
            </div>
          </div>
          <div style={{ fontWeight: 400, fontSize: 15, color: "#525885" }} className={styles.address}>{truncatedRowItem} </div>
          <div className={styles.copyBtn}><InputIcon onClick={() => copyToClipboard(rowItem)} /></div>
        </div>
      </div>
    );
  }
  return (
    <div style={{
      paddingTop: "12px",
      paddingBottom: "12px",
      width: "100%",
    }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: "4px" }}>
        <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>{rowItem}</div>
        <InfoIcon />
      </div>
    </div>
  );
}

function renderPrice(rowItem: string) {
  if (!rowItem) return <div style={dashStyle}>-</div>
  const match = rowItem.match(/(.*?)(\d+\.\d+)(.*)/);
  if (match) {
    const label = match[1].trim();
    const number = match[2];
    const suffix = match[3].trim();
    return (
      <div style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}>
        <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>{label}</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "4px" }}>
          <div style={{ fontWeight: 600, fontSize: 14, color: "#190044" }}>{number} {suffix}</div>
          {label !== "Rate (specific asset)" && <InfoIcon />}
        </div>
      </div>
    );
  }
  return (
    <div style={{
      paddingTop: "12px",
      paddingBottom: "12px",
      width: "100%",
    }}>
      <div style={{ fontWeight: 700, fontSize: 14, color: "#190044" }}>{rowItem}</div>
    </div>
  );
}

function renderSetting(rowItem: string) {
  const match = rowItem.match(/(.*?)(\d+\.\d+%)/);
  if (match) {
    const label = match[1].trim(); // "Rate" or "Rate (in kind)"
    const percentage = match[2]; // "1.75%" or "2.75%"
    return (
      <div style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}>
        <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>{label}</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "4px" }}>
          <div style={{ fontWeight: 600, fontSize: 14, color: "#190044" }}>{percentage}</div>
          {label !== "Rate (in kind)" && <InfoIcon />}
        </div>
      </div>
    );
  }
  return (
    <div style={{
      paddingTop: "12px",
      paddingBottom: "12px",
      width: "100%",
    }}>
      <div style={{ fontWeight: 700, fontSize: 14, color: "#190044" }}>{rowItem}</div>
    </div>
  );
}

const contentStyle = {
  fontWeight: 600,
  fontSize: 14,
  color: "#190044",
};
const dashStyle = {
  fontWeight: 700,
  fontSize: 15,
  color: "#9599B5",
};


const contentPositiveNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#3E3AFF",
};
const contentNegativeNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#F97172",
};




const customStyles: TableStyles = {
  rows: {
    style: {
      '&:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
      },
      border: "0px !important",
      BorderBottom: "none",
      boxShadow: "none",
      borderRadius: "6px",
      height: "auto",
    },
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "44.3px",
      PaddingTop: "12px",
      PaddingBottom: "13px",
      background: "white",
      height: "48px",
      borderBottom: 0,
      fontWeight: 700,
      fontSize: 15,
      color: "#9599B5",
    },
  },
  cells: {
    style: {
      borderBottom: 0,
      fontWeight: 400,
      fontSize: 15,
      color: "#525885",
      height: "auto",
    },
  },
};

const Fees = ({ data }: { data: tVaultFees }) => {
  const [selectedItem, setSelectedItem] = useState<SelectedRow | null>(null);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddtOpen] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { managementFeeRate, managementFeeRecipientAddress, entranceFeeAllocatedTo, entranceFeeRate, exitFeeAllocatedTo, performanceFeeRate, performanceFeeRecipientAddress } = data;
  const dummyData = [
    {
      id: "Management Fee",
      item1: `Rate ${managementFeeRate}%`,
      item2: "-",
      item3: "$3,438,726.40",
      item4: `${managementFeeRecipientAddress}`,
    },
    {
      id: "Performance Fee",
      item1: `Rate ${performanceFeeRate}%`,
      item2: "High watermark 1.3041 USDT",
      item3: "$3,438,726.40",
      item4: `${performanceFeeRecipientAddress}`,
    },
    {
      id: "Entrance Fee",
      item1: `Rate ${entranceFeeRate}%`,
      item2: "High watermark 1.3041 USDT",
      item3: "$3,438,726.40",
      item4: `${entranceFeeAllocatedTo}`,
    },
    {
      id: "Exit Fee",
      item1: "Rate (in kind) 0%",
      item2: "Rate (specific asset) 1.50%",
      item3: "$3,438,726.40",
      item4: `${exitFeeAllocatedTo}`,
      action: "fasfasf"
    },
  ];
  const toggle = () => {
    setIsDeleteOpen(false);
    setIsDeleted(false);
    setIsEditOpen(false);
    setIsAddtOpen(false)
    setIsEdited(false);
  };
  const resetHandler = () => {
    setSelectedItem(null);
    setClearAll(true);
  };
  const handleSort = async (
    selectedColumn: TableColumn<Data>,
    sortDirection: SortOrder,
    sortedRows: Data[]
  ) => {
    const sort = sortingFunction(selectedColumn?.sortField, sortDirection);
    dispatch(setSort({ sort: sort ?? 0 }));
  };
  const sortingFunction = (
    sortField: string | undefined,
    sortDirection: string
  ) => {
    switch (sortField) {
      case 'collection':
        return sortCaseHandler(sortDirection, 1, 2)
      case 'price':
        return sortCaseHandler(sortDirection, 5, 6)
      case 'value':
        return sortCaseHandler(sortDirection, 7, 8)
      case 'change':
        return sortCaseHandler(sortDirection, 9, 10)
      case 'allocation':
        return sortCaseHandler(sortDirection, 11, 12)
    }
  };

  function renderRecipient(rowItem: string) {
    if (!rowItem) return <div style={dashStyle}>-</div>

    const isAddress = /^0x[a-fA-F0-9]{40}$/.test(rowItem);

    if (isAddress) {
      return (
        <div style={{
          paddingTop: "12px",
          paddingBottom: "12px",
          width: "100%",
        }}>
          <div style={{
            paddingLeft: "11.5px",
            paddingRight: "11.5px",
            borderRadius: "16px",
            border: "1px solid #E0E0E0",
            width: "fit-content",
          }}>
            <span style={{ fontSize: "12px", fontWeight: 400, color: "#525885" }}>Vault owner</span>
          </div>

          <CopyToClipboard value={rowItem} isFees={true} />
        </div>
      );
    }
    return (
      <div style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: "4px" }}>
          <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>{rowItem}</div>
          <InfoIcon />
        </div>
      </div>
    );
  }
  function renderPrice(rowItem: string) {
    if (!rowItem) return <div style={dashStyle}>-</div>
    const match = rowItem.match(/(.*?)(\d+\.\d+)(.*)/);
    if (match) {
      const label = match[1].trim();
      const number = match[2];
      const suffix = match[3].trim();
      return (
        <div style={{
          paddingTop: "12px",
          paddingBottom: "12px",
          width: "100%",
        }}>

          <div style={{ fontWeight: 500, fontSize: 12, color: "#525885" }}>{label}</div>
          <div style={{ display: 'flex', alignItems: 'center', gap: "4px" }}>

            <div style={{ fontWeight: 600, fontSize: 14, color: "#190044" }}>{number} {suffix}</div>

            {label !== "Rate (specific asset)" &&
              <Tooltip
                classes={{ tooltip: styles.priceTooltip, arrow: styles.arrow }}
                title={"This is the share price under which performance fees cannot be claimed."}
                arrow
                placement="top"
              ><InfoIcon /></Tooltip>}
          </div>

        </div>
      );
    }
    return (
      <div style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}>
        <div style={{ fontWeight: 700, fontSize: 14, color: "#190044" }}>{rowItem}</div>
      </div>
    );
  }
  const columns: Columns[] = [
    {
      name: "Fee Type",
      selector: (row) => row.id,
      style: contentStyle,
    },
    {
      name: "Setting",
      selector: (row) => row.item1,
      style: contentStyle,
      cell: (row: IRow) => renderSetting(row.item1),
    },
    {
      name: "Name",
      selector: (row) => row.item2,
      style: contentStyle,
      cell: (row: IRow) => renderPrice(row.item2),
    },
    {
      name: "Recipient",
      selector: (row) => row.item4,
      style: contentPositiveNumber,
      cell: (row: IRow) => renderRecipient(row.item4),
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      cell: (row) => {
        return (
          <div
            className={clsx(
              styles.action,
            )}
          >
            <EditIcon
              className={styles.icon}
              data-edit={row.email}
              onClick={() => {
                console.log("Clicked Edit")
                setIsEditOpen(true);
              }}
            />
            <TrashIcon
              className={styles.icon}
              data-cy={`delete_${row.email}`}
              onClick={() => {
                console.log("ClickedDeleted")
                setIsDeleteOpen(true);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <h2 className={styles.title}>Fees</h2>
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionViewTitle}>
              <h2>My Fees</h2>
              <CustomButton startIcon={<CircularPlus />} className={styles.addBtn} onClick={() => setIsAddtOpen(true)}>Add Fees</CustomButton>
            </div>
            <DataTables handleSort={handleSort} customStyles={customStyles} selectableRows={false} columns={columns} data={dummyData} />
            <FeeModals
              isDeleteOpen={isDeleteOpen}
              isDeleted={isDeleted}
              setIsDeleted={setIsDeleted}
              isEditOpen={isEditOpen}
              isEdited={isEdited}
              setIsEdited={setIsEdited}
              isAddOpen={isAddOpen}
              toggleAll={toggle}
              selectedItems={selectedItem?.selectedRows}
              resetHandler={resetHandler}
              type="user"
            />
          </div>
        </FlexBox>
      </div>
    </>
  )

};

export default Fees;