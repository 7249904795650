import { useState } from "react";
import { Eth, RoundedToken } from "../../../assets";
import styles from "./index.module.scss";

import moment from "moment";
import useTransactions from "../../../hooks/useTransactions";
import ActivityCard, { ActivityEnum } from "../../ActivityCard";
import PillFilter from "../../PillFilter";

const filters = ["Buy", "Sell"];

type ActivityProps = {
  tokenName: string, tokenAddress: string, tokenSymbol: string
}
const Activity = ({ tokenName, tokenAddress, tokenSymbol }: ActivityProps) => {
  const [filterList, setFilterList] = useState<string[]>([]);
  const [customTransactions, setTransactions] = useState<Transaction[]>([]);

  const {
    transactions,
  } = useTransactions({});
  // setTransactions(transactions);
  const filterChangeHandler = (filter: string) => {
    let updatedFilterList: string[] = [];

    if (filterList.includes(filter)) {
      updatedFilterList = filterList.filter((item) => item !== filter);
    } else {
      updatedFilterList = [...filterList, filter];
    }

    setFilterList(updatedFilterList);

    const filteredTransactions = transactions.filter(
      (txn) => updatedFilterList.includes(txn.type) && (txn.status === "success" && txn.currency == tokenSymbol)
    );

    setTransactions(filteredTransactions);

  };
  return (
    <>
      <h2 className={styles.title}>Activity</h2>
      <div className={styles.pillFilter}>
        <div className={styles.filterTitle}>
          <span>Filters</span>
        </div>
        <div className={styles.filterButtons}>
          {filters.map((filter) => (
            <PillFilter
              key={filter}
              name={filter}
              isSelected={filterList.includes(filter)}
              filterClick={() => filterChangeHandler(filter)}
            />
          ))}

        </div>
      </div>

      <div className={styles.productContainer}>
        {
          customTransactions.map((txn, idx) => {
            return (
              <>
                <ActivityCard
                  key={idx}
                  activityType={txn.type == "Buy" ? ActivityEnum.DEPOSIT : ActivityEnum.REDEMPTION}
                  activityTime={moment(txn.updatedTimeStamp, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY HH:mm')}
                  tokenName={tokenName}
                  tokenAddress={tokenAddress}
                  tokenImg={<RoundedToken />}
                  rightSectionProps={{
                    to: {
                      mainText: txn.transferredTo.slice(0, 8) + "...",
                    },
                    hash: {
                      mainText: txn.transactionHash.slice(0, 16) + "...",
                    },
                    amountData: {
                      mainText: txn.amount.toString() + " " + txn.currency,
                      subText: "",
                      tokenImg: <Eth />,
                    },

                  }}
                />
              </>

            )
          })
        }
        {/* <ActivityCard
          activityType={ActivityEnum.REDEMPTION}
          activityTime="26 May 2024 05:24"
          tokenName={tokenName}
          tokenAddress={tokenAddress}
          tokenImg={<RoundedToken />}
          rightSectionProps={{

            amountData: {
              mainText: "899.8836 WETH",
              subText: "$1,086,758.60",
              tokenImg: <Eth />,
            },
            sharedRedeemedData: {
              mainText: "924.7911",
              tokenImg: <RoundedToken />,
            },
            depositorData: {
              mainText: "0x1ce8...f6db",
            },
          }}
        />
        <ActivityCard
          activityType={ActivityEnum.VAULT_MIGRATION}
          activityTime="26 May 2024 05:24"
          tokenName={tokenName}
          tokenAddress={tokenAddress}
          tokenImg={<RoundedToken />}
        />
        <ActivityCard
          activityType={ActivityEnum.ADD_ASSET}
          activityTime="26 May 2024 05:24"
          tokenName={tokenName}
          tokenAddress={tokenAddress}
          tokenImg={<RoundedToken />}
          rightSectionProps={{
            asset: {
              mainText: "0 stETH",
              subText: "$0.0",
              tokenImg: <Eth />,
            },
          }}
        />
        <ActivityCard
          activityType={ActivityEnum.SHARE_TRANSFER}
          activityTime="26 May 2024 05:24"
          tokenName={tokenName}
          tokenAddress={tokenAddress}
          tokenImg={<RoundedToken />}
          rightSectionProps={{
            shares: "5,555.0000",
            from: {
              mainText: "0x1ce8...f6db",
            },
            to: {
              mainText: "0x1ce8...f6db",
            },

          }}
        /> */}

      </div>
    </>
  )

};

export default Activity;