import { useState } from "react";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";

import copy from "copy-to-clipboard";
import { Link } from "react-router-dom";
import { BSC } from "../../../assets";
import { getTokenNameBySymbol } from "../../../utils/helper";
import CopyToClipboard from "../../CopyToClipboard";
import CustomLink from "../../CustomLink";
import IndicatorTokenCard from "../../IndicatorTokenCard/IndicatorTokenCard";



const Policies = ({ redemptionData, depositData }: { redemptionData: tVaultRedemption, depositData: tVaultDeposit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const copyToClipboard = (val?: string) => {
    if (val) copy(val);
  };


  return (
    <>
      <h2 className={styles.title}>Policies</h2>
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Restrict Assets For Redemption</h2>
            </div>
            <FlexBox className={styles.flex}>
              <div className={styles.restrictAssets}>
                {
                  redemptionData?.restrictAsset.length > 0 &&
                  redemptionData.restrictAsset.map((asset) => {
                    return (<>
                      <div className={styles.assetWrapper}>
                        <div className={styles.assetConatiner}>
                          <div className={styles.assetIcon}>
                            <BSC />
                          </div>
                          <div className={styles.assetContent}>
                            <div className={styles.assetSymbol}>
                              {asset}
                            </div>
                            <div className={styles.assetName} >
                              {getTokenNameBySymbol(asset)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)
                  })
                }
              </div>
            </FlexBox>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Limit Wallets Permitted To Deposit</h2>
            </div>
            <FlexBox className={`${styles.flexAddress} ${isExpanded ? '' : styles.notExpanded}`}>
              <>
                {Array.from({ length: isExpanded ? 10 : 5 }).map((_, index) => (
                  <div key={index} className={styles.managerWrapper}>
                    <CopyToClipboard value="0xa1818fAF5D6bB6aD2614CfF4a6b346eda31B77c4" isPolicies={true} />
                  </div>
                ))}
                <button className={styles.showMoreButton} onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? 'Show Less' : 'Show More'}
                </button>
              </>
            </FlexBox>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Deposit Limits</h2>
            </div>
            <FlexBox className={styles.flex}>
              <IndicatorTokenCard
                key={1}
                valueClassName={styles.valueClassName}
                heading="Minimum:"
                val={depositData.minimumDepositAmount}
              />
              <IndicatorTokenCard
                key={1}
                valueClassName={styles.valueClassName}
                heading="Maximum:"
                val={depositData.maximumDepositAmount}
              />
            </FlexBox>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Restrict Asset Position Removal</h2>
            </div>
            <CustomLink
              className={styles.customLinkText}
              link={
                <div>
                  {" "} <Link to="">This policy</Link> eliminates the potential for a vault manager to manipulate their vault’s share price by undercounting the assets it holds.
                </div>
              }
            />
            <CustomLink
              className={styles.customLinkText}
              link={
                <div>
                  {" "} A vault can optionally allow an asset without a valid price to bypass  this policy. Once added, an asset will be bypassable after a 1 week timelock. Once the timelock is elapsed, the asset will be able to bypass the policy for 2 days.
                </div>
              }
            />


          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Shares Lock-Up Period</h2>
            </div>
            <CustomLink
              className={styles.customLinkText}
              link={
                <div>
                  {" "} The duration (defined as elapsed time since your last receipt of newly  minted shares) you must hold this vault’s shares before being able to  redeem or transfer them. <Link to="">Shares Lock-Up Period</Link> prevents various types of arbitrage.
                </div>
              }
            />
            <div className={styles.bottomTitle}>
              <h2>{redemptionData.sharesLockUpPeriod} hours</h2>
            </div>
          </div>
        </FlexBox>
      </div>

    </>
  )

};

export default Policies;