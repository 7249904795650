import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BlogCard from "../../components/BlogCard";
import Spinner from "../../components/Spinner";
import { useGetNewsQuery } from "../../service/api";
import styles from "./index.module.scss";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const Blog = () => {
  const { data, isLoading } = useGetNewsQuery();
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={styles.blogContainer}>
      <div className={styles.blog}>
        <h2>Curated news for you</h2>
        <div className={styles.divide}></div>
      </div>
      {data && (
        <div>
          {data?.data?.data.length === 0 ? (
            <p className={styles.textCenter}>No news found!</p>
          ) : (
            <div className={styles.carouselSlider}>
              <Carousel
                partialVisible={false}
                responsive={responsive}
                infinite={true}
                autoPlay={isAutoPlay}
                autoPlaySpeed={3000}
                centerMode={false}
              >
                {data?.data?.data?.map((blog) => (
                  <BlogCard
                    {...blog}
                    key={blog.id}
                    setIsAutoPlay={setIsAutoPlay}
                  />
                ))}
              </Carousel>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Blog;
