import { useAppSelector } from "../../../store/hooks";
import CustomButton from "../../CustomButton";
import styles from "./index.module.scss";

export type Amount = {
  min: string;
  max: string;
};
interface REWARD {
  reward: {
    type: string;
    points: string;
    amount: {
      min: string;
      max: string;
    };
  };
}
const RewardDesktop = ({ reward }: REWARD) => {
  const { selectedToken } = useAppSelector((state) => state.auth);
  return (
    <div className={styles.stakContainer}>
      <h5>{reward.type}</h5>
      <p>{reward.points}</p>
      <p>
        {reward.amount.min}20.00${selectedToken?.name}
        <span>${reward.amount.max}</span>
      </p>
      <div className={styles.claim}>
        <CustomButton variant="outlined" className={styles.claimBtn}>
          Claim
        </CustomButton>
      </div>
    </div>
  );
};
export default RewardDesktop;
