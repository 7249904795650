import clsx from "clsx";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ArchiveIcon,
  BillCheckIcon,
  CardIcon,
  CardReciveIcon,
  CloseIcon,
  MagniferIcon,
  NotebookIcon,
  Play,
  WatchVideoImg,
  bannerImg,
} from "../../assets";
import AlertMessage from "../../components/AlertMessage";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import CustomButton from "../../components/CustomButton";
import Dashboardtitle from "../../components/DashbordTitle";
import Title from "../../components/Title";
import WalletCard, { TypeCard } from "../../components/WalletCard";
import CustomModal from "../../components/common/CustomModal";
import FlexBox from "../../components/common/FlexBox";
import OverviewWallet from "../../components/overviewWallet";
import { useAlertMessage } from "../../context/AlertContext";
import {
  ResponseData,
  useGetProfileQuery,
  useUpdateSkipDashboardMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeToDashboardStatus } from "../../store/reducers/authSlice";
import { SAMPLE_INTRO_VIDEO } from "../../utils/constant";
import styles from "./index.module.scss";

const datas = [
  {
    id: 1,
    title: "BUY CRYPTOCURRENCIES",
    desc: "Don't own any yet? You can buy your first cryptos here and now!",
    btnText: "Buy cryptocurrencies",
    icon: <BillCheckIcon />,
    type: "BUY_CRYPTO",
  },
  {
    id: 2,
    title: "DIVE INTO TOKEN'S INDICATORS",
    desc: "Our Indicator Tokens are the best way to enjoy a lot of exciting assets, effortlessly!",
    btnText: "Buy Tokens",
    icon: <MagniferIcon />,
    type: "BUY_N_TOKEN",
  },
  {
    id: 3,
    title: "SAVE A PAYMENT METHOD",
    desc: "Add payment informations to make your life easier and even buy and sell tokens for fiat!",
    btnText: "Add my payment infos",
    icon: <CardIcon />,
    type: "SAVE_PAYMENT_INFO",
  },
];

const overviews = [
  {
    id: 1,
    title: "Claim an airdrop",
    desc: "You have the possibility to claim an airdrop. Click here and follow these steps.",
    icon: <CardReciveIcon />,
  },
  {
    id: 2,
    title: "Buy new Token",
    desc: "We will launch a new token soon. Click here to be the first to buy it.",
    icon: <ArchiveIcon />,
  },
  {
    id: 3,
    title: "What’s new?",
    desc: "What is Yuga planning? Check here to see our new Blog.",
    icon: <NotebookIcon />,
  },
  {
    id: 4,
    title: "Governance decision",
    desc: "Take part in the Governance decision of your Tokens. Click here.",
    icon: <NotebookIcon />,
  },
];

const BoardingDashboard = () => {
  const [vedioPopup, setVedioPopup] = useState(false);
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();
  const { user, token, selectedToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { refetch } = useGetProfileQuery(null, { skip: !token });

  const [updateProfile, { isLoading }] = useUpdateSkipDashboardMutation();

  const changeToDashboard = async (val: boolean) => {
    try {
      const payload = {
        userId: user?.id,
        skipToDashboard: true,
      };
      const res = (await updateProfile(payload)) as ResponseData;
      refetch();
      dispatch(changeToDashboardStatus(val));
      navigate("/dashboard");
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || "Something went wrong!",
      });
    }
  };

  const openVediopopup = () => {
    setVedioPopup((vedioPopup) => !vedioPopup);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      <Dashboardtitle title={"Welcome to nealthy!"} />
      {alertMsg?.msg && (
        <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
          {alertMsg?.msg}
        </AlertMessage>
      )}
      <Banner
        title="Watch intro video"
        content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        src={bannerImg}
        alt="bannerImg"
        icon={<WatchVideoImg />}
      >
        <div className={styles.btnContainer}>
          <CustomButton className={styles.btn} onClick={openVediopopup}>
            Watch Video <Play />
          </CustomButton>
        </div>
        <CustomModal
          isOpen={vedioPopup}
          toggle={openVediopopup}
          classNames={{ modal: styles.modalWidth }}
        >
          <CloseIcon
            className={styles.CloseIcon}
            onClick={() => {
              setVedioPopup(false);
            }}
          />
          <Dashboardtitle
            className={styles.vedioTitle}
            title="Let us introduce ourselves"
            description="Discover everything nealthy has to offer in this video we prepared just for you!"
          />
          <div className={styles.popupVedio}>
            <div className={styles.vedioDotsSection}>
              <span className={styles.vedioDots}></span>
              <span className={styles.vedioDots}></span>
              <span className={styles.vedioDots}></span>
            </div>
            <div className={styles.vedio}>
              <video ref={videoRef} width="100%" height="100%" controls>
                <source src={SAMPLE_INTRO_VIDEO} type="video/webm" />
                <source src={SAMPLE_INTRO_VIDEO} type="video/mp4" />
              </video>
            </div>
          </div>
        </CustomModal>
      </Banner>
      <div className={clsx(styles.container)}>
        <div>
          <Title title={"Getting Started"} />
          <FlexBox className={styles.flex}>
            {datas.map((item) => (
              <Card className={styles.card} padding="lg" key={item.id}>
                <WalletCard
                  selectedToken={selectedToken}
                  className={styles.onboardingCard}
                  icon={item.icon}
                  isInfoIcon={true}
                  to="#"
                  title={item.title}
                  type={item.type as TypeCard}
                  subtile={""}
                  desCription={item.desc}
                  butBtn={item.btnText}
                />
              </Card>
            ))}
          </FlexBox>

          <Title title={"Overview"} />
          <FlexBox className={styles.flex}>
            {overviews.map((overview) => (
              <Card key={overview.id}>
                <OverviewWallet
                  Icon={overview.icon}
                  title={overview.title}
                  description={
                    <div>
                      You have the possibility to claim an airdrop.
                      <Link to="">here</Link> and follow these steps.
                    </div>
                  }
                />
              </Card>
            ))}
          </FlexBox>
          <div className={styles.bottom}>
            <p>
              Still have questions? Visit our{" "}
              <Link to="/helpCenter">Help center</Link>
            </p>
            <CustomButton
              loading={isLoading}
              variant="outlined"
              onClick={() => changeToDashboard(true)}
            >
              Skip to Dashboard
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardingDashboard;
