export const dateAndTimeConversion = (val: number) => {
  const getYear = new Date(val).getFullYear();
  const getMonth = new Date(val).getMonth();
  const getDay = new Date(val).getUTCDate();
  const getHrs = new Date(val).getHours();
  const getMin = new Date(val).getMinutes();
  return { getYear, getMonth, getDay, getHrs, getMin };
};

export const dateAndTimeFormat = (sortVal: string) => {
  if (sortVal === "24h") {
    return { format: "MMM DD(HH:mm)", type: "hrs" };
  } else if (sortVal === "1w" || sortVal === "1m") {
    return { format: "MMM DD", type: "" };
  } else if (sortVal === "1y") {
    return { format: "YYYY", type: "" };
  } else {
    return { format: "YYYY", type: "" };
  }
};

export const sum = (previous: number, current: number) => {
  const total = previous + current;
  return total;
};

export const convertExponentialToString = (value: number) => {
  if (value.toString().includes("e")) {
    return value.toFixed(Math.abs(parseInt(`${value}`.replace(/.e/, ""))));
  }
  return value.toString();
};

export const sliceTextWithThreeDots = (text?: string) => {
  return text?.slice(0, 10) + "...";
};

export const getTokenLabel = (
  currency: string,
  selectedToken: string | undefined
) => {
  return currency.toLowerCase() === selectedToken?.toLowerCase()
    ? selectedToken.toUpperCase()
    : currency;
};
export const formatAmountToFixed = (amount: any) => {
  return parseFloat(amount.toString()).toFixed(6);
};

export const sortCaseHandler = (
  direction: string,
  asc: number,
  des: number
) => {
  if (direction === "asc") {
    return asc;
  } else {
    return des;
  }
};

export const getTokenNameBySymbol = (symbol: string) => {
  switch (symbol) {
    case "ada":
    case "ADA":
      return "Cardano Token";
    case "jton":
    case "JTON":
      return "Wrapped TON Coin";
    case "doge":
    case "DOGE":
      return "DogeCoin";
    case "xrp":
    case "XRP":
      return "XRP Token";
    case "usdc":
    case "USDC":
      return "USD Coin";
    case "sol":
    case "SOL":
      return "Solana";
    case "bnb":
    case "BNB":
      return "BNB";
    case "usdt":
    case "USDT":
      return "Tether USD";
    case "eth":
    case "ETH":
      return "Ethereum";
    case "btc":
    case "BTC":
      return "Bitcoin";
    default:
      return "Token";
  }
};
