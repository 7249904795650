import clsx from "clsx";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../../assets/saving-plan-img/plus.svg";
import Card from "../../Card";
import FlexBox from "../../common/FlexBox";
import CustomButton from "../../CustomButton";
import CustomLink from "../../CustomLink";
import OverviewWallet from "../../overviewWallet";
import SavingPlanBox from "../../SavingPlanBox";
import Title from "../../Title";
import style from "./index.module.scss";
import { DollarIcon, RepeatIcon, SafeIcon, coinSample } from "../../../assets";

const autoInvestTokens = [
  {
    title: "$TOKEN1",
    startIcon: coinSample,
    content: "$TOKEN1 focuses on the 10 top trading assets on the NFT market.",
    average: "Average %",
    duration: "Duration",
    flexible: "Flexible",
    value: "12.3%",
    button: "Select",
    nealthToken: "Single asset",
  },
  {
    title: "$TOKEN2",
    startIcon: coinSample,
    content:
      "$TOKEN2 focuses on the 10 top trading assets on the cryptocurrency market.",
    average: "Average %",
    duration: "Duration",
    flexible: "Flexible",
    value: "12.3%",
    button: "Select",
    nealthToken: "Single asset",
  },
  {
    title: "$Token3",
    startIcon: coinSample,
    lastIcon: coinSample,
    content:
      "$Token3 Combo Auto-Invest allows you to set up monthly purchase for each token, all at once.",
    average: "Average %",
    duration: "Duration",
    flexible: "Flexible",
    value: "12.3%",
    button: "Select",
    nealthToken: "Single asset",
  },
];
export default function MonthlyPlan() {
  const [thankyou, setThankyou] = useState(false);
  const thankyouPop = () => {
    setThankyou((thankyou) => !thankyou);
  };
  const navigate = useNavigate();

  return (
    <div className={style.monthlyPlan}>
      {/* <Dashboardtitle title={"Monthly investing plan"} /> */}
      <FlexBox className={style.flexMonthly}>
        <div className={style.lookinginvestment}>
          <p className={style.investmentText}>
            Are you the DCA kind of investor? We stand by your side with our
            novel Auto-Invest system and offer the perfect tool for simple and
            efficient Dollar-Cost Averaging.
          </p>
          <p className={style.investmentText}>
            With Auto-Invest, you can set your own budget however you like and
            steadily grow your portfolio on a monthly basis without having to
            stress about timing the market or missing an opportunity. Less
            stress is healthy, and if it’s healthy, it’s nealthy!
          </p>
          <CustomLink
            className={style.TokensLink}
            link={
              <div>
                Of course, if you’d rather go with a one-time buy, we got you
                covered <Link to=""> here.</Link>
              </div>
            }
          />
        </div>
        <div className={style.quickLinks}>
          <h4 className={style.quckTitle}>Quick links</h4>
          <Link to="/" className={style.linksText}>
            More details about Auto-Invest
          </Link>
          <Link to="/" className={style.linksText}>
            How to buy a nealthy token?
          </Link>
          <Link to="/subscribePlan" className={style.linksText}>
            How to buy a nealthy token?
          </Link>
        </div>
      </FlexBox>
      <Title title={"Auto-Invest in an Indicator Token"} />
      <FlexBox className={style.planFlex}>
        {autoInvestTokens.map((token, index) => (
          <SavingPlanBox
            key={index}
            className={clsx(style.removeIcon, style.autoInvestBox)}
            title={token.title}
            startIcon={
              <div>
                <img src={token.startIcon} alt="tokenIcon" />
              </div>
            }
            content={token.content}
            average={token.average}
            duration={token.duration}
            flexible={token.flexible}
            value={token.value}
            button={token.button}
            nealthToken={token.nealthToken}
          />
        ))}
      </FlexBox>
      {/* <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <EditCancelPopup
          title={"Cancel investing plan?"}
          updateBtn={"Yes, Cancel Plan"}
          cancelBtn={"Cancel"}
        >
          <p className={style.popupText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </EditCancelPopup>
      </CustomModal> */}
      {/* <CustomModal isOpen={thankyou} toggle={thankyouPop}>
        <EditCancelPopup
          title={"Cancel investing plan?"}
          updateBtn={"Ok, Thanks!"}
          onClick={thankyouPop}
        >
          <p className={style.popupText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </EditCancelPopup>
      </CustomModal> */}

      <Title
        className={style.investingTitle}
        title={"Custom Setup"}
        content={
          "Do you want to create a custom Saving plan? There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
        }
      />
      <FlexBox className={style.planFlex}>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY DURATION"}
            Icon={<RepeatIcon />}
            description={
              <div>
                Choose the duration that best suits your goals and needs, and
                respect your comfort zone.
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY TOKEN"}
            Icon={<DollarIcon />}
            description={
              <div>
                Select only what you are interested in, be it a single asset or
                several.
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY AMOUNT"}
            Icon={<SafeIcon />}
            description={
              <div>
                Decide exactly how much is enough, so you are always in control
                of your budget.
              </div>
            }
          />
        </Card>
      </FlexBox>
      <CustomButton
        className={style.investingButton}
        onClick={() => navigate("/customsavingplan")}
      >
        Start Custom Setup
      </CustomButton>
    </div>
  );
}
