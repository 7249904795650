import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CaretUP, Down, GasPump } from "../../assets";
import { Exchange } from "../../assets/index";
import BackButton from "../../components/BackBtn";
import CustomButton from "../../components/CustomButton";
import Swap from "../../components/ProductComponents/Swap";
import Tab from "../../components/Tab";
import styles from "./index.module.scss";
import { useAppSelector } from "../../store/hooks";

interface Props {
  item: {
    name: string;
    value: string;
  };
}
const tabs = ["Buy", "Sell"];
const data = [
  { name: "Minimum NFTS Received", value: "9.7754 NFTS" },
  { name: "Network Fee", value: "0.0036 ETH" },
  { name: "Price Impact", value: "-0.56%" },
];
const StakRepay = () => {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTab = (index: number) => setTabIndex(index);
  const navigate = useNavigate();
  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className={styles.stakContainer}>
        <div className={styles.stakMainDiv}>
          <div className={styles.cardWrapper}>
            <h2>${selectedToken?.name} TOKEN</h2>
            <div className={styles.buttons}>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  tab={tab}
                  index={i}
                  tabIndex={tabIndex}
                  handleTab={handleTab}
                />
              ))}
            </div>
            {tabIndex == 0 && (
              <>
                <h5 className={styles.claim}>Buy Token</h5>
                <div className={styles.coinDetail}>
                  <Swap
                    isMax={true}
                    coin="ETH"
                    type={"From"}
                    douwntIcon={<Down />}
                    balance={"Balance: 0.0023,7"}
                    amount={0.0}
                    isDropMenu={true}
                  />
                  <Exchange className={styles.exchangeIcon} />
                  <Swap
                    isMax={false}
                    coin="NFTS"
                    type={"To"}
                    douwntIcon={<Down />}
                    amount={0.0}
                    isDropMenu={true}
                  />
                </div>
                <div className={styles.tokenDetail}>
                  {data.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                  <CustomButton>Enter Amount</CustomButton>
                </div>
              </>
            )}
            {tabIndex == 1 && (
              <>
                <h5 className={styles.claim}>Sell</h5>
                <div className={styles.coinDetail}>
                  <Swap
                    isMax={true}
                    coin="NFTS"
                    type={"From"}
                    isDropMenu={true}
                    amount={0.0}
                  />
                  <Exchange className={styles.exchangeIcon} />
                  <Swap
                    isMax={false}
                    coin="ETH"
                    type={"To"}
                    isDropMenu={true}
                    amount={0.0}
                  />
                </div>
                <div className={styles.tokenDetail}>
                  <div className={styles.headeritem}>
                    <p>1 ${selectedToken?.name} = 0.00001 ETH</p>
                    <p className={styles.phoneIcon}>
                      <GasPump />
                      3.13 $ <CaretUP />
                    </p>
                  </div>
                  {data.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
                <CustomButton>Enter Amount</CustomButton>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const TokenDetail = ({ item }: Props) => {
  return (
    <div className={styles.item}>
      <span className={styles.amount}>{item.name}</span>
      <p
        className={clsx(
          item.name === "Total repayable amount" && styles.colorChange
        )}
      >
        {item.value}
      </p>
    </div>
  );
};
export default StakRepay;
