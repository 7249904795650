import clsx from "clsx";
import { KeyboardEventHandler, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import AlertMessage from "../../components/AlertMessage";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/common/CustomModal";
import { useAlertMessage } from "../../context/AlertContext";
import BasicLayout from "../../layout/BasicLayout";
import {
  emailResponse,
  useEmailLoginMutation,
  useVerifyOtpMutation,
} from "../../service/api";
import { useAppDispatch } from "../../store/hooks";
import {
  changeToDashboardStatus,
  loginUser,
} from "../../store/reducers/authSlice";
import styles from "../Register/index.module.scss";

export default function Otp() {
  const {
    state: { email, type },
  } = useLocation();

  const [otp, setOtp] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage()

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [emailLogin, { isLoading: loading }] = useEmailLoginMutation();

  const toggle = () => setIsModalOpen((prev) => !prev);
  const onSubmit = async () => {
    if (otp.length < 6 || isLoading) return;
    const payload = {
      email,
      otp,
    };
    const { data, error }: any = await verifyOtp(payload);
    if (data) {
      const { user, token, refreshToken } = data.data;
      if (user.is2FAEnable) {
        const route = user.is2FAVerified ? "/2fa-auth" : "/2fa-qrcode";
        return navigate(route, {
          state: {
            email,
            user,
          },
        });
      }
      dispatch(loginUser({ user, token, refreshToken }));
      if (type === "login") {
        if (!user.isOnboardingCompleted) {
          return navigate("/boarding_details", {
            state: data,
          });
        } else if (user.isOnboardingCompleted && user.skipToDashboard == true) {
          dispatch(changeToDashboardStatus(true));
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          return navigate("/", {
            state: data,
            replace: true,
          });
        } else {
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          return navigate("/boarding", {
            state: data,
            replace: true,
          });
        }
      }
      if (type === "register") {
        return navigate("/boarding_details", {
          state: data,
        });
      }
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error.data.message
      });
    }
  };
  const resendOtpCode = async () => {
    if (isLoading || loading) return;
    if (!email) return;
    const { data, error } = (await emailLogin({ email })) as emailResponse;
    if (data) {
      setAlertMsg?.({
        type: "success",
        msg: data.message
      });
      setOtp("");
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error.data.message
      });
      console.log(error, "error");
    }
  };
  const changeEmail = () => {
    if (isLoading || loading) return;
    setIsModalOpen(true);
  };

  const isLetter = () => {
    if (otp.match(/[a-z]/i) === null) {
      return false;
    } else {
      return true;
    }
  };
  const enterKeyHandler: KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === "Enter") {
      if (otp.length < 6) return;
      onSubmit();
    }
  };
  const restartHandler = () => {
    localStorage.removeItem("email");
    navigate("/login");
  };
  return (
    <div className={styles.bg}>
      <div className={styles.signup_page}>
        <BasicLayout>
          <div className={styles.sign_form}>
            <div className={styles.set_width_res}>
              <h2 className={styles.create}>Verify your e-mail address</h2>
              <p className={styles.createContent}>
                Please enter 6-digit code we sent you to {email}
              </p>
              {alertMsg?.msg && <AlertMessage type={alertMsg.type} onClose={onCloseAlert}>{alertMsg.msg}</AlertMessage>}
              <form onKeyDown={enterKeyHandler} className={styles.verify_code}>
                <OTPInput
                  onChange={setOtp}
                  value={otp}
                  inputStyle="inputStyle"
                  containerStyle={styles.reactOtp}
                  numInputs={6}
                  shouldAutoFocus={true}
                  renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        type="number"
                        value={props.value}
                        className={styles.inputOtp}
                        placeholder="0"
                      />
                    );
                  }}
                />
              </form>
              <div
                className={clsx(
                  styles.input_group,
                  styles.login,
                  styles.submit
                )}
              >
                <CustomButton
                  disabled={otp.length !== 6 || isLetter()}
                  onClick={onSubmit}
                >
                  {isLoading ? "Loading..." : "Confirm"}
                </CustomButton>
              </div>
              <div className={clsx(styles.resendCode, styles.resendMargin)} onClick={resendOtpCode}>
                <span>
                  {loading ? "Loading..." : "Resend Verification Code"}
                </span>
              </div>
              <div className={clsx(styles.resendCode, styles.changeEmail)} onClick={changeEmail}>
                <span>Change Email Address</span>
              </div>
            </div>
          </div>
        </BasicLayout>
        <CustomModal isOpen={isModalOpen} toggle={toggle}>
          <div className={styles.modalContainer}>
            <h5>Change email address?</h5>
            <p>Are you sure you want to restart with new email address?</p>
            <div className={styles.modalBtn}>
              <CustomButton
                variant="outlined"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </CustomButton>
              <CustomButton onClick={restartHandler}>Restart</CustomButton>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
