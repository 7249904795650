import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Deposite,
  Down,
  Exchange,
  Redeem,
  Setting,
  Tokengraph,
  accordionImg,
} from "../../../assets/index";
import useWindowResize from "../../../hooks/useWindowResize";
import Card from "../../Card";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import { CustomExpandIcon } from "../../SavingPlans/MyInvestPlan";
import Title from "../../Title";
import AccordionHeader from "../AccordionHeader";
import style from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

type Amount = {
  type: string;
  isMax: boolean;
  coin: string;
};
export default function NFTSToeknPage() {
  const { selectedToken } = useAppSelector((state) => state.auth);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const navigate = useNavigate();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const AccordionCard = [
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
    {
      cardImg: <img src={accordionImg} />,
    },
  ];

  const { width } = useWindowResize();
  const breakpoint = 576;
  return (
    <div className={style.nftsTokenpage}>
      <div className={style.graphSection}>
        <h2>${selectedToken?.name}</h2>
        <Tokengraph className={style.graphImg} />
      </div>
      <div className={style.depositeSection}>
        <div className={style.redeemYour}>
          <div className={style.redeepIcon}>
            <Redeem />
          </div>
          <h4 className={style.redeemTitle}>REDEEM YOUR NFT</h4>
        </div>
        <div className={style.redeemYour}>
          <div className={style.redeepIcon}>
            <Deposite />
          </div>
          <h4 className={style.redeemTitle}>DEPOSIT YOUR NFT</h4>
        </div>
      </div>

      <div className={style.wrapper}>
        <div className={style.cardLeft}>
          <div className={style.innerCard}>
            <Card>
              <div className={style.statContainer}>
                <h2>Stats</h2>
                <div className={style.statDEtail}>
                  <div className={style.statItem}>
                    <p>Market Cap</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Volume(24h)</p>
                    <h3>$65.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Current Supply</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Streaming Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Mint Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Redeem Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>NAV</p>
                    <h3>$685.14K</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={style.innerCard}>
            <div className={style.innerCardAllocation}>
              <Dashboardtitle
                className={style.allocationsTitle}
                title="Allocations"
              />
              {width < breakpoint ? (
                ""
              ) : (
                <div className={style.allocationHeader}>
                  <div className={clsx(style.allocationHeaderHead, style.pos)}>
                    <p>Pos</p>
                  </div>
                  <div className={style.allocationHeaderHead}>
                    <p>Collection</p>
                  </div>
                  <div className={style.allocationHeaderHead}>
                    <p>NFTs</p>
                  </div>
                  <div className={style.allocationHeaderHead}>
                    <p>Price(USD)</p>
                  </div>
                  <div className={style.allocationHeaderHead}>
                    <p>Change VS USD (24H)</p>
                  </div>
                </div>
              )}

              <div></div>
            </div>
            <Card className={style.accordiongape}>
              <>
                <Accordion
                  className={style.customAccordion}
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      paddingRight: "0px",
                      paddingLeft: "0px",
                      border: "0",
                      flexWrap: "wrap",
                      boxShadow: "none",
                      ".css-o4b71y-MuiAccordionSummary-content": {
                        flexWrap: "wrap",
                        width: "80%",
                      },
                    }}
                    expandIcon={
                      <CustomExpandIcon
                        className={style.customeAccordionIcon}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={style.token}
                  >
                    <AccordionHeader
                      title="CRYPTOPUNK"
                      sn={1}
                      headerValue={"$15.80"}
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ borderTop: "1px solid #DDDEED" }}
                    className={style.accordionTitle}
                  >
                    <Title title="All NFTs" />
                    <div className={style.accordiondataScroll}>
                      <div className={style.AccordionCard}>
                        {AccordionCard.map((cardItem, index) => {
                          return (
                            <div className={style.cardItems} key={index}>
                              {cardItem.cardImg}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            </Card>
            <Card className={style.accordiongape}>
              <>
                <Accordion
                  className={style.customAccordion}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    sx={{
                      paddingRight: "0px",
                      paddingLeft: "0px",
                      border: "0",
                      flexWrap: "wrap",
                      boxShadow: "none",
                      ".css-o4b71y-MuiAccordionSummary-content": {
                        flexWrap: "wrap",
                        width: "80%",
                      },
                    }}
                    expandIcon={
                      <CustomExpandIcon
                        className={style.customeAccordionIcon}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={style.token}
                  >
                    <AccordionHeader
                      title="CRYPTOPUNK"
                      sn={1}
                      headerValue={"$15.80"}
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ borderTop: "1px solid #DDDEED" }}
                    className={style.accordionTitle}
                  >
                    <Title title="All NFTs" />
                    <div className={style.accordiondataScroll}>
                      <div className={style.AccordionCard}>
                        {AccordionCard.map((cardItem, index) => {
                          return (
                            <div className={style.cardItems} key={index}>
                              {cardItem.cardImg}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            </Card>
          </div>
        </div>

        <div className={style.cardRight}>
          <Card>
            <div>
              <div className={style.swapContainer}>
                <h2>Swap</h2>
                <Setting />
              </div>
              <div className={style.coinDetail}>
                <Amount type="From" isMax={true} coin="ETH" />

                <Exchange className={style.exchangeIcon} />
                <Amount
                  type="To"
                  isMax={false}
                  coin={`$${selectedToken?.name}`}
                />
              </div>
              <CustomButton
                className={style.btn}
                onClick={() => navigate("/stak_repay")}
              >
                Insufficient Balance
              </CustomButton>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
const Amount = ({ type, isMax, coin }: Amount) => {
  return (
    <div className={style.amountContainer}>
      <div className={style.amountTop}>
        <h6>{type}</h6>
        <p>Balance: 0.0023,7</p>
      </div>
      <div className={style.amountBottom}>
        <h3>0.00</h3>
        <div className={style.amountBottomRight}>
          {isMax && (
            <div className={style.max}>
              <span>Max</span>
            </div>
          )}
          <div className={style.coin}>
            <p>{coin}</p>
            <Down />
          </div>
        </div>
      </div>
    </div>
  );
};
